import { SET_PROVIDERS, SET_VENDORS,
  MODIFY_YARN_PURCHASE_ORDER_DETAIL,
  SET_YARNS,
  MODIFY_YARN_PURCHASE_ORDER_FOOTER,
  MODIFY_YARN_PURCHASE_ORDER_GENERAL,
  RESET_YARN_PO,
  SET_YARN_PO_STATUS,
  SET_YARN_PO_ID,
  SET_YARNS_POS,
  SET_YARNS_OF_PO} from '../mutation-types'
import backendService from '../../services/backend.service'
import moment from 'moment'
import { camelizeKeys } from 'humps'

const getProviders = async ({ commit }) => {
  try {
    const response = await backendService.getProviders();
    const data = response.data

    commit(SET_PROVIDERS, data)
  } catch (error) {

  }
}

const getVendors = async ({ commit }) => {
  try {
    const response = await backendService.getVendors();
    const data = response.data

    commit(SET_VENDORS, data)
  } catch (error) {

  }
}

const getYarns = async ({ commit }) => {
  try {
    const response = await backendService.getYarns();
    const data = response.data

    commit(SET_YARNS, data)
  } catch (error) {

  }
}

const modifyYarnPurchaseOrderDetail = ({ commit }, payload) => {
  commit(MODIFY_YARN_PURCHASE_ORDER_DETAIL, payload)
}

const modifyYarnPurchaseOrderFooter = ({ commit }, payload) => {
  commit(MODIFY_YARN_PURCHASE_ORDER_FOOTER, payload)
}

const modifyYarnPurchaseOrderGeneral = ({ commit }, payload) => {
  commit(MODIFY_YARN_PURCHASE_ORDER_GENERAL, payload)
}

const setYarnPOStatus = ({ commit }, payload) => {
  commit(SET_YARN_PO_STATUS, payload)
}

const createYarnPo = async ({ commit, state }) => {
  try {
    const { provider, buyer, code, vendor, contact,
      orderDate, deliveryDate, unitType, plantSlug  } = state.general;
    const { terms } = state.footer
    const body = {
      provider, buyer, vendor, code, contact,
      orderDate: moment(orderDate).toISOString(),
      deliveryDate: moment(deliveryDate).toISOString(),
      type: unitType, plantSlug,
      terms, detail: state.yarnPurchaseDetail }

    await backendService.createYarnPO(body);

    location.reload();

    const status = {
      status: 'success',
      message: 'yarnPurchaseOrder.createdYarnPO'
    }
    commit(SET_YARN_PO_STATUS, status)
  } catch(error) {
    const response = error.response
    const message = response.data.message

    const status = {
      status: 'error',
      message
    }
    commit(SET_YARN_PO_STATUS, status)
  }
}

const updateYarnPo = async ({ commit, state }) => {
  try {
    const { provider, buyer, code, vendor, contact,
      orderDate, deliveryDate, unitType, plantSlug  } = state.general;
    const { terms } = state.footer
    const body = {
      provider, buyer, vendor, code, contact,
      orderDate: moment(orderDate).toISOString(),
      deliveryDate: moment(deliveryDate).toISOString(),
      type: unitType, plantSlug,
      terms, detail: state.yarnPurchaseDetail }

    await backendService.updateYarnPo(body, state.yarnPoById.id);

    location.reload();

    const status = {
      status: 'success',
      message: 'yarnPurchaseOrder.createdYarnPO'
    }
    commit(SET_YARN_PO_STATUS, status)
  } catch(error) {
    const response = error.response
    const message = response.data.message

    const status = {
      status: 'error',
      message
    }
    commit(SET_YARN_PO_STATUS, status)
  }
}

const resetYarnPO = ({ commit }) => {
  commit(RESET_YARN_PO)
}

const getYarnsPos = async ({ commit }, payload) => {
  try {
    const response = await backendService.getYarnPOS(payload)
    const data = response.data
    commit(SET_YARNS_POS, data)
  } catch(error) {
    const response = error.response
    const message = response.data.message

    const status = {
      status: 'error',
      message
    }
    commit(SET_YARN_PO_STATUS, status)
  }
}

const getYarnsOfPo = async ({ commit }, id) => {
  try {
    const response = await backendService.getYarnsOfPos(id)
    const data = camelizeKeys(response.data)
    commit(SET_YARNS_OF_PO, data)
  } catch (error) {
    const response = error.response
    const message = response.data.message
    const status = {
      status: 'error',
      message
    }
    commit(SET_YARN_PO_STATUS, status)
  }
}

const getYarnsPoById = async ({ commit }, {id, edit}) => {
  try {
    const response = await backendService.getYarnsPoById(id)
    const data = response.data
    commit(SET_YARN_PO_ID, data)
    if (edit) {
      const { buyer, code, deliveryDate, orderDate, plant, provider, vendor, unitType, terms, yarnPurchaseOrderDetail } = data
      const formattedDeliveryDate = moment(deliveryDate).format('YYYY-MM-DD')
      const formattedOrderDate = moment(orderDate).format('YYYY-MM-DD')
      const payload = { buyer: buyer.id, code, deliveryDate: formattedDeliveryDate, edit: true,
        orderDate: formattedOrderDate, plantSlug: plant.slug, provider: provider.id, vendor: vendor.id, unitType }

      const footer = { terms }

      const detail = yarnPurchaseOrderDetail
      .map(yarnDetail => (
        { yarn: yarnDetail.yarn.id, origin: yarnDetail.origin,
        quantity: yarnDetail.quantity, unitPrice: yarnDetail.unitPrice,
        amount: parseFloat(yarnDetail.amount) }
      ))

      commit(MODIFY_YARN_PURCHASE_ORDER_GENERAL, payload)
      commit(MODIFY_YARN_PURCHASE_ORDER_FOOTER, footer)
      commit(MODIFY_YARN_PURCHASE_ORDER_DETAIL, [])
      commit(MODIFY_YARN_PURCHASE_ORDER_DETAIL, detail)
    }
  } catch(error) {
    const response = error.response
    const message = response.data.message
    const status = {
      status: 'error',
      message
    }
    commit(SET_YARN_PO_STATUS, status)
  }
 }

export default {
  getProviders,
  getVendors,
  modifyYarnPurchaseOrderDetail,
  getYarns,
  modifyYarnPurchaseOrderFooter,
  modifyYarnPurchaseOrderGeneral,
  createYarnPo,
  setYarnPOStatus,
  resetYarnPO,
  getYarnsPos,
  getYarnsPoById,
  getYarnsOfPo,
  updateYarnPo
}

