import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './plugins/vuex';
import vuetify from './plugins/vuetify';
import i18n from './i18n'
import Interceptors from './plugins/interceptors';
import './styles/application.scss';

Vue.config.productionTip = false;

const interceptors = Interceptors(store)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  interceptors,
  render: (h) => h(App)
}).$mount('#app');
