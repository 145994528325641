import URI from 'urijs'
import Vue from 'vue';
import { config } from '../../plugins/env.config';

async function createTexture(payload, attemptNumber = 1) {
  const url = new URI(config.apiBaseUrl)
  url.segment('texture')
  url.segment('create')

  try {
    return await Vue.prototype.$http.post(url.toString(), payload)
  } catch (error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return createTexture(payload, attemptNumber + 1)
    }

    throw error
  }
}

async function createVendor(payload, attemptNumber = 1) {
  const url = new URI(config.apiBaseUrl)
  url.segment('vendor')
  url.segment('create')

  try {
    return await Vue.prototype.$http.post(url.toString(), payload)
  } catch (error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return createVendor(payload, attemptNumber + 1)
    }

    throw error
  }
}

async function createProvider(payload, attemptNumber = 1) {
  const url = new URI(config.apiBaseUrl)
  url.segment('provider')
  url.segment('create')

  try {
    return await Vue.prototype.$http.post(url.toString(), payload)
  } catch (error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return createProvider(payload, attemptNumber + 1)
    }

    throw error
  }
}

async function createBuyer(payload, attemptNumber = 1) {
  const url = new URI(config.apiBaseUrl)
  url.segment('vendor')
  url.segment('create-buyer')

  try {
    return await Vue.prototype.$http.post(url.toString(), payload)
  } catch (error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return createBuyer(payload, attemptNumber + 1)
    }

    throw error
  }
}

async function getAllTextures(attemptNumber = 1) {
  const url = new URI(config.apiBaseUrl)
  url.segment('texture')

  try {
    return await Vue.prototype.$http.get(url.toString())
  } catch (error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getAllTextures(attemptNumber + 1)
    }

    throw error
  }
}

async function getTexture(payload, attemptNumber = 1) {
  const url = new URI(config.apiBaseUrl)
  url.segment('texture')
  url.segment(payload)

  try {
    return await Vue.prototype.$http.get(url.toString())
  } catch (error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getTexture(payload, attemptNumber + 1)
    }

    throw error
  }
}

async function getVendor (payload, attemptNumber = 1) {
  const url = new URI(config.apiBaseUrl)
  url.segment('vendor')
  url.segment(payload)

  try {
    return Vue.prototype.$http.get(url.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getVendor(payload, attemptNumber + 1)
    }

    throw error
  }
}

async function getProvider (payload, attemptNumber = 1) {
  const url = new URI(config.apiBaseUrl)
  url.segment('provider')
  url.segment(payload)

  try {
    return Vue.prototype.$http.get(url.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getProvider(payload, attemptNumber + 1)
    }

    throw error
  }
}

async function getBuyer (vendor, buyer, attemptNumber = 1) {
  const url = new URI(config.apiBaseUrl)
  url.segment('vendor')
  url.segment(vendor.toString())
  url.segment('buyers')
  url.segment(buyer)

  if (!vendor) {
    return;
  }

  try {
    return Vue.prototype.$http.get(url.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getBuyer (vendor, buyer, attemptNumber + 1)
    }

    throw error
  }
}

async function getFabricPoChilds(fabricPoId, attemptNumber = 1) {
  const uri = new URI(config.apiBaseUrl)
  uri.segment('fabric-po')
  uri.segment(fabricPoId)
  uri.segment('childs')

  try {
    return await Vue.prototype.$http.get(uri.toString())
  } catch (error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getFabricPoChilds(number, attemptNumber + 1)
    }

    throw error
  }
}

async function deleteFabricPo(fabricPoId, attemptNumber = 1) {
  const uri = new URI(config.apiBaseUrl)
  uri.segment('fabric-po')
  uri.segment(fabricPoId)

  try {
    return await Vue.prototype.$http.delete(uri.toString())
  } catch (error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return deleteFabricPo(fabricPoId, attemptNumber + 1)
    }

    throw error
  }
}

async function createFabricPOFile (payload, attemptNumber = 1) {
  const createFabricPOFileUri = new URI(config.apiBaseUrl)
  createFabricPOFileUri.segment('fabric-po')
  createFabricPOFileUri.segment('add-temporal-file')

  try {
    return await Vue.prototype.$http.post(createFabricPOFileUri.toString(), payload)

  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return createFabricPOFile(payload, attemptNumber + 1)
    }

    throw error
  }
}

async function getFabricPOFile (fabricId, fileId, attemptNumber = 1) {
  const fabricPOFileUri = new URI(config.apiBaseUrl)
  fabricPOFileUri.segment('fabric-po')
  fabricPOFileUri.segment(fabricId)
  fabricPOFileUri.segment('files')
  fabricPOFileUri.segment(fileId)

  try {
    return await Vue.prototype.$http.get(fabricPOFileUri.toString(), { responseType: 'arraybuffer' })

  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getFabricPOFile(fabricId, fileId, attemptNumber + 1)
    }

    throw error
  }
}

export default {
  createTexture, getTexture, getAllTextures, getFabricPoChilds, deleteFabricPo, getVendor,
  createVendor, getBuyer, createBuyer, createFabricPOFile, getFabricPOFile, getProvider,
  createProvider
}
