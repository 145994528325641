export default {
  username: localStorage.getItem('username') || null,
  email: localStorage.getItem('email') || null,
  name: localStorage.getItem('name') || null,
  roles: JSON.parse(localStorage.getItem('roles')) || [],
  functionalities: JSON.parse(localStorage.getItem('functionalities')) || [],
  isAuthenticated: localStorage.getItem('isAuthenticated') || false,
  accessToken: localStorage.getItem('accessToken') || null,
  refreshToken: localStorage.getItem('refreshToken') || null,
  users: [],
  drawer: true
}
