<template>
  <v-col v-if="fabricPoDetail && fabricPoDetail.length === 0">
    <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
  </v-col>
  <v-col v-else class="white rounded pa-8" style="box-shadow: 0px 0px 15px 0px #00000036;">
    <v-row>
      <v-col class="d-flex justify-space-between">
        <span class="text-h6 d-flex">
          <div class="pt-1">
            {{ $t('fabricPurchaseOrder.detail.title') }}
          </div>
        </span>
        <v-btn
          small
          color="secondary"
          dark
          @click="addNewDetail"
        >
          {{ $t('fabricPurchaseOrder.detail.addButtonDetail') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row style="overflow: auto; max-height: 700px">
      <v-col>
        <v-row v-for="(item, detailIndex) in fabricPoDetail" :key="detailIndex" class="py-8" style="border-bottom: solid 3px #e0e0e0;">
          <DetailInformation
            :item="item"
            @input="changeDetailSelection(detailIndex, $event)"
            @delete="deleteDetailSelection(detailIndex)"
            />
          <v-container class="pt-4">
            <v-row no-gutters>
              <v-col>
                <span class="text-h6 pr-3 font-weight-folder">
                  {{ $t('fabricPurchaseOrder.detail.yarnSelection.title') }}
                </span>
                <v-btn icon color="secondary" @click="addNewYarn(detailIndex)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-for="(yarn, yarnIndex) in item.yarns" :key="yarnIndex">
              <YarnSelection
                :item="yarn"
                :yarns="yarns"
                @input="changeYarnSelection(yarnIndex, $event, detailIndex)"
                @delete="deleteYarnSelection(yarnIndex, detailIndex)"
              />
            </v-row>
          </v-container>

          <v-container class="pt-4">
            <v-row no-gutters>
              <v-col>
                <span class="text-h6 pr-3 font-weight-folder">
                  {{ $t('fabricPurchaseOrder.detail.colorSelection.title') }}
                </span>
                <v-btn icon color="secondary" @click="addNewColor(detailIndex)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn
                  depressed
                  small
                  @click="addNewDetailWithColors(detailIndex)"
                >
                  {{ $t('fabricPurchaseOrder.detail.addWithColorButtonDetail') }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-for="(color, colorIndex) in item.colors" :key="colorIndex">
              <ColorSelection
                ref="colorSelection"
                :item="color"
                :unitType="item.unitType"
                @input="changeColorSelection(colorIndex, $event, detailIndex)"
                @delete="deleteColorSelection(colorIndex, detailIndex)"/>
            </v-row>
          </v-container>
        </v-row>
      </v-col>
    </v-row>

  </v-col>
</template>

<script>
import DetailInformation from './DetailInformation.vue'
import YarnSelection from './YarnSelection.vue'
import ColorSelection from './ColorSelection.vue'
import ProcessSelection from './ProcessSelection.vue'
import { createNamespacedHelpers } from 'vuex'

const fabricPoModule = createNamespacedHelpers('fabricPo')
const yarnPoModule = createNamespacedHelpers('yarnPo')

export default {
  name: 'FabricPoDetail',
  components: { DetailInformation, YarnSelection, ProcessSelection, ColorSelection },
  created() {
    this.getYarns();
  },
  computed: {
    ...fabricPoModule.mapGetters(['fabricPoDetail', 'fabricPoDetailYarns']),
    ...yarnPoModule.mapGetters(['yarns']),
  },
  methods: {
    ...fabricPoModule.mapActions(['modifyFabricPoDetail', 'modifyFabricPoDetailYarns']),
    ...yarnPoModule.mapActions(['getYarns']),
    calculateYd(weightYds, item, index) {
      item.unitType = this.fabricPoDetail[index].unitType
      if (this.fabricPoDetail[index].unitType==="KGS"){
      item.yd = item.quantity && ((item.quantity / weightYds) * 1000).toFixed(2) || 0
      }
      if (this.fabricPoDetail[index].unitType==="YDS"){
      item.yd = item.quantity && ((item.quantity / 1000) * weightYds).toFixed(2) || 0
      }

    },
    changeDetailSelection(index, element) {
      const details = this.fabricPoDetail
      details[index] = element
      this.modifyFabricPoDetail(details)
      if (element.weightYds) {
        element.colors.forEach((color, idx) => {
          this.calculateYd(element.weightYds, color, index)
          this.changeColorSelection(idx, color, index)
          setTimeout(() => {
            this.$refs.colorSelection[index].updateContent()
          }, 200)
        })
      }
    },
    deleteDetailSelection(index) {
      const details = this.fabricPoDetail.filter((item, idx) => idx !== index)
      this.modifyFabricPoDetail(details)
    },
    addNewDetail() {
      const details = this.fabricPoDetail;
      details.push({
        yarns: [{}],
        unitType: 'KGS',
        colors: [{}]
      })
      this.modifyFabricPoDetail(details)
    },
    addNewDetailWithColors(index) {
      const details = this.fabricPoDetail;
      const detail = details[index]
      if (!detail.type) {
        return
      }

      const code = detail.type.substring(1,2)
      const type = detail.type.substring(0,1) === 'b' ? 't'.concat(code) : 'b'.concat(code)
      const newColors = detail.colors.map(color => ({ color: color.color, grade: color.grade, number: color.number, }))
      details.push({
        type,
        yarns: [{}],
        colors: newColors
      })
      this.modifyFabricPoDetail(details)
    },
    changeYarnSelection(index, element, detailIndex) {
      const fabricDetails = this.fabricPoDetail
      const detail = fabricDetails[detailIndex]
      if (!detail.yarns) {
        detail['yarns'] = []
      }
      const yarnDetail = detail.yarns
      yarnDetail[index] = element
      fabricDetails[detailIndex].yarns = yarnDetail
      this.modifyFabricPoDetail(fabricDetails)
    },
    deleteYarnSelection(index, detailIndex) {
      const fabricDetails = this.fabricPoDetail
      const detail = fabricDetails[detailIndex]
      const yarnDetail = detail.yarns.filter((item, idx) => idx !== index)
      fabricDetails[detailIndex].yarns = yarnDetail
      this.modifyFabricPoDetail(fabricDetails)
      this.$forceUpdate()
    },
    addNewYarn(detailIndex) {
      const fabricDetails = this.fabricPoDetail
      const detail = fabricDetails[detailIndex]
      if (!detail.yarns) {
        detail['yarns'] = []
      }
      detail.yarns.push({})
      fabricDetails[detailIndex] = detail
      this.modifyFabricPoDetail(fabricDetails)
      this.$forceUpdate()
    },
    changeColorSelection(index, element, detailIndex) {
      const fabricDetails = this.fabricPoDetail
      const detail = fabricDetails[detailIndex]
      if (!detail.colors) {
        detail['colors'] = []
      }
      const colorDetail = detail.colors
      colorDetail[index] = element
      fabricDetails[detailIndex].colors = colorDetail
      this.calculateYd(detail.weightYds, element, detailIndex)
      this.modifyFabricPoDetail(fabricDetails)
      setTimeout(() => {
        this.$refs.colorSelection[detailIndex].updateContent()
      }, 200)
    },
    deleteColorSelection(index, detailIndex) {
      const fabricDetails = this.fabricPoDetail
      const detail = fabricDetails[detailIndex]
      const colorDetail = detail.colors.filter((item, idx) => idx !== index)
      fabricDetails[detailIndex].colors = colorDetail
      this.modifyFabricPoDetail(fabricDetails)
      this.$forceUpdate()
    },
    addNewColor(detailIndex) {
      const fabricDetails = this.fabricPoDetail
      const detail = fabricDetails[detailIndex]
      if (!detail.colors) {
        detail['colors'] = []
      }
      detail.colors.push({})
      fabricDetails[detailIndex] = detail
      this.modifyFabricPoDetail(fabricDetails)
      this.$forceUpdate()
    },
    getItemYarns(item) {
      return item.yarns || []
    }
  }
}
</script>
