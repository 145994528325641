import {
  SET_YARN_OUTBOUND,
  SET_YARN_OUTBOUNDS,
  SET_OUTBOUND_MESSAGE,
  SET_YARN_OUTBOUND_YARNS,
  SET_YARN_OUTBOUND_BY_ID
} from '../mutation-types'

export default {
  [SET_YARN_OUTBOUND] (state, payload) {
    state.yarnOutbound = payload
  },
  [SET_YARN_OUTBOUNDS] (state, payload) {
    state.yarnOutbounds = payload.data
    state.yarnOutboundPage = payload.meta
  },
  [SET_OUTBOUND_MESSAGE] (state, payload) {
    state.message = payload
  },
  [SET_YARN_OUTBOUND_YARNS] (state, payload) {
    state.yarnSelection = payload
  },
  [SET_YARN_OUTBOUND_BY_ID] (state, payload) {
    state.yarnOutboundById = payload
  }
}
