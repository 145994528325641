import axios from 'axios';
import URI from 'urijs'
import Vue from 'vue';
import { config } from '../plugins/env.config';

async function getColors() {
  return axios.get('http://localhost:3000/api/v1/colors')
}

async function login (email, password, attemptNumber = 1) {
  const getLogin = new URI(config.apiBaseUrl)
  getLogin.segment('login')

  const body = { email, password }

  return axios.post(getLogin.toString(), body, {})
}

async function refreshToken (refreshToken, attemptNumber = 1) {
  const refreshTokenUri = new URI(config.apiBaseUrl)
  refreshTokenUri.segment('refresh-token')

  const body = { refreshToken }

  return axios.post(refreshTokenUri.toString(), body, {})
}

async function getProviders (attemptNumber = 1) {
  const getProvidersUri = new URI(config.apiBaseUrl)
  getProvidersUri.segment('provider')

  try {
    return Vue.prototype.$http.get(getProvidersUri.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getProviders(attemptNumber + 1)
    }

    throw error
  }
}

async function getVendors (attemptNumber = 1) {
  const getClientsUri = new URI(config.apiBaseUrl)
  getClientsUri.segment('vendor')

  try {
    return Vue.prototype.$http.get(getClientsUri.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getVendors(attemptNumber + 1)
    }

    throw error
  }
}

async function getYarns (attemptNumber = 1) {
  const getYarnsUri = new URI(config.apiBaseUrl)
  getYarnsUri.segment('yarn')

  try {
    return Vue.prototype.$http.get(getYarnsUri.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getYarns(attemptNumber + 1)
    }

    throw error
  }
}

async function getYarn(payload, attemptNumber = 1) {
  const url = new URI(config.apiBaseUrl)
  url.segment('yarn')
  url.segment(payload)

  try {
    return Vue.prototype.$http.get(url.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getYarn(attemptNumber + 1)
    }

    throw error
  }
}

async function createYarn(payload, attemptNumber = 1) {
  const url = new URI(config.apiBaseUrl)
  url.segment('yarn')
  url.segment('create')

  try {
    return await Vue.prototype.$http.post(url.toString(), payload)
  } catch (error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return createYarn(payload, attemptNumber + 1)
    }

    throw error
  }
}

async function createYarnPO (payload, attemptNumber = 1) {
  const createYarnPOUri = new URI(config.apiBaseUrl)
  createYarnPOUri.segment('yarn-purchase-order')
  createYarnPOUri.segment('create')

  try {
    return await Vue.prototype.$http.post(createYarnPOUri.toString(), payload)

  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return createYarnPO(payload, attemptNumber + 1)
    }

    throw error
  }
}

async function updateYarnPo (payload, yarnPoId, attemptNumber = 1) {
  const yarnPOUri = new URI(config.apiBaseUrl)
  yarnPOUri.segment('yarn-purchase-order')
  yarnPOUri.segment(yarnPoId)
  yarnPOUri.segment('edit')

  try {
    return await Vue.prototype.$http.put(yarnPOUri.toString(), payload)

  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return updateYarnPo(payload, yarnPoId, attemptNumber + 1)
    }

    throw error
  }
}

async function getYarnPOS(filters, attemptNumber = 1) {
  const getYarnPOUri = new URI(config.apiBaseUrl)
  getYarnPOUri.segment('yarn-purchase-order')
  getYarnPOUri.segment('list')

  if (filters.page) {
    getYarnPOUri.addQuery('page', filters.page)
  }

  if (filters.limit) {
    getYarnPOUri.addQuery('limit', filters.limit)
  }

  if (filters.searchBy) {
    getYarnPOUri.addQuery('searchBy', filters.searchBy)
  }

  if (filters.search) {
    getYarnPOUri.addQuery('search', filters.search)
  }

  try {
    return await Vue.prototype.$http.get(getYarnPOUri.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getYarnPO(filters, attemptNumber + 1)
    }

    throw error
  }
}

async function getYarnsOfPos(id, attemptNumber = 1) {
  const getYarnsOfPoUri = new URI(config.apiBaseUrl)
  getYarnsOfPoUri.segment('yarn-purchase-order')
  getYarnsOfPoUri.segment(id)
  getYarnsOfPoUri.segment('yarns')

  try {
    return await Vue.prototype.$http.get(getYarnsOfPoUri.toString())
  } catch (error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getYarnsOfPos(id, attemptNumber + 1)
    }

    throw error
  }

}

async function getYarnsPoById(id,attemptNumber = 1) {
  const getYarnPOUri = new URI(config.apiBaseUrl)
  getYarnPOUri.segment('yarn-purchase-order')
  getYarnPOUri.segment('poById')
  getYarnPOUri.segment(id)

  try {
    return await Vue.prototype.$http.get(getYarnPOUri.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getYarnsPoById(id, attemptNumber + 1)
    }

    throw error
  }
}

async function getPlants(process = null, attemptNumber = 1) {
  const getPlantsUri = new URI(config.apiBaseUrl)
  getPlantsUri.segment('plants')

  if (process) {
    getPlantsUri.addSearch(process)
  }

  try {
    return await Vue.prototype.$http.get(getPlantsUri.toString())
  } catch (error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getPlants(attemptNumber + 1)
    }

    throw error
  }
}

async function createYarnInbound(payload, attemptNumber = 1) {
  const createYarnInboundUri = new URI(config.apiBaseUrl)
  createYarnInboundUri.segment('yarn-inbound')
  createYarnInboundUri.segment('create')

  try {
    return await Vue.prototype.$http.post(createYarnInboundUri.toString(), payload)
  } catch (error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return createYarnInbound(payload, attemptNumber + 1)
    }

    throw error
  }
}

async function updateYarnInbound(payload, yarnInboundId, attemptNumber = 1) {
  const yarnInboundURI = new URI(config.apiBaseUrl)
  yarnInboundURI.segment('yarn-inbound')
  yarnInboundURI.segment(yarnInboundId)
  yarnInboundURI.segment('edit')

  try {
    return await Vue.prototype.$http.put(yarnInboundURI.toString(), payload)
  } catch (error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return updateYarnInbound(payload, yarnInboundId, attemptNumber + 1)
    }

    throw error
  }
}

async function getYarnInbounds(filters, attemptNumber = 1) {
  const getYarnInboundUri = new URI(config.apiBaseUrl)
  getYarnInboundUri.segment('yarn-inbound')
  getYarnInboundUri.segment('list')

  if (filters.page) {
    getYarnInboundUri.addQuery('page', filters.page)
  }

  if (filters.limit) {
    getYarnInboundUri.addQuery('limit', filters.limit)
  }

  try {
    return await Vue.prototype.$http.get(getYarnInboundUri.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getYarnInbounds(filters, attemptNumber + 1)
    }

    throw error
  }
}

async function getYarnInboundsById(id, attemptNumber = 1) {
  const getYarnInboundUri = new URI(config.apiBaseUrl)
  getYarnInboundUri.segment('yarn-inbound')
  getYarnInboundUri.segment(id)
   try {
    return await Vue.prototype.$http.get(getYarnInboundUri.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay
    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getYarnInbounds(id, attemptNumber + 1)
    }

    throw error
  }
}

async function getFabricPo(fabricId, attemptNumber = 1) {
  const fabricPoUri = new URI(config.apiBaseUrl)
  fabricPoUri.segment('fabric-po')
  fabricPoUri.segment(fabricId)

  try {
    return await Vue.prototype.$http.get(fabricPoUri.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getFabricPo(fabricId, attemptNumber + 1)
    }

    throw error
  }
}

async function createFabricPO (payload, attemptNumber = 1) {
  const createFabricPOUri = new URI(config.apiBaseUrl)
  createFabricPOUri.segment('fabric-po')
  createFabricPOUri.segment('create')

  try {
    return await Vue.prototype.$http.post(createFabricPOUri.toString(), payload)

  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return createFabricPO(payload, attemptNumber + 1)
    }

    throw error
  }
}

async function updateFabricPo(payload, fabricPoId, attemptNumber = 1) {
  const fabricPoUri = new URI(config.apiBaseUrl)
  fabricPoUri.segment('fabric-po')
  fabricPoUri.segment(fabricPoId)
  fabricPoUri.segment('edit')

  try {
    return await Vue.prototype.$http.put(fabricPoUri.toString(), payload)
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return updateFabricPo(payload, fabricPoId, attemptNumber + 1)
    }

    throw error
  }
}

async function getFabricPOS(filters, attemptNumber = 1) {
  const getFabricPOUri = new URI(config.apiBaseUrl)
  getFabricPOUri.segment('fabric-po')
  getFabricPOUri.segment('list')

  if (filters.page) {
    getFabricPOUri.addQuery('page', filters.page)
  }

  if (filters.limit) {
    getFabricPOUri.addQuery('limit', filters.limit)
  }

  if (filters.searchBy) {
    getFabricPOUri.addQuery('searchBy', filters.searchBy)
  }

  if (filters.search) {
    getFabricPOUri.addQuery('search', filters.search)
  }

  try {
    return await Vue.prototype.$http.get(getFabricPOUri.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getFabricPOS(filters, attemptNumber + 1)
    }

    throw error
  }
}

async function createYarnOutbound(payload, attemptNumber = 1) {
  const createYarnOutboundUri = new URI(config.apiBaseUrl)
  createYarnOutboundUri.segment('yarn-outbound')
  createYarnOutboundUri.segment('create')

  try {
    return await Vue.prototype.$http.post(createYarnOutboundUri.toString(), payload)
  } catch (error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return createYarnOutbound(payload, attemptNumber + 1)
    }

    throw error
  }
}

async function getYarnOutbounds(filters, attemptNumber = 1) {
  const getYarnOutboundUri = new URI(config.apiBaseUrl)
  getYarnOutboundUri.segment('yarn-outbound')
  getYarnOutboundUri.segment('list')

  if (filters.page) {
    getYarnOutboundUri.addQuery('page', filters.page)
  }

  if (filters.limit) {
    getYarnOutboundUri.addQuery('limit', filters.limit)
  }

  try {
    return await Vue.prototype.$http.get(getYarnOutboundUri.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getYarnOutbounds(filters, attemptNumber + 1)
    }

    throw error
  }
}

async function getYarnsOfPoFabric(id, attemptNumber = 1) {
  const getYarnsOfPoUri = new URI(config.apiBaseUrl)
  getYarnsOfPoUri.segment('fabric-po')
  getYarnsOfPoUri.segment(id)
  getYarnsOfPoUri.segment('yarns')

  try {
    return await Vue.prototype.$http.get(getYarnsOfPoUri.toString())
  } catch (error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getYarnsOfPo(id, attemptNumber + 1)
    }

    throw error
  }
}

async function getFabricPoById(id, attemptNumber = 1) {
  const getFabricPOUri = new URI(config.apiBaseUrl)
  getFabricPOUri.segment('fabric-po')
  getFabricPOUri.segment('fabricPo')
  getFabricPOUri.segment(id)

  try {
    return await Vue.prototype.$http.get(getFabricPOUri.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay
    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getYarnInbounds(id, attemptNumber + 1)
    }

    throw error
  }
}

async function getYarnOutbound(id, attemptNumber = 1) {
  const getYarnOutboundUri = new URI(config.apiBaseUrl)
  getYarnOutboundUri.segment('yarn-outbound')
  getYarnOutboundUri.segment(id)
   try {
    return await Vue.prototype.$http.get(getYarnOutboundUri.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay
    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getYarnOutbound(id, attemptNumber + 1)
    }

    throw error
  }
}

async function updateYarnOutbound(payload, yarnOutboundId, attemptNumber = 1) {
  const yarnOutboundUri = new URI(config.apiBaseUrl)
  yarnOutboundUri.segment('yarn-outbound')
  yarnOutboundUri.segment(yarnOutboundId)
  yarnOutboundUri.segment('edit')

  try {
    return await Vue.prototype.$http.put(yarnOutboundUri.toString(), payload)
  } catch (error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return updateYarnOutbound(payload, yarnOutboundId, attemptNumber + 1)
    }

    throw error
  }
}

async function getProcessFile(id, attemptNumber = 3) {
  const url = new URI(config.apiBaseUrl)
  url.segment('fabric-po')
  url.segment('process')
  url.segment('images')
  url.segment(id)
  try {
    return await Vue.prototype.$http.get(url.toString(), { responseType: 'arraybuffer' })
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay
    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getProcessFile(id, attemptNumber + 1)
    }

    throw error
  }
}

async function getUsers (attemptNumber = 1) {
  const uri = new URI(config.apiBaseUrl)
  uri.segment('user')

  try {
    return Vue.prototype.$http.get(uri.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getUsers(attemptNumber + 1)
    }

    throw error
  }
}

async function getYarnInventary(attemptNumber = 3) {
  const url = new URI(config.apiBaseUrl)
  url.segment('yarn')
  url.segment('inventary')
  url.segment('list')

  try {
    return await Vue.prototype.$http.get(url.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay
    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getYarnInventary(attemptNumber + 1)
    }

    throw error
  }
}

async function getCurrentYarnInventary(yarnId, attemptNumber = 3) {
  const url = new URI(config.apiBaseUrl)
  url.segment('yarn')
  url.segment(yarnId.toString())
  url.segment('inventary')

  try {
    return await Vue.prototype.$http.get(url.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay
    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getYarnInventary(attemptNumber + 1)
    }

    throw error
  }
}


export default { getColors, login, getProviders, getVendors, getYarns, createYarnPO, getYarnPOS,
  getYarnsOfPos, getPlants, createYarnInbound, getYarnInbounds, getYarnsPoById, createFabricPO,
  getYarnInboundsById, getFabricPOS, refreshToken, createYarnOutbound, getYarnOutbounds,
  getYarnsOfPoFabric, updateYarnPo, updateYarnInbound, getFabricPo, updateFabricPo, getYarnOutbound, updateYarnOutbound,
  getFabricPoById, getProcessFile, getUsers, getYarn, createYarn, getYarnInventary, getCurrentYarnInventary }
