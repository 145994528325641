<template>
  <v-col>
    <v-textarea
      outlined 
      background-color="white" 
      :value="footer.terms"
      @input="change($event, 'terms')"
      :label="$t('yarnPurchaseOrder.footer.terms')">

    </v-textarea>
  </v-col>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    footer: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    change(value, field) {
      const newTerms = this.footer
      newTerms[field] = value
      this.$emit('input', newTerms)
    }
  }
}
</script>