import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/plugins/vuex'

import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import YarnPurchaseOrder from '../views/yarn-purchase-order/YarnPurchaseOrder';
import YarnPOList from '../views/yarn-purchase-order/List'
import YarnLayout from '../views/yarn-purchase-order/YarnLayout'
import YarnInboundLayout from '../views/yarn-inbound/YarnInboundLayout'
import YarnInbound from '../views/yarn-inbound/YarnInbound'
import printablePOYarn from '../views/yarn-purchase-order/printablePoYarn'
import printableInbounds from '../views/yarn-inbound/printableInbounds'
import YarnInboundList from '../views/yarn-inbound/List'

import FabricPoLayout from '../views/fabric-po/FabricPoLayout'
import FabricPo from '../views/fabric-po/FabricPo'
import FabricPoList from '../views/fabric-po/List'
import printablePoFabric from '../views/fabric-po/printablePoFabric'
import printablePoDyeing from '../views/fabric-po/printablePoDyeing'
import printablePoPrinting from '../views/fabric-po/printablePoPrinting'
import precostReport from '../views/fabric-po/precostReport'

import YarnOutboundLayout from '../views/yarn-outbound/YarnOutboundLayout'
import YarnOutbound from '../views/yarn-outbound/YarnOutbound'
import YarnOuboundList from '../views/yarn-outbound/YarnOutboundList'

import ProviderInvoiceList from '../views/provider-invoice/List'
import ProviderInvoice from '../views/provider-invoice/ProviderInvoice'
import ProviderInvoiceLayout from '../views/provider-invoice/ProviderInvoiceLayout'

import YarnInventaryLayout from '../views/yarn-inventary/YarnInventaryLayout'
import YarnInventaryList from '../views/yarn-inventary/List'
import CurrentYarnInventary from '../views/yarn-inventary/YarnPlantInventary.vue'

Vue.use(VueRouter);

const validateIsAuthenticated = (to, from, next) => {
  const { getters } = store;
  const isAuthenticated = getters['auth/isAuthenticated'];
  if (!isAuthenticated) {
    next({ name: 'Login' })
  }

  next()
}

const validatePermission = (to, from, next) => {
  const { getters } = store;
  const functionalities = getters['auth/functionalities'];
  const routeSlug = to.meta.slug
  if (routeSlug) {
    const denyFunctionality = functionalities.some(functionality => functionality.slug === routeSlug && functionality.permission === 'DENY')
    if (denyFunctionality) {
      next({ name: 'Home'})
    }
  }

  next()
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: validateIsAuthenticated
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/yarn-po',
    name: 'YarnPurchaseOrder',
    component: YarnLayout,
    beforeEnter: validateIsAuthenticated,
    children: [
      {
        path: 'new',
        name: 'NewYarnPurchaseOrder',
        component: YarnPurchaseOrder,
      },
      {
        path: 'list',
        name: 'YarnPurchaseOrderList',
        component: YarnPOList
      },
      {
        path: ':id',
        name: 'printablePOYarn',
        component: printablePOYarn,
      },
      {
        path: ':id/edit',
        name: 'EditYarnPurchaseOrder',
        component: YarnPurchaseOrder
      }
    ]
  },
  {
    path: '/yarn-inbound',
    name: 'YarnInbound',
    component: YarnInboundLayout,
    beforeEnter: validateIsAuthenticated,
    children: [
      {
        path: 'new',
        name: 'NewYarnInbound',
        component: YarnInbound
      },
      {
        path: 'list',
        name: 'YarnInboundList',
        component: YarnInboundList
      },
      {
        path: ':id',
        name: 'printableInbounds',
        component: printableInbounds,
        props:(route)=>{
          return{
            id:route.params.id
          }
        }
      },
      {
        path: ':id/edit',
        name: 'EditYarnInbound',
        component: YarnInbound
      }
    ]
  },
  {
    path: '/yarn-inventary',
    name: 'YarnInventary',
    component: YarnInventaryLayout,
    beforeEnter: validateIsAuthenticated,
    children: [
      {
        path: 'list',
        name: 'YarnInventaryList',
        component: YarnInventaryList
      },
      {
        path: ':yarnId/plants',
        name: 'YarnPlantsInventary',
        component: CurrentYarnInventary
      }
    ]
  },
  {
    path: '/yarn-outbound',
    name: 'YarnOutbound',
    component: YarnOutboundLayout,
    beforeEnter: validateIsAuthenticated,
    children: [
      {
        path: 'new',
        name: 'NewYarnOutbound',
        component: YarnOutbound
      },
      {
        path: 'list',
        name: 'YarnOutboundList',
        component: YarnOuboundList
      },
      {
        path: ':id/edit',
        name: 'EditYarnOutbound',
        component: YarnOutbound
      }
    ]
  },
  {
    path: '/fabric-po',
    name: 'FabricPo',
    component: FabricPoLayout,
    beforeEnter: validateIsAuthenticated,
    children: [
      {
        path: 'new',
        name: 'NewFabricPo',
        meta: {slug: 'fabric-create'},
        beforeEnter: validatePermission,
        component: FabricPo
      },
      {
         path: ':id/aditional',
        name: 'NewAditionalFabricPo',
        meta: {slug: 'fabric-create-additional'},
        beforeEnter: validatePermission,
        component: FabricPo
      },
      {
        path: 'knitting/:id',
        name: 'printablePoFabric',
        meta: {slug: 'fabric-print-knitting'},
        beforeEnter: validatePermission,
        component: printablePoFabric,
        props:(route)=>{
          return{
            id:route.params.id
          }
        }
      },
      {
        path: 'dyeing/:id',
        name: 'printablePoDyeing',
        meta: {slug: 'fabric-print-dyeing'},
        beforeEnter: validatePermission,
        component: printablePoDyeing,
        props:(route)=>{
          return{
            id:route.params.id
          }
        }
      },
      {
        path: 'printing/:id',
        name: 'printablePoPrinting',
        meta: {slug: 'fabric-print-printing'},
        beforeEnter: validatePermission,
        component: printablePoPrinting,
        props:(route)=>{
          return{
            id:route.params.id
          }
        }
      },
      {
        path: 'precost/:id',
        name: 'precostReport',
        meta: {slug: 'precost-report'},
        beforeEnter: validatePermission,
        component: precostReport,
        props:(route)=>{
          return{
            id:route.params.id
          }
        }
      },
      {
        path: 'list',
        name: 'FabricPoList',
        meta: {slug: 'fabricpo-view-list'},
        beforeEnter: validatePermission,
        component: FabricPoList
      },
      {
        path: ':id/edit',
        name: 'EditFabricPo',
        meta: {slug: 'fabric-edit'},
        beforeEnter: validatePermission,
        component: FabricPo
      },
      {
        path: ':id/copy',
        name: 'CopyFabricPo',
        meta: {slug: 'fabric-copy'},
        beforeEnter: validatePermission,
        component: FabricPo
      },
      {
        path: ':id/view',
        name: 'ViewFabricPo',
        meta: {slug: 'fabric-view'},
        beforeEnter: validatePermission,
        component: FabricPo
      }
    ]
  },
  {
    path: '/provider-invoice',
    name: 'ProviderInvoice',
    component: ProviderInvoiceLayout,
    beforeEnter: validateIsAuthenticated,
    children: [
      {
        path: 'new',
        name: 'NewProviderInvoice',
        component: ProviderInvoice
      },
      {
        path: 'list',
        name: 'ProviderInvoiceList',
        component: ProviderInvoiceList
      },
      {
        path: ':id/view',
        name: 'ViewProviderInvoice',
        component: ProviderInvoice
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
