<template>
  <v-container>
    <v-row>
      <v-col>
        <v-autocomplete
          :items="types"
          item-value="id"
          item-text="name"
          :value="item.type"
          @input="change($event, 'type')"
          :label="$t('fabricPurchaseOrder.detail.type')"
          dense outlined background-color="white" hide-details="auto">
        </v-autocomplete>
      </v-col>

      <v-col>
        <div class="d-flex pl-3">
          <span class="text-body-2 pt-2">{{ $t('fabricPurchaseOrder.detail.yds') }}</span>
          <v-switch
            class="my-0 px-1"
            v-model="kgs"
          ></v-switch>
          <span class="text-body-2 pt-2">{{ $t('fabricPurchaseOrder.detail.kgs') }}</span>
        </div>
      </v-col>
      <v-col style="max-width: 40px;" class=" d-flex justify-center">
        <v-btn icon color="error" @click="deleteElement">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="3">
        <v-text-field
          dense
          outlined
          background-color="white"
          :value="item.knittingLoss"
          @input="change(parseFloat($event), 'knittingLoss');"
          :label="$t('fabricPurchaseOrder.detail.knittingLoss')"
          hide-details="auto">
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          dense
          outlined
          background-color="white"
          :value="item.knittingPrice"
          @input="change(parseFloat($event), 'knittingPrice');"
          :label="$t('fabricPurchaseOrder.detail.knittingPrice')"
          hide-details="auto">
        </v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field
          dense
          outlined
          background-color="white"
          :value="item.dyeingLoss"
          @input="change(parseFloat($event), 'dyeingLoss');"
          :label="$t('fabricPurchaseOrder.detail.dyeingLoss')"
          hide-details="auto">
        </v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field
          dense
          outlined
          background-color="white"
          :value="item.printingLoss"
          @input="change(parseFloat($event), 'printingLoss');"
          :label="$t('fabricPurchaseOrder.detail.printingLoss')"
          hide-details="auto">
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="3">
        <v-autocomplete
          v-model="select"
          :loading="loading"
          :items="items"
          cache-items
          :search-input.sync="search"
          outlined
          :value="item.texture"
          @change="change($event, 'texture')"
          dense
          :menu-props="{closeOnContentClick:true}"
          item-text="name"
          item-value="id"
          hide-details
          :label="$t('fabricPurchaseOrder.detail.texture')"
        >
          <template slot="no-data" v-if="search">
            <v-container>
              <v-row>
                <v-col>
                  <span class="text-caption secondary--text">No se encontraron registros</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template slot="append-item" v-if="search">
            <v-container>
              <v-row class="" @click="addCustomTexture">
                <v-col role="button">
                  <span class="text-subtitle-2 primary--text">Agregar textura: <span class="text-subtitle-1">( {{ search }} )</span></span>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="4">
        <v-text-field
          dense
          outlined
          :label="$t('fabricPurchaseOrder.detail.widthUse')"
          :value="item.widthUse"
          @input="change(parseFloat($event), 'widthUse');calculateTotals()"
          background-color="white" hide-details="auto">
        </v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          dense
          outlined
          :label="$t('fabricPurchaseOrder.detail.weightGsm')"
          :value="item.weightGsm"
          @input="change(parseFloat($event), 'weightGsm');calculateTotals()"
          background-color="white" hide-details="auto">
        </v-text-field>
      </v-col>

    </v-row>

    <v-row>
      <v-col>
        <span class="text-subtitle-1 pr-3"> {{ $t('fabricPurchaseOrder.detail.widthTotal') }}: </span>
        <span class="text-body-1 secondary--text font-weight-bold"> {{ widthTotal }} </span>
      </v-col>

      <v-col>
        <span class="text-subtitle-1 pr-3"> {{ $t('fabricPurchaseOrder.detail.weightYds') }}: </span>
        <span class="text-body-1 secondary--text font-weight-bold"> {{ weightYds }} </span>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import fabricService from '../../services/modules/fabric.service'

export default {
  name: 'FabricPoDetail',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    widthTotal: null,
    weightYds: null,
    yd: null,
    search: null,
    select: null,
    items: [],
    loading: null
  }),
  async created() {
    const response = await fabricService.getAllTextures()
    this.items = response.data
  },
  mounted() {
    this.changeUnitType('unitType', true)
    this.calculateTotals()
  },
  methods: {
    changeUnitType(field, event) {
      const row = this.item;
      if (event) {
        row[field] = 'KGS'
      } else {
        row[field] = 'YDS'
      }
      this.$emit('input', row);
    },
    calculateTotals() {
      const widthUse = this.item.widthUse ? parseFloat(this.item.widthUse) : null;
      const weightGsm = this.item.weightGsm ? parseFloat(this.item.weightGsm) : null;
      this.widthTotal = widthUse && (widthUse + 2).toFixed(2) || 0
      this.weightYds = weightGsm && (weightGsm * 1.3935 * (this.widthTotal / 60)).toFixed(2) || 0
      this.change(this.weightYds, 'weightYds')
    },
    change(value, field) {
      
      const row = this.item;
      row[field] = value;
      this.$emit('input', row);
    },
    deleteElement() {
      this.$emit('delete')
    },
    async searchTexture(value) {
      this.loading = true
      const response = await fabricService.getTexture(value)
      this.loading = false
      this.items = response.data
    },
    async addCustomTexture() {
      const response = await fabricService.createTexture({name: this.search})
      this.select = response.data
      this.items = [response.data]
      this.change(this.select.id, 'texture')
    }
  },
  computed: {
    kgs: {
      get() {
        return this.item.unitType === 'KGS'
      },
      set(value) {
        this.changeUnitType('unitType', value)
      }
    },
    selectedTexture() {
      return this.item.texture
    },
    executeFormula() {
      return this.item.widthUse && this.item.weightGsm
    },
    types() {
      return [
        {
          id: 'b1',
          name: 'b1'
        },
        {
          id: 'b2',
          name: 'b2'
        },
        {
          id: 'b3',
          name: 'b3'
        },
        {
          id: 't1',
          name: 't1'
        },
        {
          id: 't2',
          name: 't2'
        },
        {
          id: 't3',
          name: 't3'
        }
      ]
    }
  },
  watch: {
    search(value) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        value && value !== this.select && this.searchTexture(value)
      }, 500)
    },
    items() {
      if (this.items && this.items.length > 0) {
        this.select = this.item.texture
      }
    },
    selectedTexture() {
      if (this.items && this.items.length > 0) {
        this.select = this.item.texture
      }
    },
    executeFormula() {
      this.calculateTotals()
    }
  }
}
</script>
