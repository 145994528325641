import moment from "moment"

export default {
  vendors: state => state.vendors,
  general: state => state.general,
  fabricPoStatus: state => state.fabricPoStatus,
  fabricPoDetail: state => state.detail,
  fabricPoDetailYarns: state => state.yarns,
  fabricPos: state => {
    if (state.fabricPos.length === 0 ) {
      return state.fabricPos
    }

    return state.fabricPos.map(fabricPo => ({
      ...fabricPo,
      formattedDate: moment(fabricPo.createdAt).format('YYYY-MM-DD HH:mm'),
      versions: fabricPo.versions.map(version => ({
        ...version,
        formattedDate: moment(version.createdAt).format('YYYY-MM-DD HH:mm')
      }))
    }))
  },
  fabricPoPage: state => state.fabricPoPage,
  plants: state => state.plants,
  currentFabricPoYarns: state => state.currentPoFabricYarns,
  fabricPo: state => state.fabricPo,
  fabricPoById: state => state.fabricPoById,
  fabricPosVersions: state => {
    return state.fabricPosVersions.map(version => ({
      ...version,
      formattedDate: moment(version.updatedAt).format('YYYY-MM-DD HH:MM')
    }))
  },
  uploadingFile: state => state.uploadingFiles,
  files: state => state.files
}
