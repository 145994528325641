import moment from 'moment'

export default {
  yarnInbound: state => state.yarnInbound,
  plants: state => state.plants,
  yarnInboundMessage: state => state.message,
  yarnInbounds: state => {
    return state.yarnInbounds.map(yarnPo => ({
      ...yarnPo,
      formattedDate: moment(yarnPo.createdAt).format('YYYY-MM-DD'),
      inbounds: yarnPo.yarnInbounds.map(inbound => ({
        ...inbound,
        formattedDate: moment(yarnPo.admissionDate).format('YYYY-MM-DD')
      }))
    }))
  },
  yarnInboundPage: state => state.yarnInboundPage,
  yarnInboundById: state => state.yarnInboundById,
  yarnInventary: state => state.yarnInventary
}
