import backendService from '../../services/backend.service'
import router from '@/router'
import moment from 'moment'
import { SET_OUTBOUND_MESSAGE, SET_YARN_OUTBOUND, SET_YARN_OUTBOUNDS,
  SET_YARN_OUTBOUND_YARNS, SET_YARN_OUTBOUND_BY_ID } from '../mutation-types'

const setYarnOutbound = ({ commit }, payload ) => {
  commit(SET_YARN_OUTBOUND, payload)
}

const setYarnSelection = ({ commit }, payload) => {
  commit(SET_YARN_OUTBOUND_YARNS, payload)
}

const createYarnOutbound = async ({ commit, state }) => {
  const { date, fabricPo,
  lossPercentage, quantity, knittingPlant, dyeingPlant, closePo = false } = state.yarnOutbound

  const details = state.yarnSelection;

  try {
    const departureDate = moment(date).toISOString()

    const payload = {
      departureDate, fabricPo, lossPercentage, quantity,
      knittingPlant, dyeingPlant, details, closePo
    }

    await backendService.createYarnOutbound(payload)

    const status = {
      alert: true,
      type: 'success',
      message: 'yarnOutbound.createdOutbound'
    }

    commit(SET_OUTBOUND_MESSAGE, status)

    setTimeout(() => {
      router.push('/yarn-outbound/list')
    }, 1500)
  } catch (error) {
    const response = error.response
    const message = response.data.message

    const status = {
      alert: true,
      type: 'error',
      message
    }
    commit(SET_OUTBOUND_MESSAGE, status)
  }
}

const updateYarnOutbound = async ({ commit, state }) => {
  const { date, fabricPo,
  lossPercentage, quantity, knittingPlant, dyeingPlant, closePo = false } = state.yarnOutbound

  const details = state.yarnSelection;

  try {
    const departureDate = moment(date).toISOString()

    const payload = {
      departureDate, fabricPo, lossPercentage, quantity,
      knittingPlant, dyeingPlant, details, closePo
    }

    await backendService.updateYarnOutbound(payload, state.yarnOutboundById.id)

    const status = {
      alert: true,
      type: 'success',
      message: 'yarnOutbound.createdOutbound'
    }

    commit(SET_OUTBOUND_MESSAGE, status)

    setTimeout(() => {
      router.push('/yarn-outbound/list')
    }, 1500)
  } catch (error) {
    const response = error.response
    const message = response.data.message

    const status = {
      alert: true,
      type: 'error',
      message
    }
    commit(SET_OUTBOUND_MESSAGE, status)
  }
}

const setMessage = ({ commit }, payload) => {
  commit(SET_OUTBOUND_MESSAGE, payload)
}

const getYarnOutbounds = async ({ commit }, payload) => {
  try {
    const response = await backendService.getYarnOutbounds(payload)
    const data = response.data
    commit(SET_YARN_OUTBOUNDS, data)
  } catch(error) {
    const response = error.response
    const message = response.data.message

    const status = {
      alert: true,
      type: 'error',
      message
    }
    commit(SET_OUTBOUND_MESSAGE, status)
  }
}

const getYarnOutbound = async ({ commit, dispatch }, {id, edit}) => {
  try {
    const response = await backendService.getYarnOutbound(id)
    const data = response.data
    commit(SET_YARN_OUTBOUND_BY_ID, data)
    if (edit) {
      const { departureDate, knittingPlant,
        fabricPo, dyeingPlant, lossPercentage, quantity, yarnOutboundDetails } = data

      const date = moment(departureDate).format('YYYY-MM-DD')

      dispatch('fabricPo/getYarnsOfPo', fabricPo.id, { root: true })

      const payload = { date, dyeingPlant: dyeingPlant.slug, fabricPo: fabricPo.id,
      knittingPlant: knittingPlant.slug, lossPercentage: parseFloat(lossPercentage),
      quantity: parseFloat(quantity) }

      const detail = yarnOutboundDetails.map(yarnDetail => ({
        batch: yarnDetail.batch, brand: yarnDetail.brand,
        yarnPercentage: parseFloat(yarnDetail.yarnPercentage),
        yarn: yarnDetail.yarn.id, origin: yarnDetail.origin, unitPrice: yarnDetail.unitPrice
      }))

      commit(SET_YARN_OUTBOUND, payload)

      commit(SET_YARN_OUTBOUND_YARNS, detail)
    }
  } catch(error) {
    const response = error.response
    const message = response.data.message

    const status = {
      alert: true,
      type: 'error',
      message
    }
    commit(SET_OUTBOUND_MESSAGE, status)
  }
}

export default {
  setYarnOutbound,
  createYarnOutbound,
  setMessage,
  getYarnOutbounds,
  setYarnSelection,
  getYarnOutbound,
  updateYarnOutbound
}
