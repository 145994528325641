var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('span',{staticClass:"text-h3 pr-3"},[_vm._v(_vm._s(_vm.$t('yarnInventary.list.title')))])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.yarnInventary,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"primary--text pb-2"},[_vm._v("Total (KGS)")]),_c('span',[_vm._v(_vm._s(header.text))])])]}},{key:"header.quantity",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"primary--text pb-2"},[_vm._v(_vm._s(header.text.split('|')[0]))]),_c('span',[_vm._v(_vm._s(header.text.split('|')[1]))])])]}},{key:"header.floating",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"primary--text pb-2"},[_vm._v(_vm._s(header.text.split('|')[0]))]),_c('span',[_vm._v(_vm._s(header.text.split('|')[1]))])])]}},{key:"header.hold",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"primary--text pb-2"},[_vm._v(_vm._s(header.text.split('|')[0]))]),_c('span',[_vm._v(_vm._s(header.text.split('|')[1]))])])]}},{key:"header.total",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"primary--text pb-2"},[_vm._v(_vm._s(header.text.split('|')[0]))]),_c('span',[_vm._v(_vm._s(header.text.split('|')[1]))])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" mdi-eye ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }