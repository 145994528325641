<template>

    <v-container v-if="fabricPoById[0]">
    <v-col id="vCol" class="white rounded pa-8" style="box-shadow: 0px 0px 15px 0px #00000036;">
    <div id="printableArea" class="estilo" style="font-family:malgun; width: 100%;">
        <v-col>
            <v-row>
                <v-col cols="3" >
                    <img src="../../assets/logo2.png" alt="logo" width="240px" height="200px" >
                </v-col>
                <v-row style="width: 100%;" class="align-center">
            <v-col>
                <h2 style="text-align:center; font-weight: bolder;">PRECOST</h2>
            </v-col>
        </v-row>
        </v-row>
        </v-col>
        <h4>Vendor Information</h4>
        
<table class="dataPoInfo">
    <thead>
        <td>PO Date</td>
        <td>VENDOR PO#</td>
        <td>VENDOR</td>
        <td>BUYER</td>
        <td>YARN DESCRIPTION</td>
        <td>TEXTURE</td>
        <td> QTY (KGS) </td>
        <td> QTY (YDS) </td>
        <td> U/PRICE $ </td>
        <td> TOTAL AMT </td>
    </thead>
    
    <tr v-for="detail in details" :key="detail.index">
        <td>{{new Date(fabricPoById[0].dateOfDelivery).toLocaleDateString()}}</td>
        <td>{{ fabricPoById[0].number }}</td>
        <td>{{ fabricPoById[0].vendor.name}}</td>
        <td>{{ fabricPoById[0].buyer.name}}</td>
        <td>{{ detail.yarns[0].yarn.name }}</td>
        <td>{{ detail.texture.name }}</td>
        <td>{{ detail.sumaKG  }}</td>
        <td>{{ formato(detail.sumaYARN)  }}</td>
        <td>{{ detail.uPrice}}</td>
        <td>{{ detail.sumaAMT }}</td>
    </tr>
    <td colspan="6">TOTAL</td>
    <td>{{ formato(fabricPoById[0].totalKGS) }}</td>
    <td>{{ formato(fabricPoById[0].totalYDS) }}</td>
    <td></td>
    <td>$ {{ formato(fabricPoById[0].totalAMT) }}</td>
</table>
<div class="separate"></div>

<div v-for="yarn in yarnspertype" :key="yarn.index">
        <div v-if="yarn.type==='1'">
<H4>YARN 1 B1/T1</H4>
<table class="dataPoInfo">
    <thead>
        <td>YARN DESCRIPTION</td>
        <td>YARN ORIGEN</td>        
        <td>YARN (%)</td>
        <td>YARN QTY (KGS)</td>
        <td>YARN QTY (YDS)</td>
        <td>YARN PRICE ($/KGS)</td>
        <td> YARN AMT ($) </td>
    </thead>
        <tr v-for="yarnb1 in yarn.details" :key="yarnb1.index">
        <td>{{yarnb1.yarns[0].yarn.name}}</td>
        <td>{{fabricPoById[0].tlc}}</td>      
        <td>{{ yarnb1.yarns[0].percentage }}</td>
        <td>{{ formato(yarnb1.yarns[0].hold) }} kgs</td>
        <td>{{ formato(yarnb1.sumaYARN) }} </td>
        <td>$ {{yarnb1.yarns[0].price}}</td>
        <td>$ {{ formato(yarnb1.yarns[0].hold * yarnb1.yarns[0].price)  }}</td>
    </tr>
        
    <tr v-for="yarnb1 in yarnspertype" :key="yarnb1.index">
        <template v-if="yarnb1.type === '1'">
        <td colspan="3">BALANCE</td>
        <td>{{ formato(yarnb1.yarnQty) }} kgs</td>
        <td>{{ formato(yarnb1.yarnQtyYd) }} yds</td>
        <td colspan="1"></td>
        <td>$ {{ formato(yarnb1.yarnAmt) }}</td>
    </template>
    </tr>
    
</table>
</div>
    </div>
    </div>

<div v-if="yarnspertype[1]">
<div class="separate"></div>
<div v-for="yarn in yarnspertype" :key="yarn.index">
        <div v-if="yarn.type==='2'">
<H4>YARN 2 B2/T2</H4>
<table class="dataPoInfo">
    <thead>
        <td>YARN DESCRIPTION</td>
        <td>YARN ORIGEN</td>        
        <td>YARN (%)</td>
        <td>YARN QTY (KGS)</td>
        <td>YARN QTY (YDS)</td>
        <td>YARN PRICE ($/KGS)</td>
        <td> YARN AMT ($) </td>
    </thead>
    <tr v-for="yarnb1 in yarn.details" :key="yarnb1.index">
        <td>{{yarnb1.yarns[0].yarn.name}}</td>
        <td>{{fabricPoById[0].tlc}}</td>      
        <td>{{ yarnb1.yarns[0].percentage }}</td>
        <td>{{ formato(yarnb1.yarns[0].hold) }} kgs</td>
        <td>{{ formato(yarnb1.sumaYARN) }} yds</td>
        <td>$ {{yarnb1.yarns[0].price}}</td>
        <td>$ {{ formato(yarnb1.yarns[0].hold * yarnb1.yarns[0].price)  }}</td>
    </tr>
    <tr v-for="yarnb1 in yarnspertype" :key="yarnb1.index">
        <template v-if="yarnb1.type === '2'">
        <td colspan="3">BALANCE</td>
        <td>{{ formato(yarnb1.yarnQty) }} kgs</td>
        <td>{{ formato(yarnb1.yarnQtyYd) }} yds</td>
        <td colspan="1"></td>
        <td>$ {{ formato(yarnb1.yarnAmt) }}</td>
    </template>
    </tr>
</table>
</div>
</div>
</div>
<div v-if="yarnspertype[2]">
<div class="separate"></div>
<div v-for="yarn in yarnspertype" :key="yarn.index">
        <div v-if="yarn.type==='3'">
<H4>YARN 3 B3/T3</H4>
<table class="dataPoInfo">
    <thead>
        <td>YARN DESCRIPTION</td>
        <td>YARN ORIGEN</td>        
        <td>YARN (%)</td>
        <td>YARN QTY (KGS)</td>
        <td>YARN QTY (YDS)</td>
        <td>YARN PRICE ($/KGS)</td>
        <td> YARN AMT ($) </td>
    </thead>
    <tr v-for="yarnb1 in yarn.details" :key="yarnb1.index">
        <td>{{yarnb1.yarns[0].yarn.name}}</td>
        <td>{{fabricPoById[0].tlc}}</td>      
        <td>{{ yarnb1.yarns[0].percentage }}</td>
        <td>{{ formato(yarnb1.yarns[0].hold) }} kgs</td>
        <td>{{ formato(yarnb1.sumaYARN) }} yds</td>
        <td>$ {{yarnb1.yarns[0].price}}</td>
        <td>$ {{ formato(yarnb1.yarns[0].hold * yarnb1.yarns[0].price)  }}</td>
    </tr>
    <tr v-for="yarnb1 in yarnspertype" :key="yarnb1.index">
        <template v-if="yarnb1.type === '3'">
        <td colspan="3">BALANCE</td>
        <td>{{ formato(yarnb1.yarnQty) }} kgs</td>
        <td>{{ formato(yarnb1.yarnQtyYd) }} yds</td>
        <td colspan="1"></td>
        <td>$ {{ formato(yarnb1.yarnAmt) }}</td>
    </template>
    </tr>
</table>
</div>
</div>
</div>
<div class="separate"></div>
<h4>KNITTING</h4>
<table class="dataPoInfo">
    <thead>
        <td>INVOICE DATE #</td>
        <td>INVOICE #</td>
        <td>FABRIC DESCRIPTION</td>
        <td>TEXTURE</td>
        <td>FABRIC (KGS)</td>
        <td>KNITTING PRICE</td>
        <td>KNITTING AMT</td>
        <td>KTNITTING MILL</td>
    </thead>
    <tr v-for="detail in details" :key="detail.index">
        <td></td>
        <td></td>
        <td>{{ detail.yarns[0].yarn.name }}</td>
        <td>{{ detail.texture.name }}</td>
        <td>{{formato(calculoMontos(detail,"KNITTING"))}} kgs</td>
        <td>$ {{ formato(detail.knittingPrice) }}</td>
        <td>$ {{formato(detail.knittingAmount)}}</td>
        <td>
        <div v-for="process in fabricPoById[0].fabricPoProcesses" :key="process.index">
            <div v-if="process.status === 'KNITTING'">{{ process.plant.name }}</div>
        </div>    
        </td>

    </tr>
    <tr>
        <td colspan="4">BALANCE</td>
        <td>{{ formato(fabricPoById[0].totalKnitting) }} kgs</td>
        <td></td>
        <td>$ {{ formato(fabricPoById[0].totalKnittingAmount) }}</td>
        <td></td>
    </tr>

</table>

<div class="separate"></div>
<h4>DYEING</h4>
<table class="dataPoInfo">
    <thead>
        <td>INVOICE DATE #</td>
        <td>INVOICE #</td>
        <td>COLOR</td>
        <td>GRADE</td>
        <td>FABRIC (KGS)</td>
        <td>DYEING PRICE</td>
        <td>KNITTING AMT</td>
        <td>DYEING MILL</td>
    </thead>
    <tr v-for="detail in details" :key="detail.index">
        <td></td>
        <td></td>
        <td>VARIOS</td>
        <td>{{ detail.colors[0].grade }}</td>
        <td>{{formato(calculoMontos(detail,"DYEING"))}} kgs</td>
        <td>{{formato(detail.colors[0].dyeingPrice)}}</td>
        <td>$ {{ formato(detail.dyeingAmount) }}</td>
        <td>
        <div v-for="process in fabricPoById[0].fabricPoProcesses" :key="process.index">
            <div v-if="process.status === 'DYEING'">{{ process.plant.name }}</div>
        </div>    
        </td>
    </tr>
    <tr>
        <td colspan="4">BALANCE</td>
        <td>{{ formato(fabricPoById[0].totalDyeing) }}</td>
        <td></td>
        <td>$ {{ formato(fabricPoById[0].totalDyeingAmount) }}</td>
        <td></td>
    </tr>

</table>

<div class="separate"></div>
<h4>FREIGHT CHARGE</h4>
<table class="dataPoInfo">
    <thead>
        <td>INVOICE DATE #</td>
        <td>INVOICE #</td>
        <td>DESCRIPTION</td>
        <td>CTN</td>
        <td>FABRIC (KGS)</td>
        <td>FREIGHT CHARGE %</td>
        <td>FREIGHT CHARGE </td>
        <td>FREIGHT COMPANY</td>
    </thead>
    <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
    <tr>
        <td colspan="4">BALANCE</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
    
</table>

<div class="separate"></div>
<table class="dataPoInfo">
    <thead>
        <td>DESCRIPTION</td>
        <td>PRECOST</td>
        <td>POSTCOST</td>
        <td>BALANCE</td>
    </thead>
    <tr>
        <td>YARN</td>
        <td>$ {{balance[0].yarn}} </td>
        <td></td>
        <td></td>
    </tr>
    <tr>
        <td>KNITTING</td>
        <td>$ {{ formato(balance[0].knitting) }}</td>
        <td></td>
        <td></td>
    </tr>
    <tr>
        <td>DYEING</td>
        <td>$ {{ formato(balance[0].dyeing) }}</td>
        <td></td>
        <td></td>
    </tr>
    <tr>
        <td>FREIGHT</td>
        <td>$ {{ formato(balance[0].freight) }}</td>
        <td></td>
        <td></td>
    </tr>
    <tr style="background-color: bisque;">
      <td>BALANCE</td>
        <td>$ {{ formato(balance[1].balancePrecost) }}</td>
        <td></td>
        <td></td>
    </tr>
</table>
</v-col>
</v-container>

</template>

<script>
import { parse } from 'path';
import { createNamespacedHelpers } from 'vuex'
const fabricModule = createNamespacedHelpers('fabricPo')
const authModule = createNamespacedHelpers('auth')
import printReport from '../../services/helpers/printReport'
import formato from '../../services/helpers/formato'
import calculos from '../../services/helpers/calculos'
export default {
        props:{
    id:{
      type: String,
      required: true,
    }
    },

    data:()=>({
        }),
    methods:{
        ...fabricModule.mapActions(['getFabricPoById']),
        ...authModule.mapActions(['setDrawer']),
        formato(digito){
           return formato.formato(digito)
        },
        calculos(arr){
            return calculos.calculoLoss(arr)
        },
        calculoMontos(arr,proceso){
            return calculos.sumarMontos(arr,proceso)
        }        
    },

    beforeMount(){
        fabricModule.mapGetters(['fabricPoById'])
    },

    mounted(){
        this.getFabricPoById(this.$route.params.id)
    },

    computed:{
        ...fabricModule.mapGetters(['fabricPoById']),
        details(){
            const details = (this.fabricPoById[0].fabricPoDetails)
            calculos.calculoLoss(details)
            details.forEach((type,index) => {
            let sumaKG = 0
            let sumaYARN = 0
            let uPrice = 0
            let sumaKGWLOSS = 0
            let sumaYDAMT = 0            
                let colors =  (details[index].colors)
                const yarns = details[index].yarns 
                let dyeingPrice = 0
                if (colors){                    
                colors.forEach((el) => {
                   uPrice = (parseFloat(el.fabricPrice) + uPrice)/colors.length
                   sumaKG = parseFloat(el.quantity) + sumaKG
                   sumaYARN = parseFloat(el.yd) + sumaYARN
                   details[index].sumaKG = sumaKG
                   details[index].sumaYARN = sumaYARN
                   details[index].uPrice = uPrice
                   details[index].sumaAMT = sumaKG * uPrice
                   dyeingPrice = dyeingPrice + el.dyeingPrice
                });
                }
                yarns.forEach(el => {
                    details[index].dyeingPrice = dyeingPrice/colors.length
                    details[index].sumaKGWLOSS = sumaKGWLOSS+ el.hold
                    details[index].sumaYDAMT = sumaYDAMT + (el.price * el.hold)
                })
            })
            let totalKGS = 0
            let totalYDS = 0
            let totalAMT = 0
            let totalKGWLOSS = 0
            let totalYDAMT = 0         
            let totalKnitting = 0 
            let totalKnittingAmount = 0
            let totalDyeing = 0
            let totalDyeingAmount = 0
            details.forEach(el =>{
                totalKGS = totalKGS + el.sumaKG
                totalYDS = totalYDS + el.sumaYARN
                totalAMT = totalAMT + el.sumaAMT
                totalKGWLOSS = el.sumaKGWLOSS + totalKGWLOSS
                totalYDAMT = el.sumaYDAMT + totalYDAMT
                totalKnitting = this.calculoMontos(el,"KNITTING") + totalKnitting
                totalDyeing = this.calculoMontos(el,"DYEING") + totalDyeing
                totalKnittingAmount = (this.calculoMontos(el,"KNITTING") * el.knittingPrice)  + totalKnittingAmount
                totalDyeingAmount = (this.calculoMontos(el,"DYEING") * el.colors[0].dyeingPrice)  + totalDyeingAmount
            })
            this.fabricPoById[0].totalKGS=totalKGS
            this.fabricPoById[0].totalYDS=totalYDS
            this.fabricPoById[0].totalAMT=totalAMT
            this.fabricPoById[0].totalKGWLOSS = totalKGWLOSS
            this.fabricPoById[0].totalYDWLOSS = totalKGWLOSS * 2.2046
            this.fabricPoById[0].totalYDAMT = totalYDAMT
            this.fabricPoById[0].totalKnitting = totalKnitting
            this.fabricPoById[0].totalDyeing = totalDyeing
            this.fabricPoById[0].totalKnittingAmount = totalKnittingAmount
            this.fabricPoById[0].totalDyeingAmount = totalDyeingAmount
            return details
        },
        yarnspertype(){            
            const yarnMatriz = []
            this.details.forEach(detail => {
                const finded = yarnMatriz.findIndex(matriz => matriz.type === detail.type.substring(1,2))
                if (finded >= 0) {
                    yarnMatriz[finded].details.push(detail)
                    yarnMatriz[finded].yarnQty += detail.sumaKGWLOSS 
                    yarnMatriz[finded].yarnQtyYd += detail.sumaYARN
                    yarnMatriz[finded].yarnAmt += detail.sumaYDAMT
                } else {
                    yarnMatriz.push({ type: detail.type.substring(1,2), details: [detail], yarnQty: detail.sumaKGWLOSS, yarnQtyYd: detail.sumaYARN, yarnAmt: detail.sumaYDAMT  })
                    
                }
            })

            return yarnMatriz
        },
        balance(){
            const yarnBalance = [{
                yarn:0,
                knitting:0,
                dyeing:0,
                freight:0

            },
            {
                balancePrecost:0
            }
        ]
            this.yarnspertype.forEach(el => {
                yarnBalance[0].yarn += el.yarnAmt
                yarnBalance[0].knitting = this.fabricPoById[0].totalKnittingAmount
                yarnBalance[0].dyeing = this.fabricPoById[0].totalDyeingAmount                
            })                        
            yarnBalance[1].balancePrecost = yarnBalance[0].yarn + this.fabricPoById[0].totalKnittingAmount + this.fabricPoById[0].totalDyeingAmount 
            return yarnBalance
        }

    }}

</script>


<style lang="css" scoped>
table{
    border: 1px;
    width: 100%;
    border-collapse: collapse;

    font-size: 12px;
}

td {
    border: 1px solid;
    height: 30px;
}

.dataPo{
    font-weight: bold;
    text-align: center;
}

.dataPoInfo{
    font-weight: bold;
    text-align: center;
    font-size: 10px;
}

.dataPoInfo td{
    height: 10px;
}

.dataPoInfo thead{
    font-weight: bolder;
}



.titlePo{
    width: 20%;
}

.yarnListTitle{
    width: 20%;
    font-weight: bold;
    text-align: center;
}

.yarnListBody{
    height: 60px;
}

h5,h2,h4,h1,h3{
    font-weight: bold;

}

.separate{
    padding-bottom: 35px;
}

.estilo{
  background-color: white;
}

.types + .types + .types + .types + .types {
    page-break-before: always;
}

.types + .types + .types + .types + .types + .types{
    page-break-before: avoid;
}

</style>