<template>
  <Dashboard />
</template>

<script>
import Dashboard from '../components/Dashboard.vue';

export default {
  name: 'Home',
  components: {
    Dashboard,
  },
};
</script>
