<template>
  <v-container>
    <v-row v-if="fabricPoStatus.status">
      <v-col>
        <v-alert
          dense
          outlined
          :type="fabricPoStatus.status"
        >
          <span class="text-subtitle-1">{{ $t(fabricPoStatus.message, { code: general.poNumber }) }}</span>
        </v-alert>
      </v-col>
    </v-row>
    <v-row class="my-3">
      <general-information></general-information>
    </v-row>
    <v-row class="my-3">
      <fabric-po-detail/>
    </v-row>
    <v-row v-if="isEditable">
      <v-col class="d-flex justify-end">
        <v-btn
          v-if="!isAdditionalFabricPo"
          color="primary"
          class="mr-3"
          dark
          @click="addAdditionalFabricPO()"
        >
          {{ $t('fabricPurchaseOrder.addAdditionalFabricPo') }}
        </v-btn>
        <v-btn
          color="primary"
          dark
          @click="isUpdate ? updateFabricPo() : addNewFabricPO()"
        >
          {{ $t(buttonLabel) }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const fabricPoModule = createNamespacedHelpers('fabricPo')

import GeneralInformation from '../../components/fabric-po/GeneralInformation.vue'
import FabricPoDetail from '../../components/fabric-po/Detail.vue'

export default {
  name: "FabricPo",
  components: { GeneralInformation, FabricPoDetail },
  data: () => ({
    edit: null
  }),
  async created() {
    const id = this.routeId
    await this.getVendors();
    if (id) {
      this.edit = true;
      this.getFabricPoByIdForEdit({id, edit: true})
    }

    if (this.$route.name === 'NewFabricPo') {
      await this.resetFabricPO()
    }
  },
  computed: {
    ...fabricPoModule.mapGetters(['fabricPoStatus', 'general','files']),
    fabricPoResponse() {
      return this.fabricPoStatus.status
    },
    routeId() {
      return this.$route.params && this.$route.params.id || null
    },
    isEditable() {
      return this.$route.name !== 'ViewFabricPo'
    },
    isAdditionalFabricPo() {
      return this.$route.name === 'NewAditionalFabricPo'
    },
    isUpdate() {
      return this.$route.name === 'EditFabricPo'
    },
    buttonLabel() {
      return this.isAdditionalFabricPo ? 'fabricPurchaseOrder.saveAdditionalFabricPo' :  'fabricPurchaseOrder.addNewFabricPo'
    }
  },
  methods: {
    ...fabricPoModule.mapActions(['createFabricPo', 'setFabricPoStatus', 'getFabricPoByIdForEdit', 'updateFabricPo', 'resetFabricPO', 'getVendors']),
    addNewFabricPO() {
      if (this.files.length > 0){
       const id = this.routeId
       this.createFabricPo(this.isAdditionalFabricPo ? {id, additional: true} : {})
      }else
      window.alert("You have to attach at least one PO file to save PO")
    },
    addAdditionalFabricPO() {
      this.$router.push({name: 'NewAditionalFabricPo', params: {id: this.routeId}})
    }
  },
  watch: {
    fabricPoResponse() {
      if (this.fabricPoStatus.status) {
        if (this.fabricPoStatus.status === 'success') {
          this.resetFabricPO()
        }

        setTimeout(() => {
          this.setFabricPoStatus({ status: null, message: null })
        }, 100000)
      }
    },
    routeId() {
      if (!this.routeId) {
        this.resetFabricPO()
      }
    }
  }
}
</script>
