<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-center align-center">
        <span class="text-h3 pr-3">{{ $t('yarnInbound.list.title') }}</span>
        <v-btn icon color="secondary" @click="addNewYarnInbound()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels>
          <v-expansion-panel v-for="yarnPo in yarnInbounds" :key="yarnPo.id">
            <v-expansion-panel-header>
              <v-container fluid>
                <v-row>
                  <v-col cols="3">
                    <span class="text-h6 primary--text">
                      {{ yarnPo.code }}
                      <h5 class="grey--text caption">{{ yarnPo.createdBy }}</h5>
                    </span>
                  </v-col>
                  <v-col class="px-1">
                    <span class="black--text font-weight-bold">{{$t('yarnInbound.list.header.orderQty')}} </span>
                    <span class="text-h6 primary--text font-weight-bold">{{ yarnPo.totalYarnPoQuantity }}</span>
                  </v-col>
                  <v-col class="px-1">
                    <span class="black--text font-weight-bold">{{$t('yarnInbound.list.header.total')}} </span>
                    <span class="text-h6 primary--text font-weight-bold">{{ yarnPo.totalIncomingQuantity }}</span>
                  </v-col>
                   <v-col class="px-1">
                    <span class="black--text font-weight-bold">{{$t('yarnInbound.list.header.balance')}} </span>
                    <span class="text-h6 primary--text font-weight-bold">{{ yarnPo.balance }}</span>
                  </v-col>
                  <v-col cols="3">
                    <span class="black--text font-weight-bold">{{$t('yarnInbound.list.header.createdAt')}} </span>
                    <span class="text-subtitle-1 primary--text">{{ yarnPo.formattedDate }}</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-data-table
                :headers="headers"
                :items="yarnPo.inbounds"
                :items-per-page="filters.size"
                hide-default-footer
                class="elevation-1"
                @click:row="print"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>

              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-pagination
          class="py-4"
          v-model="filters.page"
          @input="changePage"
          :length="yarnInboundPage.totalPages"
          :total-visible="filters.size"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const yarnInboundModule = createNamespacedHelpers('yarnInbound')

export default {
  name: 'List',
  created() {
    this.getYarnInbounds(this.filters);
  },
  data: () => ({
    filters: {
      page: 1,
      limit: 10
    },
  }),
  methods: {
    ...yarnInboundModule.mapActions(['getYarnInbounds']),
    changePage(event) {
      this.filters.page = event
      this.getYarnInbounds(this.filters)
    },
    addNewYarnInbound() {
      this.$router.push('/yarn-inbound/new')
    },
    print(row){
      this.$router.push({name:'printableInbounds', params:{id:row.yarnPurchaseOrder.id}})

    },
    editItem(item) {
      this.$router.push({ name: 'EditYarnInbound', params: { id: item.id }})
    }
  },
  computed: {
    ...yarnInboundModule.mapGetters(['yarnInbounds', 'yarnInboundPage']),
    headers() {
      return [
        {
          text: this.$t('yarnInbound.list.header.date'),
          value: 'formattedDate',
          align: 'center',
          class: 'text-subtitle-1 font-weight-bold',
          cellClass: 'text-subtitle-2 secondary--text'
        },
        {
          text: this.$t('yarnInbound.list.header.yarn'),
          value: 'yarn.name',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: this.$t('yarnInbound.list.header.brand'),
          value: 'brand',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: this.$t('yarnInbound.list.header.batch'),
          value: 'batch',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: this.$t('yarnInbound.list.header.quantity'),
          value: 'incomeQuantity',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: "Options",
          value: "action",
          align: 'center',
          class: 'text-subtitle-1',
        }
      ]
    }
  }
}
</script>
