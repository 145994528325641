export default {
  username: state => state.username,
  name: state => state.name,
  isAuthenticated: state => state.isAuthenticated,
  accessToken: state => state.accessToken,
  roles: state => state.roles,
  users: state => state.users,
  functionalities: state => state.functionalities,
  drawer: state => state.drawer
}
