<template>
  <v-col>
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="select"
          :loading="loading"
          :items="yarnItems"
          cache-items
          :label="$t('yarnPurchaseOrder.detail.yarnSelection.yarn')"
          :search-input.sync="search"
          outlined
          return-object

          @change="change($event.id, 'yarn')"
          dense
          :menu-props="{closeOnContentClick:true}"
          item-text="name"
          item-value="id"
          hide-details
        >
          <template slot="no-data" v-if="search">
            <v-container>
              <v-row>
                <v-col>
                  <span class="text-caption secondary--text">No se encontraron registros</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template slot="append-item" v-if="addCustomValues">
            <v-container>
              <v-row class="" @click="addCustomYarn">
                <v-col role="button">
                  <span class="text-subtitle-2 primary--text">Agregar hilo: <span class="text-subtitle-1">( {{ search }} )</span></span>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-select
          dense
          outlined
          :value="item.origin"
          background-color="white"
          @input="change($event.key, 'origin')"
          :label="$t('yarnPurchaseOrder.detail.yarnSelection.origin')"
          :items="origin"
          item-value="key"
          item-text="text"
          return-object
          hide-details="auto">
        </v-select>
      </v-col>
      <v-col>
        <v-text-field
          dense
          type="number"
          :value="item.quantity"
          outlined
          background-color="white"
          @input="change(parseFloat($event), 'quantity');calculateSubtotal()"
          :label="$t('yarnPurchaseOrder.detail.yarnSelection.quantity')"
          hide-details="auto">
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          dense
          outlined
          :value="item.unitPrice"
          background-color="white"
          @input="change(parseFloat($event), 'unitPrice');calculateSubtotal()"
          :label="$t('yarnPurchaseOrder.detail.yarnSelection.unitPrice')"
          hide-details="auto">
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          dense
          outlined
          :value="subtotal"
          readonly
          background-color="white"
          :label="$t('yarnPurchaseOrder.detail.yarnSelection.amount')"
          hide-details="auto">
        </v-text-field>
      </v-col>
      <v-col style="max-width: 40px;" class=" d-flex justify-center">
        <v-btn icon color="error" @click="deleteElement">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import backendService from '../../services/backend.service'

const yarnPoModule = createNamespacedHelpers('yarnPo')
const authModule = createNamespacedHelpers('auth')

export default {
  name: 'YarnOrderDetail',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    subtotal: 0,
    yarnItems: [],
    select: null,
    search: null,
    loading: null
  }),
  mounted() {
    this.subtotal = this.item && this.item.amount || 0
    this.yarnItems = this.yarns
    const yarn = this.yarns.find(yarnList => yarnList.id === this.item.yarn)
    this.select = yarn
  },
  computed: {
    ...yarnPoModule.mapGetters(['yarns', 'origin']),
    ...authModule.mapGetters(['roles']),
  },
  methods: {
    change(value, field) {
      const row = this.item;
      row[field] = value;
      this.$emit('input', row);
    },
    deleteElement() {
      this.$emit('delete')
    },
    calculateSubtotal() {
      const unitPrice = this.item.unitPrice ? parseFloat(this.item.unitPrice) : 0;
      const quantity = this.item.quantity ? parseFloat(this.item.quantity) : 0;
      this.subtotal = ( unitPrice * quantity).toLocaleString()
    },
    async searchYarn(value) {
      this.loading = true
      const response = await backendService.getYarn(value)
      this.loading = false
      this.yarnItems = response.data
    },
    async addCustomYarn() {
      const response = await backendService.createYarn({ name: this.search })
      this.select = response.data
      this.yarnItems = [response.data]
      this.change(this.select.id, 'yarn')
    },
    addCustomValues() {
      return this.search && this.roles.find(role => role.slug === 'add-custom-values')
    },
  },
  watch: {
    search(value) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        value && value !== this.select && this.searchYarn(value)
      }, 500)
    },
    yarns() {
      this.yarnItems = this.yarns
      const yarn = this.yarns.find(yarnList => yarnList.id === this.item.yarn)
      this.select = yarn
    }
  }
}
</script>
