<template>
  <v-container>
    <v-row>
      <v-col>
        <v-select
          :items="yarns"
          @input="change($event, 'yarn')"
          :value="item.yarn"
          :label="$t('yarnOutbound.detail.yarn.yarn')"
          item-value="yarnId"
          item-text="yarnName"
          dense outlined background-color="white" hide-details="auto"
        ></v-select>
      </v-col>

      <v-col>
        <v-text-field
          dense
          outlined
          :label="$t('yarnOutbound.detail.yarn.brand')"
          :value="item.brand"
          @input="change($event, 'brand')"
          background-color="white" hide-details="auto">
        </v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          dense
          outlined
          :label="$t('yarnOutbound.detail.yarn.batch')"
          :value="item.batch"
          @input="change($event, 'batch')"
          background-color="white" hide-details="auto">
        </v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          dense
          outlined
          :label="$t('yarnOutbound.detail.yarn.yarnPercentage')"
          :value="item.yarnPercentage"
          @input="change(parseFloat($event), 'yarnPercentage')"
          background-color="white" hide-details="auto">
        </v-text-field>
      </v-col>

      <v-col>
        <v-select
          dense
          outlined
          :label="$t('yarnOutbound.detail.yarn.origin')"
          :value="item.origin"
          background-color="white"
          @input="change($event.key, 'origin')"
          :items="origin"
          item-value="key"
          item-text="text"
          return-object
          hide-details="auto">
        </v-select>
      </v-col>

      <v-col>
        <v-text-field
          dense
          :label="$t('yarnOutbound.detail.yarn.unitPrice')"
          outlined
          type="number"
          :value="item.unitPrice"
          @input="change(parseFloat($event), 'unitPrice')"
          background-color="white" hide-details="auto"
          persistent-hint
          >
        </v-text-field>
      </v-col>

      <v-col style="max-width: 40px;" class=" d-flex justify-center">
        <v-btn icon color="error" @click="deleteElement">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'YarnOutboundSelection',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    yarns: {
      type: Array,
      default: () => ([])
    },
    origin: {
      type: Array,
      default: () => ([])
    }
  },
  methods: {
    change(value, field) {
      const row = this.item;
      row[field] = value;
      this.$emit('input', row);
    },
    deleteElement() {
      this.$emit('delete')
    }
  }
}
</script>
