<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-center align-center">
        <span class="text-h3 pr-3">{{ $t('yarnOutbound.list.title') }}</span>
        <v-btn icon color="secondary" @click="addNewYarnOutbound()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels>
          <v-expansion-panel v-for="fabric in netTotalKg" :key="fabric.id">
            <v-expansion-panel-header>
              <v-container>
                <v-row>
                  <v-col cols="2">
                    <span class="grey--text">{{$t('yarnOutbound.list.header.fabricPo')}}</span>
                    <span class="text-h6 primary--text">
                      {{ fabric.number }}
                    </span>
                  </v-col>
                  <v-col >
                    <span class="grey--text">{{$t('yarnOutbound.list.header.knittingLossQty')}} </span>
                    <span class="text-subtitle-1 primary--text">{{ fabric.fabricPoDetails[0].totalKnittingLoss }} Kgs</span>
                  </v-col>
                  <v-col >
                    <span class="grey--text">{{$t('yarnOutbound.list.header.totalNetOutbound')}} </span>
                    <span class="text-subtitle-1 primary--text">{{ fabric.totalNetOutbound }} Kgs</span>
                  </v-col>
                  <v-col >
                    <span class="grey--text">{{$t('yarnOutbound.list.header.totalOutbound')}} </span>
                    <span class="text-subtitle-1 primary--text">{{ fabric.outboundwithLoss }} Kgs</span>
                  </v-col>
                  <v-col >
                    <span class="grey--text">{{$t('yarnOutbound.list.header.balanceOutbound')}} </span>
                    <span class="text-subtitle-1 primary--text">{{(fabric.fabricPoDetails[0].totalKnittingLoss - fabric.outboundwithLoss).toFixed(2) }} Kgs</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-data-table
                :headers="headers"
                :items="fabric.yarnOutbounds"
                :items-per-page="filters.size"
                hide-default-footer
                class="elevation-1"
                @click:row="print"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>


        <v-pagination
          class="py-4"
          v-model="filters.page"
          @input="changePage"
          :length="yarnOutboundPage.totalPages"
          :total-visible="filters.size"
        ></v-pagination>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const yarnOutboundModule = createNamespacedHelpers('yarnOutbound')

export default {
  name: 'YarnOutboundList',
  created() {
    this.getYarnOutbounds(this.filters)
  },
  data: () => ({
    filters: {
      page: 1,
      limit: 10
    }
  }),
  methods: {
    ...yarnOutboundModule.mapActions(['getYarnOutbounds']),
    changePage(event) {
      this.filters.page = event
      this.getYarnOutbounds(this.filters)
    },
    addNewYarnOutbound() {
      this.$router.push('/yarn-outbound/new')
    },
    print(row) {

    },
    editItem(item) {
      this.$router.push({ name: 'EditYarnOutbound', params: { id: item.id }})
    }
  },
  computed: {
    ...yarnOutboundModule.mapGetters(['yarnOutbounds', 'yarnOutboundPage']),
    netTotalKg(){
      let yarnOutbounds = this.yarnOutbounds
      yarnOutbounds.forEach(outbound => {
        let total = 0
        let totalYarn = 0
        let totalOutbound = 0
        let totalNetOutbound = 0
          const details = outbound.fabricPoDetails
          const outbounds = outbound.yarnOutbounds
            details.forEach(e => {
              e.colors.forEach(el=>{
                total += parseFloat(el.quantity)
              })
              e.totalNetKg=total
              e.yarns.forEach(el =>{
                totalYarn += parseFloat(el.hold)
              })
              e.totalKnittingLoss = parseFloat((totalYarn)/(1+parseFloat(e.knittingPercentage/100))).toFixed(2)
            });

            outbounds.forEach(e=>{
              totalOutbound += parseFloat(e.quantityWithLoss)
              totalNetOutbound += parseFloat(e.quantity)
            })
            outbound.outboundwithLoss = totalOutbound
            outbound.totalNetOutbound = totalNetOutbound
            totalNetOutbound = 0
            totalOutbound = 0

        })
      return this.yarnOutbounds
    },
    headers() {
      return [
        {
          text: this.$t('yarnOutbound.list.header.date'),
          value: 'formattedDate',
          align: 'center',
          class: 'text-subtitle-1 font-weight-bold',
          cellClass: 'text-subtitle-2 secondary--text'
        },
        {
          text: this.$t('yarnOutbound.list.header.lossPercentage'),
          value: 'lossPercentage',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: this.$t('yarnOutbound.list.header.quantity'),
          value: 'quantity',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: this.$t('yarnOutbound.list.header.knittingPlant'),
          value: 'knittingPlant.name',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: this.$t('yarnOutbound.list.header.dyeingPlant'),
          value: 'dyeingPlant.name',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: "Options",
          value: "action",
          align: 'center',
          class: 'text-subtitle-1',
        }
      ]
    }
  },
}

</script>
