import { SET_PROVIDERS, SET_VENDORS,
  MODIFY_YARN_PURCHASE_ORDER_DETAIL,
  SET_YARNS,
  MODIFY_YARN_PURCHASE_ORDER_FOOTER,
  MODIFY_YARN_PURCHASE_ORDER_GENERAL,
  SET_YARN_PO_STATUS,
  SET_YARN_PO_ID,
  RESET_YARN_PO,
  SET_YARNS_POS,
  SET_YARNS_OF_PO} from '../mutation-types'

export default {
  [SET_PROVIDERS] (state, payload) {
    state.providers = payload
  },
  [SET_VENDORS] (state, payload) {
    state.vendors = payload
  },
  [MODIFY_YARN_PURCHASE_ORDER_DETAIL] (state, payload) {
    state.yarnPurchaseDetail = payload
  },
  [SET_YARNS] (state, payload) {
    state.yarns = payload
  },
  [MODIFY_YARN_PURCHASE_ORDER_FOOTER] (state, payload) {
    state.footer = payload
  },
  [MODIFY_YARN_PURCHASE_ORDER_GENERAL] (state, payload) {
    state.general = payload
  },
  [SET_YARN_PO_STATUS] (state, payload) {
    state.yarnPoStatus = payload
  },
  [SET_YARN_PO_ID] (state, payload) {
    state.yarnPoById = payload
  },
  [RESET_YARN_PO] (state) {
    state.yarnPurchaseDetail = [{}]
    state.footer = {}
    state.general = {}
  },
  [SET_YARNS_POS] (state, payload) {
    state.yarnPOs = payload.data
    state.yarnPoPage = payload.meta
  },
  [SET_YARNS_OF_PO] (state, payload) {
    state.currentYarnPoYarns = payload
  }
}
