<template>
  <v-container style="height: 100%; " >
    <v-form ref="loginForm" v-model="valid" lazy-validation style="height: 100%; width: 100%" class="d-flex">
      <v-row>
        <v-col cols="12" sm="4" class="ma-auto" >
          <v-card class="rounded-lg">
            <v-card-text class="pa-8">
              <v-row>
                <v-col class="pb-5">
                  <span class="text-h5" style="color: #4285F4; font-size: 24px; font-weight: $bold; ">
                  {{ $t('login.title') }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined background-color="white"
                    hide-details="auto"
                    :label="$t('login.usernameLabel')" dense
                    :rules="[rules.required, rules.email]"
                    v-model="email"
                  >

                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined type="password" background-color="white"
                    hide-details="auto"
                    :label="$t('login.passwordLabel')" dense
                    :rules="[rules.required]"
                    v-model="password"
                  >

                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-6">
                  <v-btn rounded width="100%" color="info" class="text-button" @click="login">
                    {{ $t('login.loginButton') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { inputRules } from '@/utils'
import { createNamespacedHelpers } from 'vuex'

const authModule = createNamespacedHelpers('auth')
export default {
  name: 'Login',
  data: () => ({
    valid: true,
    rules: inputRules,
    email: 'test1@email.com',
    password: '12345'
  }),
  methods: {
    ...authModule.mapActions(['requestLogin']),
    async login() {
      const email = this.email;
      const password = this.password;
      this.requestLogin({ email, password })
    }
  },
  computed: {
    ...authModule.mapGetters(['isAuthenticated'])
  },
  watch: {
    isAuthenticated() {
      if (this.isAuthenticated) {
        this.$router.push('/')
      }
    }
  }
}
</script>
