<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" v-for="file in files" :key="file.fileId"
        class="file-list rounded border my-1 text-center">
        <v-row>
          <v-col style="max-width: 70px;">
            <v-icon color="primary" size="34px">
              mdi-file
            </v-icon>
          </v-col>
          <v-col class="d-flex flex-column text-left col justify-center">
            <span class="text-body-1 font-weight-bold grey--text">
              {{file.fileName}}
            </span>
          </v-col>
           <v-col style="max-width: 60px;">
            <v-btn
              icon
              @click="download(file)"
              color="secondary"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-col>
          <v-col style="max-width: 60px;">
            <v-btn
              icon
              @click="removeFile(file)"
              color="error"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'FileList',
  props: {
    files: {
      type: Array,
      default: () => ([])
    }
  },
  methods: {
    removeFile(file) {
      this.$emit('removeFile', file)
    },
    download(file) {
      this.$emit('downloadFile', file)
    }
  }
}
</script>

<style lang="scss">
.file-list {
  border-style: solid;
  border-color: #80808057;
  border-width: 2px;
}
</style>
