<template>
<v-container>
<v-col id="vCol" class="white rounded pa-8" style="box-shadow: 0px 0px 15px 0px #00000036;">
<div id="printableArea" class="estilo" style="width: 1080px;">
        <div>
        <div>
        <h2 style="margin-bottom:25px">INBOUNDS YARN PURCHASE ORDER # {{yarnPoById.code}}</h2>
 
 </div>
       
        </div>
 
 </div>
 <div class="separate"></div>
<h2>Inbounds List</h2>
<div class="separate"></div>
<table>
    <thead >
        <td lass="yarnListTitle">#</td>
        <td class="yarnListTitle">DATE</td>
        <td class="yarnListTitle">YARN</td>
        <td class="yarnListTitle">BRAND</td>
        <td class="yarnListTitle">BATCH</td>
        <td class="yarnListTitle">QUANTITY</td>
    </thead>
    <tbody class="yarnListBody text-center">
    <tr v-for="(inbound,i) in inbounds" :key="inbound.id">
        <td>{{i+1}}</td>
        <td>{{inbound.admissionDate}}</td>
        <td>{{inbound.yarn.name}}</td>
        <td>{{inbound.brand}}</td>
        <td>{{inbound.batch}}</td>
        <td>{{inbound.incomeQuantity}} Kgs</td>
    </tr>
    </tbody>
</table>
<div class="separate"></div>
<h2>Yarn Purchase Order Balance</h2>
<div class="separate"></div>
<table>
    <thead >
         <td class="yarnListTitle">#</td>
        <td class="yarnListTitle">YARN</td>
        <td class="yarnListTitle">FLOATING</td>
        <td class="yarnListTitle">REAL</td>
        <td class="yarnListTitle">TOTAL</td>
    </thead>
    <tbody class="yarnListBody text-center">
    <tr v-for="(yarn,index) in yarns" :key="yarn.id"
        >
        <td>{{index+1}}</td>
        <td>{{yarn.yarn.name}}</td>
        <td>{{yarn.yarn.floating}} Kgs</td>
        <td>{{yarn.yarn.quantity}} Kgs</td>
        <td>{{yarn.yarn.floating + yarn.yarn.quantity}} Kgs</td>
    </tr>
    </tbody>
</table>

<div class="separate"></div>
<h5>This PO Yarn is {{yarnPoById.status}}</h5>
<div class="separate"></div>
<div class="text-center">
<v-btn
          id="buttonPrint"
          color="primary"
          dark
          @click="PrintReport"
        >
         {{ $t('printButton') }}
        </v-btn>
</div>
</v-col>
</v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const yarnPOModule = createNamespacedHelpers('yarnPo')
const yarnInboundModule = createNamespacedHelpers('yarnInbound')


export default {
 props:{
    id:{
      type: String,
      required: true,
    }
    },
 methods:{
    ...yarnPOModule.mapActions(['getYarnsPoById']),
    ...yarnInboundModule.mapActions(['getYarnInboundsById', 'getYarnInbounds']),

     PrintReport(){
      document.getElementById("appBar").style.display="none";
      document.getElementById("buttonPrint").style.display="none";
      document.getElementById("footBar").style.display="none";
      document.getElementById("vCol").className="";
      document.getElementById("vCol").style=""
           window.print();
      document.getElementById("appBar").style.display="";
      document.getElementById("buttonPrint").style.display="";
      document.getElementById("footBar").style.display="";
      document.getElementById("vCol").className="white rounded pa-8";
      document.getElementById("vCol").style="box-shadow: 0px 0px 15px 0px #00000036;"
    },
    
 },
 computed:{
    ...yarnInboundModule.mapGetters(['yarnInbounds', 'yarnInboundById']),
    ...yarnPOModule.mapGetters(['yarnPoById']),
    inbounds(){        
        return  this.yarnInboundById
    },
    yarns(){
        let yarnToShow = []
        this.yarnInboundById.forEach(element => {
            if(yarnToShow.findIndex(item => item.yarnId === element.yarnId) < 0 ){
                yarnToShow.push(element)
            }
        });
        return yarnToShow
    }
 },
 mounted(){
   this.getYarnsPoById(this.id)
   this.getYarnInboundsById(this.id)
 },

}

</script>

<style lang="css" scoped>
table{
    border: 1px;
    width: 1080px;
    border-collapse: collapse;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
}

td{
    border: 1px solid;
}

.dataPo{
    font-weight: bold;
    width: 30%;
    text-align: center;
}

.titlePo{
    width: 20%;
}

.yarnListTitle{
    width: 20%;
    font-weight: bold;
    text-align: center;
}

.yarnListBody{
    height: 60px;
}

h2{
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
}
h5{
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.separate{
    padding-bottom: 35px;
}

.estilo{
  background-color: white;
}

header, footer, nav, aside {
  display: none;
}
</style>