import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#2471A3',
        secondary: '#2A0BA9',
        accent: '#ABEBC6',
        error: '#D35400',
        white: '#FFF'
      }
    }
  }
});
