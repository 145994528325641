<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-center align-center">
        <span class="text-h3 pr-3">{{ $t('yarnInventary.plant.title') }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="plantsDetail"
          hide-default-footer
          class="elevation-1"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import backendService from '../../services/backend.service';
export default {
  name: 'YarnPlantInventary',
  data: () => ({
    yarnPlants: []
  }),
  async mounted() {
    const id = this.$route.params && this.$route.params.yarnId;
    const result = await backendService.getCurrentYarnInventary(id)
    this.yarnPlants = result.data
  },
  computed: {
    plantsDetail() {
      return this.yarnPlants
    },
    headers() {
      return [
        {
          text: this.$t('yarnInventary.list.plant.header.plant'),
          value: 'plantName',
          align: 'center',
          class: 'text-subtitle-1 font-weight-bold',
          cellClass: 'text-subtitle-2 secondary--text'
        },
        {
          text: this.$t('yarnInventary.list.plant.header.quantity'),
          value: 'quantity',
          align: 'center',
          class: 'text-subtitle-1'
        },
        {
          text: this.$t('yarnInventary.list.plant.header.float'),
          value: 'float',
          align: 'center',
          class: 'text-subtitle-1'
        },
        {
          text: this.$t('yarnInventary.list.plant.header.hold'),
          value: 'hold',
          align: 'center',
          class: 'text-subtitle-1'
        },
        {
          text: this.$t('yarnInventary.list.plant.header.outbound'),
          value: 'outbound',
          align: 'center',
          class: 'text-subtitle-1'
        },
        {
          text: this.$t('yarnInventary.list.plant.header.total'),
          value: 'total',
          align: 'center',
          class: 'text-subtitle-1',
          cellClass: 'text-subtitle-2 secondary--text'
        }
      ]
    }
  }
}
</script>
