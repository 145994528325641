<template>
  <v-col>
    <v-row>
      <v-col class="d-flex justify-space-between">
        <span class="text-h6 d-flex">
          <div class="pt-1">
            {{ $t('yarnOutbound.detail.title') }}
          </div>
        </span>
        <v-btn
          small
          color="secondary"
          dark
          @click="addNewDetail"
        >
          {{ $t('yarnOutbound.detail.addButtonDetail') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row v-for="(item, index) in yarnSelection" :key="index" class="py-8" style="border-bottom: solid 3px #e0e0e0;">
          <YarnOutboundSelection
            :item="item"
            :origin="origin"
            :yarns="yarns"
            @input="changeDetail(index, $event)"
            @delete="deleteDetail(index)"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import YarnOutboundSelection from './Yarn.vue'
import { createNamespacedHelpers } from 'vuex'
const yarnOutboundModule = createNamespacedHelpers('yarnOutbound')
const fabricPoModule = createNamespacedHelpers('fabricPo')
const yarnPOModule = createNamespacedHelpers('yarnPo')

export default {
  name: 'YarnOutboundDetail',
  components: {YarnOutboundSelection},
  computed: {
    ...fabricPoModule.mapGetters(['currentFabricPoYarns']),
    ...yarnOutboundModule.mapGetters(['yarnSelection']),
     ...yarnPOModule.mapGetters(['origin']),
    yarns() {
      return this.currentFabricPoYarns || []
    }
  },
  methods: {
    ...yarnOutboundModule.mapActions(['setYarnSelection']),
    changeDetail(index, element) {
      const details = this.yarnSelection
      details[index] = element
      this.setYarnSelection(details)
    },
    deleteDetail(index) {
      const details = this.yarnSelection.filter((item, idx) => idx !== index)
      this.setYarnSelection(details)
    },
    addNewDetail() {
      const details = this.yarnSelection
      details.push({}),
      this.setYarnSelection(details)
    }
  }
}
</script>
