import jsPDF from 'jspdf'
import store from '@/plugins/vuex'



function printReport (documento){

    
       
    document.getElementById("appBar").style.display="none";
    document.getElementById("buttonPrint").style.display="none";
    document.getElementById("footBar").style.display="none";
    document.getElementById("vCol").className="";
    document.getElementById("vCol").style=""
    store.commit('auth/SET_DRAWER',false)
    process.nextTick(() => {
        window.print();    
        document.getElementById("appBar").style.display="";
        document.getElementById("buttonPrint").style.display="";
        document.getElementById("footBar").style.display="";
        document.getElementById("vCol").className="white rounded pa-8";
        document.getElementById("vCol").style="box-shadow: 0px 0px 15px 0px #00000036;"
        store.commit('auth/SET_DRAWER',true)
      });
    
    
    

    // let name = poname +'_'+ process
    //         const doc = new jsPDF('','mm','letter');        
    //    var margin = 10          
        

    //    doc.html(document.getElementById('printableArea'),{
    //             x: margin,
    //             y: margin,
    //             autoPaging: 'text',
    //             margin: [5, 5, 6.7, 0],              
    //             html2canvas: {
    //                 scale: 0.17
    //             },
    //             callback: function(doc){                                       
    //                 doc.save(name)
    //                 window.close()
    //             }
    //         });
}

export default{
    printReport
}