<template>
  <v-col>
    <v-row>
      <v-col cols="2">
        <v-text-field
          dense
          outlined
          :label="$t('fabricPurchaseOrder.detail.colorSelection.color')"
          :value="item.color"
          @input="change($event, 'color')"
          background-color="white" hide-details="auto">
        </v-text-field>
      </v-col>

      <v-col cols="2">
        <v-text-field
          dense
          outlined
          :label="$t('fabricPurchaseOrder.detail.colorSelection.number')"
          :value="item.number"
          @input="change($event, 'number')"
          background-color="white" hide-details="auto">
        </v-text-field>
      </v-col>

      <v-col cols="2">
        <v-text-field
          dense
          outlined
          :label="$t('fabricPurchaseOrder.detail.colorSelection.grade')"
          :value="item.grade"
          @input="change($event, 'grade')"
          background-color="white" hide-details="auto">
        </v-text-field>
      </v-col>

      <v-col style="min-width: 130px;max-width: 150px">
        <v-text-field
          dense
          outlined
          :label="$t('fabricPurchaseOrder.detail.colorSelection.dyeingPrice')"
          :value="item.dyeingPrice"
          @input="change(parseFloat($event), 'dyeingPrice')"
          type="number"
          background-color="white" hide-details="auto">
        </v-text-field>
      </v-col>

      <v-col style="min-width: 150px;max-width: 150px">
        <v-text-field
          dense
          outlined
          :label="$t('fabricPurchaseOrder.detail.colorSelection.printingPrice')"
          :value="item.printingPrice"
          @input="change(parseFloat($event), 'printingPrice')"
          type="number"
          background-color="white" hide-details="auto">
        </v-text-field>
      </v-col>

      <v-col style="min-width: 150px;max-width: 150px">
        <v-text-field
          dense
          outlined
          :label="$t('fabricPurchaseOrder.detail.colorSelection.fabricPrice')"
          :value="item.fabricPrice"
          @input="change(parseFloat($event), 'fabricPrice')"
          type="number"
          background-color="white" hide-details="auto">
        </v-text-field>
      </v-col>

      <v-col style="min-width: 100px;max-width: 150px">
        <v-text-field
          dense
          outlined
          :label="$t('fabricPurchaseOrder.detail.colorSelection.quantity')"
          :value="item.quantity"
          @input="change(parseFloat($event), 'quantity');"
          type="number"
          background-color="white" hide-details="auto">
        </v-text-field>
      </v-col>

      <v-col class="d-flex align-center">
        <span class="text-subtitle-1 pr-3">
          {{unitTypeLabel}}:
        </span>
        <span class="text-body-1 secondary--text font-weight-bold"> {{ item.yd }} </span>
      </v-col>

      <v-col style="max-width: 40px;" class=" d-flex justify-center">
        <v-btn icon color="error" @click="deleteElement">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const fabricPoModule = createNamespacedHelpers('fabricPo')

export default {
  name: 'FabricPoColorSelection',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    unitType: {
      type: String,
      default: () => (null)
    }
  },
  data: () => ({

  }),
  computed: {
    ...fabricPoModule.mapGetters(['fabricPoDetail']),
    unitTypesArray() { return this.fabricPoDetail
    },
    unitTypeLabel() {
      return this.unitType === "YDS" ? this.$t('fabricPurchaseOrder.detail.colorSelection.kg') : this.$t('fabricPurchaseOrder.detail.colorSelection.yd')
    }
  },
  methods: {
    change(value, field) {
      const row = this.item;
      row[field] = value;
      this.unitTypesArray
      this.$emit('input', row);
    },
    deleteElement() {
      this.$emit('delete')
    },
    updateContent() {
      this.$forceUpdate()
    }
  }
}
</script>
