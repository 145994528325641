import moment from 'moment'

export default {
  providers: state => state.providers,
  vendors: state => state.vendors,
  yarnPurchaseDetail: state => state.yarnPurchaseDetail,
  yarns: state => state.yarns,
  origin: state => state.origin,
  footer: state => state.footer,
  general: state => state.general,
  yarnPOStatus: state => state.yarnPoStatus,
  yarnPoById: state => state.yarnPoById,
  yarnPos: state => {
    return state.yarnPOs.map(yarnPO => ({
      ...yarnPO,
      formattedOrderDate: moment(yarnPO.orderDate).format('YYYY-MM-DD'),
      formattedDeliveryDate: moment(yarnPO.deliveryDate).format('YYYY-MM-DD')
    }))
  },
  yarnPosPage: state => state.yarnPoPage,
  currentYarnPoYarns: state => state.currentYarnPoYarns
}
