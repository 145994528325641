<template>
  <v-container>
    <v-row v-if="yarnOutboundMessage.alert">
      <v-col>
        <v-alert
          dense
          v-model="yarnOutboundMessage.alert"
          outlined
          :type="yarnOutboundMessage.type"
        >
          <span class="text-subtitle-1">{{ $t(yarnOutboundMessage.message) }}</span>
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-center">
        <span class="text-h3">{{ $t('yarnOutbound.title') }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col class="d-flex justify-space-between">
                <span class="text-h6 font-weight-bold">
                  {{ $t('yarnOutbound.inputDescriptions') }}
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-label>
                  {{ $t('yarnOutbound.form.date') }}
                </v-label>
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      background-color="white"
                      append-icon="mdi-calendar"
                      readonly
                      v-model="date"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @input="changeDate($event, 'date'); date=$event; menuDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="6">
                <v-label>
                  {{ $t('yarnOutbound.form.fabricPo') }}
                </v-label>

                <v-select
                  :items="fabricPos"
                  @input="change($event, 'fabricPo')"
                  :value="yarnOutbound.fabricPo"
                  item-value="id"
                  dense outlined background-color="white" hide-details="auto"
                >
                  <template v-slot:selection="{ item }">
                    <span>({{ item.number }}) {{ item.vendor.name }} - {{ item.buyer.name }} </span>
                  </template>
                  <template v-slot:item="{item}">
                    <span class="font-weight-bold"> {{ item.number }} </span>
                    <span class="pl-2">{{ item.vendor.name }} - {{ item.buyer.name }}</span>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="6">
                <v-label>
                  {{ $t('yarnOutbound.form.lossPercentage') }}
                </v-label>

                <v-text-field
                  dense
                  outlined
                  :value="yarnOutbound.lossPercentage"
                  @input="change(parseFloat($event), 'lossPercentage')"
                  background-color="white" hide-details="auto"
                  >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-label>
                  {{ $t('yarnOutbound.form.quantity') }}
                </v-label>

                <v-text-field
                  dense
                  outlined
                  :value="yarnOutbound.quantity"
                  @input="change(parseFloat($event), 'quantity')"
                  background-color="white" hide-details="auto"
                  >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-label>
                  {{ $t('yarnOutbound.form.knittingPlant') }}
                </v-label>

                <v-select
                  :items="knittingPlants"
                  item-value="slug"
                  item-text="name"
                  :value="yarnOutbound.knittingPlant"
                  @input="change($event, 'knittingPlant')"
                  dense outlined background-color="white" hide-details="auto">
                </v-select>
              </v-col>

              <v-col cols="6">
                <v-label>
                  {{ $t('yarnOutbound.form.dyeingPlant') }}
                </v-label>

                <v-select
                  :items="dyeingPlants"
                  item-value="slug"
                  item-text="name"
                  :value="yarnOutbound.dyeingPlant"
                  @input="change($event, 'dyeingPlant')"
                  dense outlined background-color="white" hide-details="auto">
                </v-select>
              </v-col>

            </v-row>

            <v-row>
              <YarnOutboundDetail/>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-end">
        <v-switch
          class="my-0 pr-5"
          v-model="closePo"
        >
          <template v-slot:label>
            <span class="font-weight-bold secondaty--text text-h6">{{ $t('yarnOutbound.form.closePo') }}</span>
          </template>
        </v-switch>
        <v-btn
          color="primary"
          dark
          @click="edit ? updateYarnOutbound() :  addNewYarnOutbound()"
        >
          {{ $t('yarnOutbound.form.addNewYarnOutbound') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5 primary--text">
          {{ $t('yarnOutbound.form.dialog.title') }}
        </v-card-title>
        <v-card-text class="text-h6 secondary--text">
          {{ $t('yarnOutbound.form.dialog.text') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            color="primary"
            dark
            @click="dialog = false"
          >
            {{ $t('yarnOutbound.form.dialog.close') }}
          </v-btn>

          <v-btn
            color="primary"
            dark
            @click="closePoAndCreateYarnOutbound"
          >
            {{ $t('yarnOutbound.form.dialog.accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import YarnOutboundDetail from '../../components/yarn-outbound/YarnOutboundDetail.vue'
import { createNamespacedHelpers } from 'vuex'
import moment from 'moment'
const yarnOutboundModule = createNamespacedHelpers('yarnOutbound')
const yarnInboundModule = createNamespacedHelpers('yarnInbound')
const fabricPoModule = createNamespacedHelpers('fabricPo')

export default {
  name: 'YarnOutbound',
  components: {YarnOutboundDetail},
  data: () => ({
    menuDate: false,
    date: null,
    edit: null,
    filters: {
      page: 1,
      limit: 100
    },
    closePo: false,
    dialog: false,
    remaining: null
  }),
  mounted() {
    this.setYarnOutbound({})
    this.setYarnSelection([{}])
    this.resetSearch()
    this.getPlants()
    const id = this.$route.params && this.$route.params.id;
    if (id) {
      this.edit = true
      this.getYarnOutbound({ id, edit: true})
    }
  },
  methods: {
    ...fabricPoModule.mapActions(['getFabricPos', 'getYarnsOfPo']),
    ...yarnInboundModule.mapActions(['getPlants']),
    ...yarnOutboundModule.mapActions(['setYarnOutbound', 'createYarnOutbound', 'getYarnOutbound', 'updateYarnOutbound', 'setYarnSelection']),
    resetSearch() {
      this.filters.page = 1
      this.getFabricPos(this.filters)
    },
    getMoreFabricPos() {
      if (this.fabricPoPage.currentPage != this.fabricPoPage.totalPages) {
        this.filters.page = this.filters.page + 1
        this.getFabricPos(this.filters)
      }
    },
    change(value, field) {
      const newYarnOutbound = this.yarnOutbound
      newYarnOutbound[field] = value
      this.setYarnOutbound(newYarnOutbound)
      if (field === 'fabricPo') {
        this.getYarnsOfPo(value)
      }
    },
    changeDate(date, field) {
      const isoDate = moment(date).toISOString()
      this.change(isoDate, field)
      this.$forceUpdate()
    },
    addNewYarnOutbound() {
      if (this.closePo) {
        this.dialog = true
      } else {
        this.createYarnOutbound()
      }
    },
    closePoAndCreateYarnOutbound() {
      this.change(true, 'closePo')
      if (this.edit) {
        this.updateYarnOutbound()
      } else {
        this.createYarnOutbound()
      }
    }
  },
  computed: {
    ...yarnInboundModule.mapGetters(['plants']),
    ...yarnOutboundModule.mapGetters(['yarnOutbound', 'yarnOutboundMessage']),
    ...fabricPoModule.mapGetters(['fabricPos', 'fabricPoPage']),
    knittingPlants() {
      return this.plants.filter(plant => plant.knitting)
    },
    dyeingPlants() {
      return this.plants.filter(plant => plant.dyeing)
    },
    yarnOutboundDate() {
      return this.yarnOutbound.date
    }
  },
  watch: {
    yarnOutboundDate() {
      this.date = this.yarnOutboundDate ? moment(this.yarnOutbound.date).format('YYYY-MM-DD') : null
    }
  }
}
</script>
