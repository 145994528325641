<template>
  <router-view></router-view>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const authModule = createNamespacedHelpers('auth')

export default {
  name: "FabricPoLayout",
  mounted() {
    if (this.roles.some(role => role.slug === 'admin')) {
      this.getUsers()
    }
  },
  methods: {
    ...authModule.mapActions(['getUsers'])
  },
  computed: {
    ...authModule.mapGetters(['roles'])
  }
}
</script>
