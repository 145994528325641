import URI from 'urijs'
import Vue from 'vue';
import { config } from '../../plugins/env.config';

async function getAllProviderInvoices(filters, attemptNumber = 1) {
  const uri = new URI(config.apiBaseUrl)
  uri.segment('provider-invoices')
  uri.segment('list')

  if (filters.page) {
    uri.addQuery('page', filters.page)
  }

  if (filters.limit) {
    uri.addQuery('limit', filters.limit)
  }

  if (filters.searchBy) {
    uri.addQuery('searchBy', filters.searchBy)
  }

  if (filters.search) {
    uri.addQuery('search', filters.search)
  }

  try {
    return await Vue.prototype.$http.get(uri.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getAllProviderInvoices(filters, attemptNumber + 1)
    }

    throw error
  }
}

async function createProviderInvoice (payload, attemptNumber = 1) {
  const uri = new URI(config.apiBaseUrl)
  uri.segment('provider-invoices')
  uri.segment('create')

  try {
    return await Vue.prototype.$http.post(uri.toString(), payload)

  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay

    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return createProviderInvoice(payload, attemptNumber + 1)
    }

    throw error
  }
}

async function getProviderInvoice(id, attemptNumber = 1) {
  const uri = new URI(config.apiBaseUrl)
  uri.segment('provider-invoices')
  uri.segment(id)

  try {
    return await Vue.prototype.$http.get(uri.toString())
  } catch(error) {
    const maxAttempts = config.maxAttemptNumber
    const attemptDelay = config.attemptDelay
    const response = error.response
    const status = response.status
    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelay * 1000 ))
      return getProviderInvoice(id, attemptNumber + 1)
    }

    throw error
  }
}

export default {
  getAllProviderInvoices, createProviderInvoice, getProviderInvoice
}
