import store from '@/plugins/vuex'

function calculoLoss(arr){

const details = arr
details.forEach((type,index) => {
    let sumYarn = 0
    let totalYarn = 0
    let totalKgs = 0
    let dyeingPrice = 0
        let colors =  (details[index].colors)
        if (colors){
        colors.forEach(el => {
           sumYarn = parseFloat(el.quantity) + sumYarn
           dyeingPrice = dyeingPrice + el.dyeingPrice
           details[index].sumYarn = sumYarn           
        });
        }
        details[index].dyeingPrice = dyeingPrice/colors.length
        totalYarn = (sumYarn * (details[index].knittingPercentage/100))+sumYarn
        sumYarn = totalYarn
        totalYarn = (sumYarn * (details[index].dyeingPercentage/100))+sumYarn
        sumYarn = totalYarn
        totalYarn = (sumYarn * (details[index].printingPercentage/100))+sumYarn
        sumYarn = totalYarn
        if(details[index].yarns.length > 0){
        let yarns = (details[index].yarns)
        yarns.forEach((e,i) => {
            totalKgs = (totalYarn) * (e.percentage/100)
            yarns[i].totalKgs = totalKgs
            details[index].totalYarn = totalYarn
            details[index].knittingAmount = sumarMontos(type,"KNITTING") * type.knittingPrice
            details[index].dyeingAmount = sumarMontos(type,"DYEING") * type.colors[0].dyeingPrice
        });
        }
    });

}

function sumarMontos(arr,process){        
    let sum = 0
    let totalYarn = arr.sumYarn        
    if (process === "KNITTING"){
            sum=totalYarn*(1+(arr.dyeingPercentage/100))
            totalYarn = sum
            sum=totalYarn*(1+(arr.printingPercentage/100))                     
    }

    if (process === "DYEING"){
        let isPrinting = 1
        // this.processes.forEach(e=>{
        //         if (e.status === 'PRINTING'){
        //             isPrinting = 1
        //         }
        // })
            if (isPrinting == 1){
                sum=totalYarn*(1+(arr.printingPercentage/100))
                totalYarn = sum
            }else{
                sum=totalYarn
                totalYarn = sum
            }
    }

    if (process === "PRINTING"){
        this.processes.forEach(e=>{
                if (e.status === 'PRINTING'){
                    sum = totalYarn
                }
        })
    }

    return sum
}

export default{
    calculoLoss, sumarMontos
}