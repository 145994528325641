<template>
  <v-col>
    <v-row>
      <v-col>
        <v-select
          dense
          outlined
          background-color="white"
          :value="item.process"
          @input="change($event.key, 'process'); filterPlants()"
          :label="$t('fabricPurchaseOrder.detail.processSelection.process')"
          :items="processes"
          item-value="key"
          item-text="name"
          return-object
          hide-details="auto">
        </v-select>
      </v-col>
      <v-col>
        <v-select
          dense
          outlined
          background-color="white"
          :value="item.plantSlug"
          @input="change($event, 'plantSlug')"
          :label="$t('fabricPurchaseOrder.detail.processSelection.plant')"
          :items="filteredPlants"
          item-value="slug"
          item-text="name"
          hide-details="auto">
        </v-select>
      </v-col>

      <v-col style="max-width: 40px;" class=" d-flex justify-center">
        <v-btn icon color="error" @click="deleteElement">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" v-if="!showComment">
        <v-btn @click="addComment = true" text color="primary" class="font-weight-bold" small>add comment</v-btn>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col :cols="item.process !== 'PRINTING' ? '12': '6'" v-if="showComment">
            <v-textarea
              outlined
              rows="3"
              :label="$t('fabricPurchaseOrder.detail.processSelection.comment')"
              :value="item.comment"
              background-color="white"
              @input="change($event, 'comment')"
              hide-details="auto"
            >
            </v-textarea>
          </v-col>
          <template v-if="item.process === 'PRINTING'">
          <v-col cols="6" v-if="showComment" >
            <v-row @paste="pasteFunction($event)" v-if="!hasImages" class="ma-auto images-container d-flex justify-center align-center overflow-y-auto">
              <v-col class="text-center">
                <span class="text-body-1 grey--text" >Paste images here...</span>
              </v-col>
            </v-row>

            <v-row v-else @paste="pasteFunction($event)" class="ma-auto images-container d-flex justify-center align-center overflow-y-auto">
              <v-col cols="6" v-for="(image, index) in processImages" :key="image.lastModified">
                <v-img
                  contain
                  max-height="84"
                  @dblclick="deleteImage(index)"
                  :src="previewImage(image)"
                ></v-img>
              </v-col>
            </v-row>
          </v-col>
          </template>
        </v-row>
      </v-col>

    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'ProcessSelection',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    plants: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    filteredPlants: [],
    addComment: false,
    images: []
  }),
  mounted() {
    this.filterPlants()
  },
  computed: {
    processes() {
      return [
        {
          key: 'KNITTING',
          name: 'KNITTING'
        },
        {
          key: 'DYEING',
          name: 'DYEING'
        },
        {
          key: 'PRINTING',
          name: 'PRINTING'
        }
      ]
    },
    showComment() {
      return this.item.comment || this.item.images ? true : this.addComment
    },
    hasImages() {
      return this.processImages.length > 0 || false
    },
    processImages() {
      return this.images.length > 0 ? this.images : this.item.images && this.item.images || []
    }
  },
  methods: {
    change(value, field) {
      const row = this.item;
      row[field] = value;
      this.$emit('input', row);
    },
    deleteElement() {
      this.$emit('delete')
    },
    filterPlants() {
      if (this.item.process) {
        this.filteredPlants = this.plants.filter(plant => plant[this.item.process.toLowerCase()] === true)
        return;
      }
      this.filteredPlants = this.plants
    },
    previewImage(image) {
      return 'data:'.concat(image.type,';base64,',image.file)
    },
    deleteImage(index) {
      this.images = this.images.filter((image, idx) => idx !== index)
      const images = this.item.images.filter((image, idx) => idx !== index)
      this.change(images, 'images')
    },
    async pasteFunction(pasteEvent, callback) {

      if(pasteEvent.clipboardData === false){
          if(typeof(callback) == "function"){
              callback(undefined);
          }
      }

      var items = pasteEvent.clipboardData.items;

      if(items == undefined){
          if(typeof(callback) == "function"){
              callback(undefined);
          }
      }

      for (const element of items) {

          if (element.type.indexOf("image") == -1) continue;
          const blob = element.getAsFile();
          let base64File = await this.blobToBase64(blob)

          base64File = base64File.replace('data:image/png;base64,', '')

          const images = this.item && this.item.images || []
          if (this.images.length < images.length) {
            images.push({ file: base64File, type: blob.type, fileName: blob.name })
            this.images.push(...images)
          } else {
            images.push({ file: base64File, type: blob.type, fileName: blob.name })
            this.images.push({ file: base64File, type: blob.type, fileName: blob.name })
          }

          this.change(images, 'images')
      }
    },
    blobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    }

  }
}
</script>

<style lang="scss">
.images-container {
  border: 1px dotted #4f4949a0;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  max-height: 94px;
}
</style>
