import moment from "moment"

export default {
  yarnOutbound: state => state.yarnOutbound,
  yarnOutboundMessage: state => state.message,
  yarnOutbounds: state => {
    return state.yarnOutbounds.map(fabric => {
      const yarnOutbounds = fabric.yarnOutbounds.map(outbound => ({
        ...outbound,
        formattedDate: moment(outbound.departureDate).format('YYYY-MM-DD')
      }))

      return {
        ...fabric,
        formattedCreatedAt: moment(fabric.createdAt).format('YYYY-MM-DD'),
        yarnOutbounds
      }
    })
  },
  yarnOutboundPage: state => state.yarnOutboundPage,
  yarnSelection: state => state.yarnSelection,
  yarnOutboundById: state => state.yarnOutboundById
}
