<template>
  <v-col>
    <v-row>
      <v-col class="d-flex justify-space-between">
        <span class="text-h6 d-flex">
          <div class="pt-1">
            {{ $t('providerInvoice.add.form.detail.title') }}
          </div>
        </span>
        <v-btn
          small
          color="secondary"
          dark
          @click="addNewDetail"
        >
          {{ $t('providerInvoice.add.form.detail.addButtonDetail') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row v-for="(item, index) in providerInvoiceDetails" :key="index" class="py-8" style="border-bottom: solid 3px #e0e0e0;">
          <ProviderInvoiceItem
            :item="item"
            @input="changeDetail(index, $event)"
            @delete="deleteDetail(index)"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import ProviderInvoiceItem from './ProviderInvoiceItem.vue'
import { createNamespacedHelpers } from 'vuex'
const providerInvoiceModule = createNamespacedHelpers('providerInvoice')

export default {
  name: 'ProviderInvoiceDetail',
  components: { ProviderInvoiceItem },
  computed: {
    ...providerInvoiceModule.mapGetters(['details']),
    providerInvoiceDetails() {
      return this.details
    }
  },
  methods: {
    ...providerInvoiceModule.mapActions(['setProviderInvoiceDetail']),
    changeDetail(index, element) {
      const details = this.details
      details[index] = element
      this.setProviderInvoiceDetail(details)
    },
    deleteDetail(index) {
      const details = this.details.filter((item, idx) => idx !== index)
      this.setProviderInvoiceDetail(details)
    },
    addNewDetail() {
      const details = this.details
      details.push({}),
      this.setProviderInvoiceDetail(details)
    }
  }
}
</script>
