import moment from "moment"

export default {
  providerInvoices: state => {
    return state.providerInvoices.map(invoice => ({
      ...invoice,
      formattedInvoiceDate: moment(invoice.invoiceDate).format('YYYY-MM-DD')
    }))
  },
  details: state => state.details,
  providerInvoicesPage: state => state.providerInvoicesPage,
  providerInvoice: state => state.providerInvoice,
  loadingInvoices: state => state.loadingInvoices,
  providerInvoiceStatus: state => state.providerInvoiceStatus
}
