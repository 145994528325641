<template>
  <v-col class="white rounded pa-8" style="box-shadow: 0px 0px 15px 0px #00000036;">
    <v-row>
     <v-col class="d-flex justify-center">
        <span class="text-h4" style="text-transform:uppercase ;color: #4285F4; " >{{ $t('yarnPurchaseOrder.title') }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-space-between">
        <span class="text-subtitle-1">{{ $t('yarnPurchaseOrder.general.title') }}</span>
        <!-- <span class="text-body"> {{ $t('yarnPurchaseOrder.general.poNumberLabel') }} <span class="text-h5">{{ poNumber }}</span> </span> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" class="pa-2">
        <v-label>
          {{ $t('yarnPurchaseOrder.general.toLabel') }}
        </v-label>
        <!-- <v-select
          :items="providers"
          item-value="id"
          item-text="name"
          :value="general.provider"
          @input="change($event, 'provider')"
          dense outlined background-color="white" hide-details="auto">
        </v-select> -->
        <v-autocomplete
          v-model="selectProvider"
          :loading="loadingProvider"
          :items="providerItems"
          cache-items
          :search-input.sync="searchProviders"
          outlined
          :value="general.provider"
          @change="change($event, 'provider');"
          dense
          :menu-props="{closeOnContentClick:true}"
          item-text="name"
          item-value="id"
          hide-details
        >
          <template slot="no-data" v-if="searchProviders">
            <v-container>
              <v-row>
                <v-col>
                  <span class="text-caption secondary--text">No se encontraron registros</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template slot="append-item" v-if="addCustomValues">
            <v-container>
              <v-row class="" @click="addCustomProvider">
                <v-col role="button">
                  <span class="text-subtitle-2 primary--text">Add provider: <span class="text-subtitle-1">( {{ searchProviders }} )</span></span>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="4" class="pa-2">
        <v-label>
          {{ $t('yarnPurchaseOrder.general.vendorLabel') }}
        </v-label>
        <v-autocomplete
          v-model="select"
          :loading="loading"
          :items="vendorItems"
          cache-items
          :search-input.sync="search"
          outlined
          return-object
          :value="general.vendor"
          @change="change($event.id, 'vendor'); setSelectedVendor($event)"
          dense
          :menu-props="{closeOnContentClick:true}"
          item-text="name"
          item-value="id"
          hide-details
        >
          <template slot="no-data" v-if="search">
            <v-container>
              <v-row>
                <v-col>
                  <span class="text-caption secondary--text">No se encontraron registros</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template slot="append-item" v-if="addCustomValues">
            <v-container>
              <v-row class="" @click="addCustomVendor">
                <v-col role="button">
                  <span class="text-subtitle-2 primary--text">Agregar vendor: <span class="text-subtitle-1">( {{ search }} )</span></span>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="4" class="pa-2">
        <v-label>
          {{ $t('yarnPurchaseOrder.general.buyerLabel') }}
        </v-label>
        <v-autocomplete
          v-model="selectBuyer"
          :loading="loadingBuyer"
          :items="buyerItems"
          cache-items
          :search-input.sync="searchBuyers"
          outlined
          :value="general.buyer"
          @change="change($event, 'buyer');"
          dense
          :menu-props="{closeOnContentClick:true}"
          item-text="name"
          item-value="id"
          hide-details
        >
          <template slot="no-data" v-if="searchBuyers">
            <v-container>
              <v-row>
                <v-col>
                  <span class="text-caption secondary--text">No se encontraron registros</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template slot="append-item">
            <v-container>
              <v-row class="" @click="addCustomBuyer">
                <v-col role="button">
                  <span class="text-subtitle-2 primary--text">Agregar buyer: <span class="text-subtitle-1">( {{ searchBuyers }} )</span></span>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="4" class="pa-2">
        <v-label>
          {{ $t('yarnPurchaseOrder.general.orderDate') }}
        </v-label>
        <v-menu
          v-model="menuOrderDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              hide-details="auto"
              background-color="white"
              :value="general.orderDate"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            @input="change($event, 'orderDate'); menuOrderDate = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="4" class="pa-2">
        <v-label>
          {{ $t('yarnPurchaseOrder.general.deliveryDate') }}
        </v-label>
        <v-menu
          v-model="menuDeliveryDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              hide-details="auto"
              background-color="white"
              :value="general.deliveryDate"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            @input="change($event, 'deliveryDate');menuDeliveryDate = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="4" class="pa-2">
        <v-label>
          {{ $t('yarnPurchaseOrder.general.plant') }}
        </v-label>

        <v-select
          :items="plants"
          item-value="slug"
          item-text="name"
          :value="general.plantSlug"
          @input="change($event, 'plantSlug')"
          dense outlined background-color="white" hide-details="auto">
        </v-select>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import fabricService from '../../services/modules/fabric.service'

const yarnPOModule = createNamespacedHelpers('yarnPo')
const yarnInboundModule = createNamespacedHelpers('yarnInbound')
const authModule = createNamespacedHelpers('auth')

import ShortUniqueId from 'short-unique-id'
export default {
  name: 'GeneralInformation',
  data: () => ({
    uuid: null,
    menuOrderDate: false,
    orderDate: null,
    menuDeliveryDate: false,
    deliveryDate: null,
    buyers: [],
    buyerItems: [],
    loadingBuyer: null,
    searchBuyers: null,
    selectBuyer: null,
    loading: null,
    search: null,
    select: null,
    vendorItems: [],
    providerItems: [],
    loadingProvider: null,
    selectProvider: null,
    searchProviders: null
  }),
  created() {
    const uid = new ShortUniqueId({ length: 6 });
    this.uuid = uid();

    this.change(this.uuid, 'code')
    this.getProviders();
    this.getVendors();
    this.getPlants();
  },
  methods: {
    ...yarnPOModule.mapActions(['getProviders', 'getVendors', 'modifyYarnPurchaseOrderGeneral']),
    ...yarnInboundModule.mapActions(['getPlants']),
    change(value, field) {
      const newGeneral = this.general
      newGeneral[field] = value
      this.modifyYarnPurchaseOrderGeneral(newGeneral)
    },
    setSelectedVendor(vendor) {
      this.buyerItems = vendor && vendor.buyers || []
    },
    async searchVendor(value) {
      this.loading = true
      const response = await fabricService.getVendor(value)
      this.loading = false
      this.vendorItems = response.data
    },
    async searchBuyer(value) {
      if (!this.general.vendor) {
        return;
      }
      this.loadingBuyer = true
      const response = await fabricService.getBuyer(this.general.vendor,value)
      this.loadingBuyer = false
      this.buyerItems = response.data
    },
    async searchProvider(value) {
      this.loading = true
      const response = await fabricService.getProvider(value)
      this.loading = false
      this.providerItems = response.data
    },
    async addCustomVendor() {
      const response = await fabricService.createVendor({name: this.search})
      this.select = response.data
      this.vendorItems = [response.data]
      this.change(this.select.id, 'vendor')
    },
    async addCustomBuyer() {
      const response = await fabricService.createBuyer({name: this.searchBuyers, vendorId: this.general.vendor})
      this.buyerItems = [response.data]
      this.$nextTick(() => {
        this.selectBuyer = response.data.id
        this.change(this.selectBuyer, 'buyer')
      })
    },
    async addCustomProvider() {
      const response = await fabricService.createProvider({name: this.searchProviders})
      this.selectProvider = response.data
      this.providerItems = [response.data]
      this.change(this.selectProvider.id, 'provider')
    },
  },
  computed: {
    ...yarnPOModule.mapGetters(['providers', 'vendors', 'general']),
    ...yarnInboundModule.mapGetters(['plants']),
    ...authModule.mapGetters(['roles']),
    poNumber() {
      return this.general.code ? this.general.code : this.uuid
    },
    selectedVendor() {
      return this.general.vendor
    },
    addCustomValues() {
      return (this.search || this.searchProviders) && this.roles.find(role => role.slug === 'add-custom-values')
    },
  },
  watch: {
    vendors() {
      this.vendorItems = this.vendors
      this.select = this.general.vendor
      const vendor = this.vendors.find(vendor => vendor.id === this.general.vendor)
      if (this.general.edit) {
        this.buyerItems = vendor.buyers || []
        this.selectBuyer = this.general.buyer
      }
    },
    providers() {
      this.providerItems = this.providers
      this.selectProvider = this.general.provider
    },
    selectedVendor() {
      this.vendorItems = this.vendors
      this.select = this.general.vendor
      const vendor = this.vendors.find(vendor => vendor.id === this.general.vendor)
      if (this.general.edit && vendor) {
        this.buyerItems = vendor.buyers || []
        this.selectBuyer = this.general.buyer
      }
    },
    search(value) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        value && value !== this.select && this.searchVendor(value)
      }, 500)
    },
    searchBuyers(value) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        value && value !== this.selectBuyer && this.searchBuyer(value)
      }, 500)
    },
    searchProviders(value) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        value && value !== this.selectProvider && this.searchProvider(value)
      }, 500)
    }
  }
}
</script>
