<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-center align-center">
        <span class="text-h3 pr-3">{{ $t('yarnInventary.list.title') }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="yarnInventary"
          hide-default-footer
          :items-per-page="-1"
          class="elevation-1"
          
        >
          <template v-slot:[`header.name`]="{header}">
            <div class="d-flex flex-column">
              <span class="primary--text pb-2">Total (KGS)</span>
              <span>{{ header.text }}</span>
            </div>
          </template>
          <template v-slot:[`header.quantity`]="{header}">
            <div class="d-flex flex-column">
              <span class="primary--text pb-2">{{ header.text.split('|')[0] }}</span>
              <span>{{ header.text.split('|')[1] }}</span>
            </div>
          </template>
          <template v-slot:[`header.floating`]="{header}">
            <div class="d-flex flex-column">
              <span class="primary--text pb-2">{{ header.text.split('|')[0] }}</span>
              <span>{{ header.text.split('|')[1] }}</span>
            </div>
          </template>
          <template v-slot:[`header.hold`]="{header}">
            <div class="d-flex flex-column">
              <span class="primary--text pb-2">{{ header.text.split('|')[0] }}</span>
              <span>{{ header.text.split('|')[1] }}</span>
            </div>
          </template>
          <template v-slot:[`header.total`]="{header}">
            <div class="d-flex flex-column">
              <span class="primary--text pb-2">{{ header.text.split('|')[0] }}</span>
              <span>{{ header.text.split('|')[1] }}</span>
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="viewItem(item)"
            >
              mdi-eye
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const yarnInboundModule = createNamespacedHelpers('yarnInbound')

export default {
  name: 'YarnInventaryList',
  created() {
    this.getYarnInventary()
  },
  methods: {
    ...yarnInboundModule.mapActions(['getYarnInventary']),
    viewItem(item) {
      this.$router.push({ name: 'YarnPlantsInventary', params: { yarnId: item.id }})
    }
  },
  computed: {
    ...yarnInboundModule.mapGetters(['yarnInventary']),
    headers() {
      let quantity = 0, floating = 0, hold = 0, total = 0;

      this.yarnInventary.forEach(inventary => {
        quantity += inventary.quantity;
        floating += inventary.floating;
        hold += inventary.hold;
        total += parseFloat(inventary.total);
      })

      return [
        {
          text: this.$t('yarnInventary.list.header.yarn'),
          value: 'name',
          align: 'left',
          class: 'text-subtitle-1 font-weight-bold',
          cellClass: 'text-subtitle-2 secondary--text',
          divider: true
        },
        {
          text: parseFloat(quantity.toFixed(2)).toLocaleString().concat('|', this.$t('yarnInventary.list.header.exist')),
          value: 'quantity',
          align: 'right',
          class: 'text-subtitle-1',
          divider: true
        },
        {
          text: parseFloat(floating.toFixed(2)).toLocaleString().concat('|', this.$t('yarnInventary.list.header.float')),
          value: 'floating',
          align: 'right',
          class: 'text-subtitle-1',
          divider: true
        },
        {
          text: parseFloat(hold.toFixed(2)).toLocaleString().concat('|', this.$t('yarnInventary.list.header.hold')),
          value: 'hold',
          align: 'right',
          class: 'text-subtitle-1',
          divider: true
        },
        {
          text: parseFloat(total.toFixed(2)).toLocaleString().concat('|', this.$t('yarnInventary.list.header.total')),
          value: 'total',
          align: 'right',
          class: 'text-subtitle-1',
          cellClass: 'text-subtitle-2 secondary--text',
          divider: true
        },
        {
          text: "Options",
          value: "action",
          align: 'center',
          class: 'text-subtitle-1',
        }
      ]
    }
  }
}
</script>
