
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_USERS = 'SET_USERS'
export const SET_DRAWER = 'SET_DRAWER'

export const SET_PROVIDERS = 'SET_PROVIDERS'
export const SET_VENDORS = 'SET_VENDORS'
export const MODIFY_YARN_PURCHASE_ORDER_DETAIL = 'MODIFY_YARN_PURCHASE_ORDER_DETAIL'
export const SET_YARNS = 'SET_YARNS'
export const MODIFY_YARN_PURCHASE_ORDER_FOOTER = 'MODIFY_YARN_PURCHASE_ORDER_FOOTER'
export const MODIFY_YARN_PURCHASE_ORDER_GENERAL = 'MODIFY_YARN_PURCHASE_ORDER_GENERAL'
export const SET_YARN_PO_STATUS = 'SET_YARN_PO_STATUS'
export const SET_YARN_PO_ID = 'SET_YARN_PO_ID'
export const RESET_YARN_PO = 'RESET_YARN_PO'
export const SET_YARNS_POS = 'SET_YARNS_POS'
export const SET_YARNS_OF_PO = 'SET_YARNS_OF_PO'

export const SET_YARN_INBOUND = 'SET_YARN_INBOUND'
export const SET_MESSAGE = 'SET_MESSAGE'
export const SET_YARN_INBOUNDS = 'SET_YARN_INBOUNDS'
export const SET_YARN_INBOUND_BY_ID = 'SET_YARN_INBOUND_BY_ID'
export const SET_YARN_INVENTARY = 'SET_YARN_INVENTARY'

export const SET_YARN_OUTBOUND = 'SET_YARN_OUTBOUND'
export const SET_OUTBOUND_MESSAGE = 'SET_MESSAGE'
export const SET_YARN_OUTBOUNDS = 'SET_YARN_OUTBOUNDS'
export const SET_YARN_OUTBOUND_YARNS = 'SET_YARN_OUTBOUND_YARNS'
export const SET_YARN_OUTBOUND_BY_ID = 'SET_YARN_OUTBOUND_BY_ID'

export const SET_PLANTS = 'SET_PLANTS'

export const MODIFY_FABRIC_PO_GENERAL = 'MODIFY_FABRIC_PO_GENERAL'
export const SET_FABRIC_PO_STATUS = 'SET_FABRIC_PO_STATUS'
export const MODIFY_FABRIC_PO_DETAIL = 'MODIFY_FABRIC_PO_DETAIL'
export const MODIFY_FABRIC_PO_DETAIL_YARN = 'MODIFY_FABRIC_PO_DETAIL_YARN'
export const RESET_FABRIC_PO = 'RESET_FABRIC_PO'
export const SET_FABRIC_POS = 'SET_FABRIC_POS'
export const SET_YARNS_OF_PO_FABRIC = 'SET_YARNS_OF_PO_FABRIC'
export const SET_FABRIC_PO_ID = 'SET_FABRIC_PO_ID'
export const SET_FABRIC_PO = 'SET_FABRIC_PO'
export const UPDATE_VERSIONS = 'UPDATE_VERSIONS'
export const SET_UPLOADING_FILES = 'SET_UPLOADING_FILES'
export const SET_FILES = 'SET_FILES'
export const REMOVE_FILE = 'REMOVE_FILE'
export const RESET_FILES = 'RESET_FILES'

export const SET_PROVIDER_INVOICES = 'SET_PROVIDER_INVOICES'
export const SET_LOADING_PROVIDER_INVOICES = 'SET_LOADING_PROVIDER_INVOICES'
export const SET_PROVIDER_INVOICE = 'SET_PROVIDER_INVOICE'
export const SET_PROVIDER_INVOICE_DETAIL = 'SET_PROVIDER_INVOICE_DETAIL'
export const SET_PROVIDER_INVOICE_STATUS = 'SET_PROVIDER_INVOICE_STATUS'
