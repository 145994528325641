import { SET_AUTHENTICATED, SET_DRAWER, SET_TOKEN, SET_USERS } from '../mutation-types'

export default {
  [SET_AUTHENTICATED] (state, { token, email, refreshToken, roles, name, functionalities }) {
    state.isAuthenticated = !!token
    state.accessToken = token
    state.username = email
    state.name = name
    state.email = email
    state.refreshToken = refreshToken
    state.functionalities = functionalities
    state.roles = roles
    localStorage.setItem('isAuthenticated', state.isAuthenticated)
    localStorage.setItem('accessToken', state.accessToken)
    localStorage.setItem('username', state.username)
    localStorage.setItem('name', state.name)
    localStorage.setItem('email', state.email)
    localStorage.setItem('refreshToken', refreshToken)
    localStorage.setItem('roles', JSON.stringify(state.roles))
    localStorage.setItem('functionalities', JSON.stringify(state.functionalities))
  },
  [SET_TOKEN] (state, {accessToken, refreshToken }) {
    state.accessToken = accessToken
    state.refreshToken = refreshToken
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('refreshToken', refreshToken)
  },
  [SET_USERS] (state, users ) {
    state.users = users
  },
  [SET_DRAWER] (state, payload) {
    state.drawer = payload
  }
}
