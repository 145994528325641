import {
  SET_VENDORS,
  MODIFY_FABRIC_PO_GENERAL,
  SET_FABRIC_PO_STATUS,
  MODIFY_FABRIC_PO_DETAIL,
  MODIFY_FABRIC_PO_DETAIL_YARN,
  RESET_FABRIC_PO,
  SET_FABRIC_POS,
  SET_PLANTS,
  SET_YARNS_OF_PO_FABRIC,
  SET_FABRIC_PO,
  SET_FABRIC_PO_ID,
  UPDATE_VERSIONS,
  SET_UPLOADING_FILES,
  SET_FILES,
  REMOVE_FILE,
  RESET_FILES
} from '../mutation-types'

export default {
  [SET_VENDORS] (state, payload) {
    state.vendors = payload
  },
  [MODIFY_FABRIC_PO_GENERAL] (state, payload) {
    state.general = payload
  },
  [SET_FABRIC_PO_STATUS] (state, payload) {
    state.fabricPoStatus = payload
  },
  [MODIFY_FABRIC_PO_DETAIL] (state, payload) {
    state.detail = payload
  },
  [MODIFY_FABRIC_PO_DETAIL_YARN] (state, payload) {
    state.yarns = payload
  },
  [RESET_FABRIC_PO] (state) {
    state.general = {
      tlc: 'ASIA',
      processes: [{
        process: 'KNITTING'
      },
      {
        process: 'DYEING'
      }]
    }
    state.detail = [{
      yarns: [{}],
      unitType: state.detail[0].unitType,
      colors: [{}],
    }]
    state.files = []
  },
  [SET_FABRIC_POS] (state, payload) {
    state.fabricPos = payload.data
    state.fabricPoPage = payload.meta
  },
  [SET_PLANTS] (state, payload) {
    state.plants = payload
  },
  [SET_YARNS_OF_PO_FABRIC] (state, payload ) {
    state.currentPoFabricYarns = payload
  },
  [SET_FABRIC_PO] (state, payload) {
    state.fabricPo = payload
  },
  [SET_FABRIC_PO_ID] (state, payload) {
    state.fabricPoById = payload
  },
  [UPDATE_VERSIONS] (state, payload) {
    const po = state.fabricPos[payload.index]
    po.versions = payload.versions
    state.fabricPos[payload.index] = po
    payload.versions.forEach(version => {
      const exist = state.fabricPosVersions.find(existedVersion => existedVersion.id === version.id)
      if (!exist) {
        state.fabricPosVersions.push(version)
      }
    })
  },
  [SET_UPLOADING_FILES] (state, payload) {
    state.uploadingFiles = payload
  },
  [SET_FILES] (state, payload) {
    state.files.push(payload)
  },
  [REMOVE_FILE] (state, file) {
    state.files = state.files.filter(persistedFile => persistedFile.id !== file.id)
  },
  [RESET_FILES] (state) {
    state.files = []
  }
}
