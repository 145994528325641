<template>
  <v-app style="background: transparent;">
    <v-app-bar
      id="appBar"
      app
      color="primary"
      dark
    >
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col class="d-flex justify-space-between align-center">
            <!-- <v-app-bar-nav-icon v-if="isAuthenticated" @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
            <span class="text-h3 pl-3">Kantex S.A.</span>
            <v-btn
              depressed
              color="primary"
              @click="logout"
            >
              <h5 class="caption">{{ $t('exitButton') }}</h5>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-app-bar>
    <v-navigation-drawer
      v-model="drawerTrigger"
      v-if="isAuthenticated"
      app
    >
      <template v-slot:prepend>
        <v-container>
          <v-row>
            <v-col class="text-center">
              <span class="text-h3">Kantex</span>
              <h5 class="pb-3">{{ $t('hello') }} {{ name }} {{$t('hello2')}}</h5>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-container>
      </template>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          mandatory
          active-class="deep-purple--text text--accent-4"
        >
          <div v-for="menu in menus" :key="menu.key">
            <v-list-group v-if="menu.children" no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-1 font-weight-bold pb-2 pt-1">{{ $t(menu.key) }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item @click="gotoPath(children.path)" v-for="children in menu.children" :key="children.key" >
                <v-list-item-title class="text-subtitle-2 font-weight-bold pb-2 pt-1">{{ $t(children.key) }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-item v-else @click="gotoPath(menu.path)">
              <v-list-item-title class="text-h6 font-weight-bold">{{ $t(menu.key) }}</v-list-item-title>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <v-list dense>


            <v-list-item>
              <span class="text-overline text-center">
                <v-select
            style="background-color: white;"
            :items="locales"
            :item-text="item => $t(item.key)"
            item-value="locale"
            v-model="$i18n.locale"
            menu-props="auto"
            hide-details="auto"
          >

          </v-select>
              </span>
            </v-list-item>

        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
    <v-footer app inset id="footBar" style="background-color: transparent;">
    </v-footer>
  </v-app>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const authModule = createNamespacedHelpers('auth')
export default {
  name: 'App',

  data: () => ({
    group: null,
    locales: [
      { key: 'locales.es', locale: 'es' },
      { key: 'locales.en', locale: 'en' },
      { key: 'locales.ko', locale: 'ko' }
    ],
    menus: [
      {
        key: 'menu.yarnPurchaseOrder.title',
        children: [
          { key: 'menu.yarnPurchaseOrder.list', path: '/yarn-po/list' },
          { key: 'menu.yarnPurchaseOrder.new', path: '/yarn-po/new' }
        ]
      },
      {
        key: 'menu.yarnInbound.title',
        children: [
          { key: 'menu.yarnInbound.list', path: '/yarn-inbound/list' },
          { key: 'menu.yarnInbound.new', path: '/yarn-inbound/new' },

        ]
      },
      {
        key: 'menu.fabricPo.title',
        children: [
          { key: 'menu.fabricPo.new', path: '/fabric-po/new' },
          { key: 'menu.fabricPo.list', path: '/fabric-po/list' }
        ]
      },
      {
        key: 'menu.yarnOutbound.title',
        children: [
          { key: 'menu.yarnOutbound.new', path: '/yarn-outbound/new' },
          { key: 'menu.yarnOutbound.list', path: '/yarn-outbound/list' },
        ]
      },
      {
        key: 'menu.providerInvoice.title',
        children: [
          { key: 'menu.providerInvoice.new', path: '/provider-invoice/new' },
          { key: 'menu.providerInvoice.list', path: '/provider-invoice/list' },
        ]
      },
      {
        key: 'menu.yarnInventary.title',
        children: [
          { key: 'menu.yarnInventary.list', path: '/yarn-inventary/list' },
        ]
      }
    ]
  }),
  methods: {
    ...authModule.mapActions(['setDrawer']),
    gotoPath(path) {
      this.$router.push(path).catch(() => { })
    },
    logout() {
      localStorage.clear()
      location.reload()
    }
  },
  computed: {
    ...authModule.mapGetters(['isAuthenticated','username','name', 'drawer']),
    drawerTrigger: {
      get() {
        return this.drawer
      },
      set(value) {
        this.setDrawer(value)
      }
    }
  }
};

</script>
