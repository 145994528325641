<template>
  <v-container>
    <v-row v-if="yarnPOStatus.status">
      <v-col>
        <v-alert
          dense
          outlined
          :type="yarnPOStatus.status"
        >
          <span class="text-subtitle-1">{{ $t(yarnPOStatus.message, { code: general.code }) }}</span>
        </v-alert>
      </v-col>
    </v-row>
    <v-row class="my-3" v-if="hasInformation">
      <general-information></general-information>
    </v-row>
    <v-row class="my-3" v-if="hasInformation">
      <yarn-purchase-detail/>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-end">
        <v-btn
          color="primary"
          dark
          @click="edit ? updateYarnPo() : addNewYarnPO()"
        >
          {{ $t(edit ? 'yarnPurchaseOrder.updateYarnPo' : 'yarnPurchaseOrder.addNewYarnPO') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GeneralInformation from '../../components/yarn-purchase-order/GeneralInformation.vue'
import YarnPurchaseDetail from '../../components/yarn-purchase-order/YarnPurchaseDetail.vue'

import { createNamespacedHelpers } from 'vuex'

const yarnPOModule = createNamespacedHelpers('yarnPo')

export default {
  name: 'YarnPurchaseOrder',
  components: { GeneralInformation, YarnPurchaseDetail },
  data: () => ({
    edit: null
  }),
  created() {
    const id = this.$route.params && this.$route.params.id;

    if (id) {
      this.edit = true;
      this.getYarnsPoById({id, edit: true})
    }
  },
  computed: {
    ...yarnPOModule.mapGetters(['yarnPOStatus', 'general']),
    yarnPoResponse() {
      return this.yarnPOStatus.status
    },
    hasInformation() {
      return Object.keys(this.general).length > 0
    }
  },
  methods: {
    ...yarnPOModule.mapActions(['setYarnPOStatus', 'createYarnPo', 'resetYarnPO', 'getYarnsPoById', 'updateYarnPo']),
    addNewYarnPO() {
      this.createYarnPo()
    },
  },
  watch: {
    yarnPoResponse() {
      if (this.yarnPOStatus.status) {
        if (this.yarnPOStatus.status === 'success') {
          this.resetYarnPO()
        }

        setTimeout(() => {
          this.setYarnPOStatus({ status: null, message: null })
        }, 100000)
      }
    }
  }
}
</script>
