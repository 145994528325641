import { SET_LOADING_PROVIDER_INVOICES,
  SET_PROVIDER_INVOICE,
  SET_PROVIDER_INVOICES,
  SET_PROVIDER_INVOICE_DETAIL,
  SET_PROVIDER_INVOICE_STATUS } from "../mutation-types";

export default {
  [SET_PROVIDER_INVOICES] (state, payload) {
    state.providerInvoices = payload.data
    state.providerInvoicesPage = payload.meta
  },
  [SET_PROVIDER_INVOICE] (state, payload) {
    state.providerInvoice = payload
  },
  [SET_PROVIDER_INVOICE_DETAIL] (state, payload) {
    state.details = payload
  },
  [SET_LOADING_PROVIDER_INVOICES] (state, payload) {
    state.loadingInvoices = payload
  },
  [SET_PROVIDER_INVOICE_STATUS] (state, payload) {
    state.providerInvoiceStatus = payload
  },
}
