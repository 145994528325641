<template>
  <v-container>
    <v-row v-if="yarnInboundMessage.alert">
      <v-col>
        <v-alert
          dense
          v-model="yarnInboundMessage.alert"
          outlined
          :type="yarnInboundMessage.type"
        >
          <span class="text-subtitle-1">{{ $t(yarnInboundMessage.message) }}</span>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center">
        <span class="text-h3">{{ $t('yarnInbound.title') }} </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col class="d-flex justify-space-between">
                <span class="text-h6 font-weight-bold">
                  {{ $t('yarnInbound.inputDescriptions') }}
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-label>
                  {{ $t('yarnInbound.form.date') }}
                </v-label>
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      background-color="white"
                      append-icon="mdi-calendar"
                      readonly
                      v-model="date"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @input="changeDate($event, 'date'); date=$event; menuDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="6">
                <v-label>
                  {{ $t('yarnInbound.form.yarnPO') }}
                </v-label>

                <v-select
                  :items="yarnPOs"
                  @input="change($event, 'yarnPO')"
                  :value="yarnInbound.yarnPO"
                  item-value="id"
                  dense outlined background-color="white" hide-details="auto">
                  <template v-slot:selection="{ item }">
                    <span>{{ item.code }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span class="font-weight-bold">{{ item.code }}</span>
                    <span class="pl-2">{{ item.provider.name }}</span>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="6">
                <v-label>
                  {{ $t('yarnInbound.form.yarn') }}
                </v-label>

                <v-select
                  :items="poYarns"
                  :disabled="poYarns.length === 0"
                  item-value="yarnId"
                  item-text="yarnsName"
                  :value="yarnInbound.yarn"
                  return-object
                  @input="change($event.yarnId, 'yarn'); setAvailable($event)"
                  dense outlined background-color="white" hide-details="auto">
                </v-select>
              </v-col>

              <v-col cols="6">
                <v-label>
                  {{ $t('yarnInbound.form.brand') }}
                </v-label>

                <v-text-field
                  dense
                  outlined
                  :value="yarnInbound.brand"
                  @input="change($event, 'brand')"
                  background-color="white" hide-details="auto">
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-label>
                  {{ $t('yarnInbound.form.batch') }}
                </v-label>

                <v-text-field
                  dense
                  outlined
                  :value="yarnInbound.batch"
                  @input="change($event, 'batch')"
                  background-color="white" hide-details="auto">
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-label>
                  {{ $t('yarnInbound.form.quantity') }}
                </v-label>

                <v-text-field
                  dense
                  outlined
                  :value="yarnInbound.quantity"
                  @input="change(parseFloat($event), 'quantity')"
                  background-color="white" hide-details="auto"
                  persistent-hint
                  :hint="remaining ? $t('yarnInbound.form.quantityHint', { remaining }) : ''"
                  >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-label>
                  {{ $t('yarnInbound.form.plant') }}
                </v-label>

                <v-select
                  :items="plants"
                  :value="yarnInbound.plant"
                  item-value="slug"
                  item-text="name"
                  @input="change($event, 'plant')"
                  dense outlined background-color="white" hide-details="auto">
                </v-select>
              </v-col>

              <v-col cols="6">
                <v-label>
                  {{ $t('yarnInbound.form.origin') }}
                </v-label>

                <v-select
                  dense
                  outlined
                  :value="yarnInbound.origin"
                  background-color="white"
                  @input="change($event.key, 'origin')"
                  :items="origin"
                  item-value="key"
                  item-text="text"
                  return-object
                  hide-details="auto">
                </v-select>
              </v-col>

              <v-col cols="6">
                <v-label>
                  {{ $t('yarnInbound.form.unitPrice') }}
                </v-label>

                <v-text-field
                  dense
                  outlined
                  type="number"
                  :value="yarnInbound.unitPrice"
                  @input="change(parseFloat($event), 'unitPrice')"
                  background-color="white" hide-details="auto"
                  persistent-hint
                  >
                </v-text-field>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-end">
        <v-switch
          class="my-0 pr-5"
          v-model="closePo"
        >
          <template v-slot:label>
            <span class="font-weight-bold secondaty--text text-h6">{{ $t('yarnInbound.form.closePo') }}</span>
          </template>
        </v-switch>
        <v-btn
          color="primary"
          dark
          @click="edit ? updateYarnInbound() : addNewYarnInbound()"
        >
          {{ $t('yarnInbound.form.addNewYarnInbound') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5 primary--text">
          {{ $t('yarnInbound.form.dialog.title') }}
        </v-card-title>
        <v-card-text class="text-h6 secondary--text">
          {{ $t('yarnInbound.form.dialog.text') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            color="primary"
            dark
            @click="dialog = false"
          >
            {{ $t('yarnInbound.form.dialog.close') }}
          </v-btn>

          <v-btn
            color="primary"
            dark
            @click="closePoAndCreateYarnInbound"
          >
            {{ $t('yarnInbound.form.dialog.accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import moment from 'moment'
const yarnPOModule = createNamespacedHelpers('yarnPo')
const yarnInboundModule = createNamespacedHelpers('yarnInbound')

export default {
  name: 'YarnInbound',
  data: () => ({
    menuDate: false,
    edit: null,
    date: null,
    filters: {
      page: 1,
      limit: 10
    },
    closePo: false,
    dialog: false,
    remaining: null
  }),
  mounted() {
    this.resetSearch()
    this.getPlants()
    const id = this.$route.params && this.$route.params.id;
    if (id) {
      this.edit = true;
      this.getYarnInboundsById({ id, edit: true})
    }
  },
  methods: {
    ...yarnPOModule.mapActions(['getYarnsPos', 'getYarnsOfPo']),
    ...yarnInboundModule.mapActions(['setYarnInbound', 'getPlants', 'createYarnInbound', 'getYarnInboundsById', 'updateYarnInbound']),
    resetSearch() {
      this.filters.page = 1
      this.getYarnsPos(this.filters)
    },
    getMoreYarnPos() {
      if (this.yarnPosPage.currentPage != this.yarnPosPage.totalPages) {
        this.filters.page = this.filters.page + 1
        this.getYarnsPos(this.filters)
      }
    },
    change(value, field) {
      const newYarnInbound = this.yarnInbound
      newYarnInbound[field] = value
      this.setYarnInbound(newYarnInbound)
      if (field === 'yarnPO') {
        this.getYarnsOfPo(value)
      }
    },
    changeDate(date, field) {
      const isoDate = moment(date).toISOString()
      this.change(isoDate, field)
       this.$forceUpdate()
    },
    addNewYarnInbound() {
      if (this.closePo) {
        this.dialog = true
      } else {
        this.createYarnInbound()
      }
    },
    closePoAndCreateYarnInbound() {
      this.change(true, 'closePo')
      this.createYarnInbound()
    },
    setAvailable(item) {
      this.remaining = item.yarnPurchaseOrderDetailRemainingQuantity
    }
  },
  computed: {
    ...yarnPOModule.mapGetters(['yarnPos', 'yarnPosPage', 'currentYarnPoYarns', 'origin']),
    ...yarnInboundModule.mapGetters(['yarnInbound', 'plants', 'yarnInboundMessage']),
    yarnPOs() {
      return this.yarnPos
    },
    poYarns() {
      return this.currentYarnPoYarns || []
    },
    yarnInboundDate() {
      return this.yarnInbound.date
    }
  },
  watch: {
    yarnInboundDate() {
      this.date = this.yarnInboundDate ? moment(this.yarnInbound.date).format('YYYY-MM-DD') : null
    }
  }
}
</script>
