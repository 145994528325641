import i18n from '@/i18n'
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

const inputRules = {
  required: value => !!value || i18n.t('errors.inputValidations.required'),
  email: value => {
    if (!value) {
      return true;
    }
    return EMAIL_REGEX.test(value) || i18n.t('errors.inputValidations.invalidFormat')
  }
}

export { inputRules }