import { camelizeKeys } from 'humps'
import backendService from '../../services/backend.service'
import router from '@/router'
import moment from 'moment'
import {
  SET_PLANTS,
  SET_YARN_INBOUND,
  SET_MESSAGE,
  SET_YARN_INBOUNDS,
  SET_YARN_INBOUND_BY_ID,
  SET_YARN_INVENTARY
} from '../mutation-types'

const setYarnInbound = ({ commit }, payload) => {
  commit(SET_YARN_INBOUND, payload)
}

const getPlants = async({ commit }) => {
  try {
    const response = await backendService.getPlants()
    const data = camelizeKeys(response.data)
    commit(SET_PLANTS, data)
  } catch (error) {
    const response = error.response
    const message = response.data.message

    const status = {
      alert: true,
      type: 'error',
      message
    }
    commit(SET_MESSAGE, status)
  }
}

const createYarnInbound = async ({ commit, state }) => {
  const { date, yarnPO: id,
    yarn, brand, batch, quantity: incomeQuantity,
  plant: plantSlug, closePo = false, origin, unitPrice } = state.yarnInbound

  try {

    const admissionDate = moment(date).toISOString()

    const payload = { admissionDate, id, yarn, brand, batch, incomeQuantity, plantSlug, closePo, origin, unitPrice }
    await backendService.createYarnInbound(payload)

    const status = {
      alert: true,
      type: 'success',
      message: 'yarnInbound.createdInbound'
    }
    commit(SET_MESSAGE, status)

    setTimeout(() => {
      router.push('/yarn-inbound/list')
    }, 1500)

  } catch (error) {
    const response = error.response
    const message = response.data.message

    const status = {
      alert: true,
      type: 'error',
      message
    }
    commit(SET_MESSAGE, status)
  }
}

const updateYarnInbound = async ({ commit, state}) => {
  const { date, yarnPO: id,
    yarn, brand, batch, quantity: incomeQuantity,
  plant: plantSlug, closePo = false, origin, unitPrice } = state.yarnInbound

  try {

    const admissionDate = moment(date).toISOString()

    const payload = { admissionDate, id, yarn, brand, batch, incomeQuantity, plantSlug, closePo, origin, unitPrice }
    await backendService.updateYarnInbound(payload, state.yarnInboundById.id)

    const status = {
      alert: true,
      type: 'success',
      message: 'yarnInbound.createdInbound'
    }
    commit(SET_MESSAGE, status)

    setTimeout(() => {
      router.push('/yarn-inbound/list')
    }, 1500)

  } catch (error) {
    const response = error.response
    const message = response.data.message

    const status = {
      alert: true,
      type: 'error',
      message
    }
    commit(SET_MESSAGE, status)
  }
}

const setMessage = ({ commit }, payload) => {
  commit(SET_MESSAGE, payload)
}

const getYarnInbounds = async ({ commit }, payload) => {
  try {
    const response = await backendService.getYarnInbounds(payload)
    const data = response.data
    commit(SET_YARN_INBOUNDS, data)
  } catch(error) {
    const response = error.response
    const message = response.data.message

    const status = {
      alert: true,
      type: 'error',
      message
    }
    commit(SET_MESSAGE, status)
  }
}

const getYarnInboundsById = async ({ commit, dispatch }, {id, edit}) => {
  try {
    const response = await backendService.getYarnInboundsById(id)
    const data = response.data
    commit(SET_YARN_INBOUND_BY_ID, data)
    if (edit) {

      const { batch, brand, admissionDate, plant, incomeQuantity: quantity,
      yarn, yarnPurchaseOrder, origin, unitPrice } = data

      dispatch('yarnPo/getYarnsOfPo', yarnPurchaseOrder.id, { root: true})

      const formattedDate = moment(admissionDate).format('YYYY-MM-DD')

      const payload = { batch, brand, date: formattedDate, plant: plant.slug, quantity, yarn: yarn.id,
      yarnPO: yarnPurchaseOrder.id, origin, unitPrice }
      commit(SET_YARN_INBOUND, payload)
    }
  } catch(error) {
    const response = error.response
    const message = response.data.message

    const status = {
      alert: true,
      type: 'error',
      message
    }
    commit(SET_MESSAGE, status)
  }
}

const getYarnInventary = async ({ commit }) => {
  try {
    const response = await backendService.getYarnInventary()
    const data = response.data
    commit(SET_YARN_INVENTARY, data)
  } catch(error) {
    const response = error.response
    const message = response.data.message

    const status = {
      alert: true,
      type: 'error',
      message
    }
    commit(SET_MESSAGE, status)
  }
}

export default {
  setYarnInbound,
  getPlants,
  createYarnInbound,
  setMessage,
  getYarnInbounds,
  getYarnInboundsById,
  updateYarnInbound,
  getYarnInventary
}
