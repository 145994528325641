import { SET_AUTHENTICATED, SET_DRAWER, SET_USERS } from '../mutation-types'
import backendService from '../../services/backend.service'

const requestLogin = async ({ commit }, credentials ) => {
  const { email, password } = credentials;
  try {
    const response = await backendService.login(email, password);
    const data = response.data
    const token = data.accessToken
    const refreshToken = data.refreshToken

    const roles = parseJwt(token).roles
    const name = parseJwt(token).name
    const userFunctionalities = parseJwt(token).functionalities

    const functionalities = []
    roles.forEach(role => {
      functionalities.push(...role.functionalities)
    })

    functionalities.push(...userFunctionalities)

    commit(SET_AUTHENTICATED, { email, token, refreshToken, roles, name, functionalities })
  } catch(error) {

  }
}

function parseJwt (token) {
  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
}

const getUsers = async ({ commit }) => {
  try {
    const response = await backendService.getUsers()
    const data = response.data
    commit (SET_USERS, data)
  } catch( error ) {

  }
}

const setDrawer = ({commit }, payload) => {
  commit (SET_DRAWER, payload)
}

export default {
  requestLogin,
  getUsers,
  setDrawer
}
