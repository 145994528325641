<template>
  <v-container>
    <v-row>
      <v-col class="white rounded pa-8" style="box-shadow: 0px 0px 15px 0px #00000036;">
        <v-row>
          <v-col class="d-flex" style="max-width: 200px">
            <span class="pr-2 text-subtitle-1 pt-2"> {{ $t('fabricPurchaseOrder.general.date') }} </span>
            <span class="pl-2 text-body-1 pt-2">{{ date }}</span>
          </v-col>
          <v-col>
            <div class="d-flex pl-3">
              <span class="text-body-2 pt-2">{{ $t('fabricPurchaseOrder.general.cafta') }}</span>
              <v-switch
                class="my-0 px-1"
                v-model="tlc"
              ></v-switch>
              <span class="text-body-2 pt-2">{{ $t('fabricPurchaseOrder.general.asia') }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <span class="text-h4"> {{ $t('fabricPurchaseOrder.general.title') }} </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="3" sm="6" class="pa-2">
            <v-label>
              {{ $t('fabricPurchaseOrder.general.poNumber') }}
            </v-label>
            <v-text-field
              dense
              outlined
              :disabled="disabled"
              :value="general.poNumber"
              @input="change($event, 'poNumber')"
              background-color="white" hide-details="auto">
            </v-text-field>
          </v-col>

          <v-col cols="12" md="3" sm="6" class="pa-2">
            <v-label>
              {{ $t('fabricPurchaseOrder.general.vendorLabel') }}
            </v-label>
            <v-autocomplete
              v-model="select"
              :loading="loading"
              :items="vendorItems"
              cache-items
              :search-input.sync="search"
              outlined
              return-object
              :value="general.vendor"
              @change="change($event.id, 'vendor'); selectVendor($event)"
              dense
              :menu-props="{closeOnContentClick:true}"
              item-text="name"
              item-value="id"
              hide-details
            >
              <template slot="no-data" v-if="search">
                <v-container>
                  <v-row>
                    <v-col>
                      <span class="text-caption secondary--text">No se encontraron registros</span>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template slot="append-item" >
              <!-- <template slot="append-item" v-if="addCustomValues"> -->
                <v-container>
                  <v-row class="" @click="addCustomVendor">
                    <v-col role="button">
                      <span class="text-subtitle-2 primary--text">Agregar vendor: <span class="text-subtitle-1">( {{ search }} )</span></span>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" md="3" sm="6" class="pa-2">
            <v-label>
              {{ $t('fabricPurchaseOrder.general.buyerLabel') }}
            </v-label>
            <v-autocomplete
              v-model="selectBuyer"
              :loading="loadingBuyer"
              :items="buyerItems"
              cache-items
              :search-input.sync="searchBuyers"
              outlined
              :value="general.buyer"
              @change="change($event, 'buyer');"
              dense
              :menu-props="{closeOnContentClick:true}"
              item-text="name"
              item-value="id"
              hide-details
            >
              <template slot="no-data" v-if="searchBuyers">
                <v-container>
                  <v-row>
                    <v-col>
                      <span class="text-caption secondary--text">No se encontraron registros</span>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template slot="append-item">
                <v-container>
                  <v-row class="" @click="addCustomBuyer">
                    <v-col role="button">
                      <span class="text-subtitle-2 primary--text">Agregar buyer: <span class="text-subtitle-1">( {{ searchBuyers }} )</span></span>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" md="3" sm="6" class="pa-2">
            <v-label>
              {{ $t('fabricPurchaseOrder.general.deliveryDate') }}
            </v-label>
            <v-menu
              v-model="menuDeliveryDate"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  hide-details="auto"
                  background-color="white"
                  :value="general.deliveryDate"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="change($event, 'deliveryDate'); menuOrderDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <file-upload :uploadingFile="uploadingFile" :files="files" @inputFile="uploadFile" @removeFile="removeFile" @downloadFile="downloadFile"></file-upload>
          </v-col>
        </v-row>

        <v-row>
          <v-container>
            <v-row no-gutters>
              <v-col>
                <span class="text-h6 pr-3 font-weight-folder">
                  {{ $t('fabricPurchaseOrder.detail.processSelection.title') }}
                </span>
                <v-btn icon color="secondary" @click="addNewProcess()">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-for="(process, processIndex) in processes" :key="processIndex">
              <ProcessSelection v-if="plants.length > 0"
                :item="process"
                :plants="plants"
                @input="changeProcessSelection(processIndex, $event)"
                @delete="deleteProcessSelection(processIndex)"
              />
            </v-row>
          </v-container>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import ProcessSelection from './ProcessSelection.vue'
import FileUpload from './file/FileUpload.vue'
import moment from 'moment'
import fabricService from '../../services/modules/fabric.service'

const fabricPoModule = createNamespacedHelpers('fabricPo')
const authModule = createNamespacedHelpers('auth')

export default {
  name: 'FabricPoGeneralInformation',
  components: { ProcessSelection, FileUpload },
  data: () => ({
    date: null,
    buyerItems: [],
    loadingBuyer: null,
    searchBuyers: null,
    selectBuyer: null,
    loading: null,
    search: null,
    select: null,
    vendorItems: [],
    menuDeliveryDate: false,
    deliveryDate: null,
  }),
  async created() {
    this.date = moment().format('YYYY-MM-DD')
    this.getPlants();
  },
  mounted() {
    this.changeTlc('tlc', true)
  },
  methods: {
     ...fabricPoModule.mapActions(['modifyFabricPoGeneral', 'getPlants', 'uploadFabricPoFile', 'removeFile']),
    change(value, field) {
      const newGeneral = this.general
      newGeneral[field] = value
      this.modifyFabricPoGeneral(newGeneral)
    },
    selectVendor(vendor) {
      this.buyerItems = vendor && vendor.buyers || []
    },
    addNewProcess() {
      const newGeneral = this.general
      newGeneral.processes.push({})
      this.modifyFabricPoGeneral(newGeneral)
    },
    deleteProcessSelection(index) {
      let newGeneral = this.general
      const processes = newGeneral.processes.filter((item, idx) => idx !== index)
      newGeneral = {...newGeneral, processes}
      this.modifyFabricPoGeneral(newGeneral)
    },
    changeProcessSelection(index, element) {
      let newGeneral = this.general
      const processes = newGeneral.processes
      processes[index] = element
      newGeneral = {...newGeneral, processes}
      this.modifyFabricPoGeneral(newGeneral)
    },
    async searchVendor(value) {
      this.loading = true
      const response = await fabricService.getVendor(value)
      this.loading = false
      this.vendorItems = response.data
    },
    async searchBuyer(value) {
      if (!this.general.vendor) {
        return;
      }
      this.loadingBuyer = true
      const response = await fabricService.getBuyer(this.general.vendor,value)
      this.loadingBuyer = false
      this.buyerItems = response.data
    },
    async addCustomVendor() {
      const response = await fabricService.createVendor({name: this.search})
      this.select = response.data
      this.vendorItems = [response.data]
      this.change(this.select.id, 'vendor')
    },
    async addCustomBuyer() {
      const response = await fabricService.createBuyer({name: this.searchBuyers, vendorId: this.general.vendor})
      this.buyerItems = [response.data]
      this.$nextTick(() => {
        this.selectBuyer = response.data.id
        this.change(this.selectBuyer, 'buyer')
      })
    },
    async uploadFile(files) {
      for await (const file of Array.from(files)) {
        const content = await this.blobToBase64(file)
        this.uploadFabricPoFile({type: file.type, file: content, fileName: file.name})
      }
    },
    blobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
    async downloadFile(file) {
      const response = await fabricService.getFabricPOFile(this.fabricPo.id, file.id)
      const base64 = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))
      const a = document.createElement('a');
      a.href = "data:".concat(file.type, ';base64,', base64)
      a.download = file.fileName
      a.click();
    },
    changeTlc(field, event) {
      if (event) {
        this.change('ASIA', field)
      } else {
        this.change('CAFTA', field)
      }
    }
  },
  computed: {
    ...fabricPoModule.mapGetters(['vendors', 'general', 'plants', 'uploadingFile', 'files', 'fabricPo']),
    ...authModule.mapGetters(['roles']),
    tlc: {
      get() {
        return this.general.tlc === 'ASIA'
      },
      set(value) {
        this.changeTlc('tlc', value)
      }
    },
    processes() {
      return this.general.processes
    },
    selectedVendor() {
      return this.general.vendor
    },
    disabled() {
      if (this.$route.name === 'CopyFabricPo') {
        this.change('', 'poNumber')
        return false
      }
      return this.$route.params && this.$route.params.id ? true : null
    },
    addCustomValues() {
      return this.search && this.roles.find(role => role.slug === 'add-custom-values')
    },
    disableBuyerField() {
      return (this.buyers.length <= 0 && !this.general.edit) && !this.addCustomValues
    }
  },
  watch: {
    vendors() {
      this.vendorItems = this.vendors
      this.select = this.general.vendor
      const vendor = this.vendors.find(vendor => vendor.id === this.general.vendor)
      if (this.general.edit) {
        this.buyerItems = vendor.buyers || []
        this.selectBuyer = this.general.buyer
      }
    },
    selectedVendor() {
      this.vendorItems = this.vendors
      this.select = this.general.vendor
      const vendor = this.vendors.find(vendor => vendor.id === this.general.vendor)
      if (this.general.edit && vendor) {
        this.buyerItems = vendor.buyers || []
        this.selectBuyer = this.general.buyer
      }
    },
    search(value) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        value && value !== this.select && this.searchVendor(value)
      }, 500)
    },
    searchBuyers(value) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        value && value !== this.selectBuyer && this.searchBuyer(value)
      }, 500)
    }
  }
}
</script>
