<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-center align-center">
        <span class="text-h3 pr-3">{{ $t('yarnPurchaseOrder.list.title') }}</span>
        <v-btn icon color="secondary" @click="addNewYarnPo()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          dense
          outlined
          background-color="white"
          @input="search($event);"
          :label="$t('yarnPurchaseOrder.list.searchByCode')"
          hide-details="auto">
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="yarnPos"
          :items-per-page="filters.size"
          hide-default-footer
          class="elevation-1"
          >
        <template v-slot:[`item.action`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="print(item)"
        >
          mdi-printer
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
        </v-data-table>
        <v-pagination
          class="py-4"
          v-model="filters.page"
          @input="changePage"
          :length="yarnPosPage.totalPages"
          :total-visible="filters.size"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const yarnPOModule = createNamespacedHelpers('yarnPo')

export default {
  name: 'YarnPoList',
  created() {
    this.getYarnsPos(this.filters);
  },
  data: () => ({
    filters: {
      page: 1,
      limit: 10,
      searchBy: null,
      search: null
    },
  }),
  methods: {
    ...yarnPOModule.mapActions(['getYarnsPos']),
    changePage(event) {
      this.filters.page = event
      this.getYarnsPos(this.filters)
    },
    print(row){
      this.$router.push({name:'printablePOYarn', params:{id:row.id}})
    },
    addNewYarnPo() {
      this.$router.push('/yarn-po/new')
    },
    editItem(item) {
      this.$router.push({ name: 'EditYarnPurchaseOrder', params: { id: item.id }} )
    },
    search(value) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (value) {
          this.filters.searchBy = 'code'
          this.filters.search = value
          this.filters.page = 1
          this.getYarnsPos(this.filters)
        } else {
          this.filters.searchBy = null
          this.filters.search = null
          this.filters.page = 1
          this.getYarnsPos(this.filters)
        }

      }, 500)
    }
  },
  computed: {
    ...yarnPOModule.mapGetters(['yarnPos', 'yarnPosPage']),
    headers() {
      return [
        {
          text: this.$t('yarnPurchaseOrder.list.header.code'),
          value: 'code',
          align: 'center',
          class: 'text-subtitle-1 font-weight-bold',
          cellClass: 'text-subtitle-2 secondary--text'
        },
        {
          text: this.$t('yarnPurchaseOrder.list.header.orderDate'),
          value: 'formattedOrderDate',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: this.$t('yarnPurchaseOrder.list.header.provider'),
          value: 'provider.name',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: this.$t('yarnPurchaseOrder.list.header.vendor'),
          value: 'vendor.name',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: this.$t('yarnPurchaseOrder.list.header.buyer'),
          value: 'buyer.name',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: this.$t('yarnPurchaseOrder.list.header.deliveryDate'),
          value: 'formattedDeliveryDate',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: "Options",
          value: "action",
          align: 'center',
          class: 'text-subtitle-1',
        }
      ]
    }
  }
}
</script>
