<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-center align-center">
        <span class="text-h3 pr-3">{{ $t('fabricPurchaseOrder.list.title') }}</span>
        <v-btn icon color="secondary" @click="addNewFabricPo()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogMessage" width="500px" height="200px">
      <v-card>
        <v-toolbar
          color="red lighten"
          dark
        >Warning</v-toolbar>
        <v-card-text>
          <div class="text-h5 pa-12">Theres isn't Printing Process</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="dialogMessage = false"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col>
        <v-text-field
          dense
          outlined
          background-color="white"
          @input="search($event);"
          :label="$t('fabricPurchaseOrder.list.searchByPo')"
          hide-details="auto">
        </v-text-field>
      </v-col>
      <v-col v-if="isAdmin">
        <v-select
          dense
          outlined
          background-color="white"
          :label="$t('fabricPurchaseOrder.list.searchByUser')"
          :items="userFilterItems"
          @change="searchByUser"
          item-value="email"
          item-text="name"
          hide-details="auto">
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels>
          <v-expansion-panel v-for="fabric in fabricPos" :key="fabric.id">
            <v-expansion-panel-header>
              <v-container fluid>
                <v-row>
                  <v-col cols="4">
                    <span class="text-h6 primary--text">
                      {{ fabric.number }}
                      <h5 class="grey--text caption">{{ fabric.createdBy }}</h5>
                    </span>
                  </v-col>
                  <v-col >
                    <span class="grey--text">{{$t('fabricPurchaseOrder.list.header.vendor')}} </span>
                    <span class="text-subtitle-1 primary--text">{{ fabric.vendor.name }}</span>
                  </v-col>
                  <v-col class="pl-0 pr-0">
                    <span class="grey--text">{{$t('fabricPurchaseOrder.list.header.buyer')}} </span>
                    <span class="text-subtitle-1 primary--text">{{ fabric.buyer.name }}</span>
                  </v-col>
                  <v-col cols="3">
                    <span class="grey--text">{{$t('fabricPurchaseOrder.list.header.date')}} </span>
                    <span class="text-subtitle-1 primary--text">{{ fabric.formattedDate }}</span>
                  </v-col>
                  <v-col >
                    <v-icon
                      class="mr-2"
                      @click.native.stop="print(fabric,'k')"
                    >
                      mdi-alpha-k
                    </v-icon>
                    <v-icon
                      class="mr-2"
                      @click.native.stop="print(fabric,'d')"
                    >
                      mdi-alpha-d
                    </v-icon>
                    <v-icon
                      class="mr-2"
                      @click.native.stop="print(fabric,'p')"
                    >
                      mdi-alpha-p
                    </v-icon>
                    <v-icon
                      small
                      class="mr-2"
                      @click.native.stop="editItem(fabric)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      class="mr-2"
                      @click.native.stop="copy(fabric)"
                    >
                      mdi-content-copy
                    </v-icon>
                    <v-icon
                      class="mr-2"
                      color="error"
                      @click="deleteFabricPoAndDetails(fabric)"
                    >
                      mdi-delete
                    </v-icon>
                    <v-icon
                      class="mr-2"
                      color="error"
                      @click="print(fabric,'precost')"
                    >
                      pre
                    </v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headers"
                :items="fabric.versions"
                :items-per-page="filters.size"
                hide-default-footer
                class="elevation-1"
                >
                <template v-slot:[`item.additional`]="{item}">
                  <v-icon
                    v-if="item.additional"
                    class="mr-2"
                  >
                    mdi-check
                  </v-icon>

                </template>
                <template v-slot:[`item.action`]="{item}">
                  <v-icon
                      class="mr-2"
                      @click="print(item,'k')"
                    >
                      mdi-alpha-k
                    </v-icon>
                    <v-icon
                      class="mr-2"
                      @click="print(item,'d')"
                    >
                      mdi-alpha-d
                    </v-icon>
                    <v-icon
                      class="mr-2"
                      @click="print(item,'p')"
                    >
                      mdi-alpha-p
                    </v-icon>
                  <v-icon
                    class="mr-2"
                    @click="viewItem(item)"
                  >
                    mdi-eye
                  </v-icon>
                  <v-icon
                    v-if="item.additional"
                    class="mr-2"
                    color="error"
                    @click="deleteFabricPoAndDetails(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>


        <v-pagination
          class="py-4"
          v-model="filters.page"
          @input="changePage"
          :length="fabricPoPage.totalPages"
          :total-visible="filters.size"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import fabricService from '../../services/modules/fabric.service'
import { createNamespacedHelpers } from 'vuex'
const fabricModule = createNamespacedHelpers('fabricPo')
const authModule = createNamespacedHelpers('auth')

export default {
  name: 'FabricList',
  created() {
    this.getFabricPos(this.filters)
  },
  data: () => ({
    filters: {
      page: 1,
      limit: 10,
      searchBy: null,
      search: null
    },
    dialogMessage: false
  }),
  methods: {
    ...fabricModule.mapActions(['getFabricPos', 'updatePos', 'deleteFabricPo', 'setFabricPos', 'resetFabricPO']),
    changePage(event) {
      this.filters.page = event
      this.getFabricPos(this.filters)
    },
    addNewFabricPo() {
      this.$router.push('/fabric-po/new')
    },
    editItem(item) {
      this.$router.push({ name: 'EditFabricPo', params: { id: item.id } })
    },
    copy(item) {
      this.$router.push({ name: 'CopyFabricPo', params: { id: item.id } })
    },
    viewItem(item) {
      this.$router.push({ name: 'ViewFabricPo', params: { id: item.id } })
    },
    print(row,report){
      if (report === "k"){
      let routeData = this.$router.resolve({name: 'printablePoFabric', params:{id:row.id}});
      window.open(routeData.href, '_blank');
      // this.$router.push({name:'printablePoFabric', params:{id:row.id}})
      }
      if (report === "precost"){
      let routeData = this.$router.resolve({name: 'precostReport', params:{id:row.id}});
      window.open(routeData.href, '_blank');
      // this.$router.push({name:'printablePoFabric', params:{id:row.id}})
      }
      if (report === "d"){
      let routeData = this.$router.resolve({name: 'printablePoDyeing', params:{id:row.id}});
      window.open(routeData.href, '_blank');
      // this.$router.push({name:'printablePoDyeing', params:{id:row.id}})
      }
      if (report === "p"){
        let message = true
        row.fabricPoProcesses.forEach(e => {
          if (e.status === "PRINTING") {
            let routeData = this.$router.resolve({name: 'printablePoPrinting', params:{id:row.id}});
            window.open(routeData.href, '_blank');
              message = false
              return
          }
        });
        if (message == true){
          this.dialogMessage = true
        }
      }
    },
    search(value) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (value) {
          this.filters.searchBy = 'number'
          this.filters.search = value
          this.filters.page = 1
          this.getFabricPos(this.filters)
        } else {
          this.filters.searchBy = null
          this.filters.search = null
          this.filters.page = 1
          this.getFabricPos(this.filters)
        }

      }, 500)
    },
    searchByUser(value) {
      this.filters.searchBy = value === 'ALL' ? null : 'createdBy'
      this.filters.search =  value === 'ALL' ? null : value
      this.filters.page = 1
      this.getFabricPos(this.filters)
    },
    async getDisabledRecords(fabricPo) {
      const response = await fabricService.getFabricPoChilds(fabricPo.id)
      const index = this.fabricPos.findIndex(po => po.id === fabricPo.id)
      if (index >= 0) {
        const versions = response.data.filter(po => !po.enabled)
        this.updatePos({ index, versions })
      }
    },
    async deleteFabricPoAndDetails(fabricPo) {
      await this.deleteFabricPo(fabricPo.id)
      this.setFabricPos({data: [], meta: {}})
      this.$nextTick(() => {
        this.getFabricPos(this.filters)
      })
    }
  },
  computed: {
    ...fabricModule.mapGetters(['fabricPos', 'fabricPoPage','fabricPoById', 'fabricPosVersions']),
    ...authModule.mapGetters(['roles', 'users']),
    headers() {
      return [
        {
          text: this.$t('fabricPurchaseOrder.list.header.code'),
          value: 'number',
          align: 'center',
          class: 'text-subtitle-1 font-weight-bold',
          cellClass: 'text-subtitle-2 secondary--text'
        },
        {
          text: this.$t('fabricPurchaseOrder.list.header.vendor'),
          value: 'vendor.name',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: this.$t('fabricPurchaseOrder.list.header.buyer'),
          value: 'buyer.name',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: this.$t('fabricPurchaseOrder.list.header.date'),
          value: 'formattedDate',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: this.$t('fabricPurchaseOrder.list.header.updatedBy'),
          value: 'updatedBy',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: this.$t('fabricPurchaseOrder.list.header.additional'),
          value: 'additional',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: "Options",
          value: "action",
          align: 'left',
          class: 'text-subtitle-1',
        }
      ]
    },
    isAdmin() {
      return this.roles.some(role => role.slug === 'admin')
    },
    userFilterItems() {
      const usersFilter = [{email: 'ALL', name: 'All'}]
      usersFilter.push(...this.users)
      return usersFilter
    }
  },
}
</script>
