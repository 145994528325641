<template>
<v-container>
<v-col id="vCol" class="white rounded pa-8" style="box-shadow: 0px 0px 15px 0px #00000036;">
<div id="printableArea" class="estilo" style="width: 1080px;">
        <div>
            <div style="display: inline-block; float: left;">
            <img src="../../assets/logo2.png" alt="logo" width="200px" height="160px" style="align-items:flex-start;">
        </div>
        <div style="display: inline-block;">
        <h2 style="margin-bottom:25px">YARN PURCHASE ORDER #{{yarnPoById.code}}</h2>
        <h5 style="margin-bottom:50px">KM 35.3 CARRETERA AL PACIFICO PALIN, ESCUINTLA<br/>
        GUATEMALA l TEL : (502) 2296-7082 / 7083<br/>
        Email : andreakim@kantex-gt.com<br/>
        NIT : 9087357-2 </h5>
        </div>
        
        </div>


<table style="width: 500px; float: right; margin-bottom: 2em;">
    <thead style="font-weight: bold; text-align: center;">
        <td style="width:25% ;">Assistant</td>
        <td style="width:25% ;">General Manager</td>
        <td style="width:25% ;">Managing Director</td>
        <td style="width:25% ;">President</td>
    </thead>
    <tbody style="height:80px ;">
        <td style="height:80px ;"></td>
        <td></td>
        <td></td>
        <td></td>
    </tbody>
</table>


<table>
    <tr>
        <td class="titlePo">TO</td>
        <td class="dataPo">{{yarnPoById.provider.name}}</td>
        <td class="titlePo">BUYER</td>
        <td class="dataPo">{{yarnPoById.buyer.name}}</td>
    </tr>
    <tr>
        <td class="titlePo">CONSIGNEE</td>
        <td class="dataPo">NUEVO MUNDO TEXTIL S.A.</td>
        <td class="titlePo">VENDOR</td>
        <td class="dataPo">{{yarnPoById.vendor.name}}</td>
    </tr>
    <tr>
        <td class="titlePo">P.O. NUMBER</td>
        <td class="dataPo">{{yarnPoById.code}}</td>
        <td class="titlePo">YARN TYPE</td>
        <td class="dataPo">SEE BELOW</td>
    </tr>
    <tr>
        <td class="titlePo">CONTACT PERSON</td>
        <td class="dataPo">{{yarnPoById.user.name}}</td>
        <td class="titlePo">TEL</td>
        <td class="dataPo">{{yarnPoById.user.telephone}}</td>
    </tr>
    <tr>
        <td class="titlePo">EMAIL</td>
        <td class="dataPo">{{yarnPoById.user.email}}</td>
        <td class="titlePo">CEL</td>
        <td class="dataPo">{{yarnPoById.user.telephone}}</td>
    </tr>
    <tr>
        <td class="titlePo">ORDER DATE</td>
        <td class="dataPo">{{formatDate(yarnPoById.orderDate)}}</td>
        <td class="titlePo">DELIVERY DATE</td>
        <td class="dataPo">{{formatDate(yarnPoById.deliveryDate)}}</td>
    </tr>

</table>

<div class="separate"></div>
<table>
    <thead >
        <td class="yarnListTitle">YARN TYPE</td>
        <td class="yarnListTitle">ORIGIN</td>
        <td class="yarnListTitle">QTY({{yarnPoById.unitType}})</td>
        <td class="yarnListTitle">PX($/{{yarnPoById.unitType}})</td>
        <td class="yarnListTitle">AMOUNT $</td>
    </thead>
    <tbody class="yarnListBody text-center">
    <tr v-for="yarn in yarns" :key="yarn.key">
        <td>{{yarn.yarn.name}}</td>
        <td>{{yarn.origin}}</td>
        <td>{{setFormato(yarn.quantity)}}</td>
        <td>$ {{setFormato(yarn.unitPrice)}}</td>
        <td>$ {{setFormato(yarn.quantity * yarn.unitPrice)}}</td>
    </tr>
    </tbody>
    <tfoot class="text-center">
        <td colspan="2" class="yarnListTitle">TOTAL</td>
        <td>{{setFormato(totalData(yarns))}} {{yarnPoById.unitType}}</td>
        <td></td>
        <td>$ {{setFormato(totalAmount(yarns))}}</td>
    </tfoot>
</table>
<div class="separate"></div>
<table>
    <tr>
        <td class="titlePo">DELIVERY (IN-FTY)</td>
        <td class="dataPo">{{yarnPoById.plant.name}}</td>
    </tr>
    <tr>
        <td class="titlePo">ADDRESS</td>
        <td class="dataPo">{{yarnPoById.plant.address}}</td>
    </tr>
    <tr>
        <td class="titlePo">PHONE #</td>
        <td class="dataPo">{{yarnPoById.plant.telephone}}</td>
    </tr>
    <tr>
        <td class="titlePo">TERMS AND CONDITIONS</td>
        <td class="dataPo">{{yarnPoById.terms}}</td>
    </tr>
</table>

<div class="separate"></div>
<table>

    <td style="height:150px ;">
    <strong>1. ACCEPTANCE:</strong> THIS PURCHASE ORDER IS LIMITED TO THE TERMS AND CONDITIONS SPECIFIED ON THE FACE OF THIS PURCHASE ORDER AND THIS DOCUMENT AND ANY ATTACHMENTS REFERENCED THEREIN. NUEVO MUNDO TEXTIL,S.A. DOES NOT AGREE TO ANY PROPOSED ADDITION, ALTERATION, OR DELETION BY SELLER UNLESS AGREED TO IN WRITING BY THE PARTIES. ANY OTHER STATEMENT OR WRITING OF SELLER SHALL NOT ALTER, ADD TO, OR OTHERWISE AFFECT THESE TERMS AND CONDITIONS.<br><br>
    <strong>2. PACKING AND SHIPPING:</strong> All items must be properly prepared for shipment to secure lowest transportation rates and comply with carrier regulations.<br><br>
    <strong>3. SHIPMENT OR DELIVERY:</strong> Time is of the essence for shipment or delivery and to any other performance required of Seller. Shipment or delivery shall be in accordance with the schedule set out in the purchase order and only a 1.5% overship from the order will be accepted.
    </td>

</table>



<div class="separate"></div>
<div class="text-center">
<v-btn
          id="buttonPrint"
          color="primary"
          dark
          @click="PrintReport('printableArea')"
        >
         Print
        </v-btn>
</div>

</div>

</v-col>
</v-container>

</template>



<script>
import moment from 'moment'
import { createNamespacedHelpers } from 'vuex'
import formato from '../../services/helpers/formato'
const yarnPOModule = createNamespacedHelpers('yarnPo')
const authModule = createNamespacedHelpers('auth')

export default {
    props:{
   
  },
    methods:{
    ...yarnPOModule.mapActions(['getYarnsPoById']),
    ...authModule.mapActions(['setDrawer']),
    setFormato(digito){
        return formato.formato(digito)
    },
    PrintReport(){
        
        document.getElementById("appBar").style.display="none";
          document.getElementById("buttonPrint").style.display="none";
          document.getElementById("footBar").style.display="none";
          document.getElementById("vCol").className="";
          document.getElementById("vCol").style=""
          this.setDrawer(false)
          this.$nextTick(() => {
          window.print();
          document.getElementById("appBar").style.display="";
          document.getElementById("buttonPrint").style.display="";
          document.getElementById("footBar").style.display="";
          document.getElementById("vCol").className="white rounded pa-8";
          document.getElementById("vCol").style="box-shadow: 0px 0px 15px 0px #00000036;"
          this.setDrawer(true)
          })
    },
    formatDate(date){
            return moment(date).format('YYYY-MM-DD')
        },
    totalData(data){
      let total = 0
      for (const x of data){
      total = parseInt (x.quantity) + total
      }
      return total
    },
    totalAmount(data){
      let total = 0
      for (const x of data){
      total = parseInt (x.amount) + total
      }
      return total
    }
    },
    created(){
        const id = this.$route.params && this.$route.params.id;

        if (id) {
        this.edit = false;
        this.getYarnsPoById({id, edit: false})
        }
    },
    computed:{
        ...yarnPOModule.mapGetters(['yarnPoById']),
        yarns (){
            return this.yarnPoById.yarnPurchaseOrderDetail
        },
    }
}
</script>

<style lang="css" scoped>
table{
    border: 1px;
    width: 1080px;
    border-collapse: collapse;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
}

td{
    border: 1px solid;
}

.dataPo{
    font-weight: bold;
    width: 30%;
    padding-left: 1em;
    text-align: left;
}

.titlePo{
    width: 20%;
}

.yarnListTitle{
    width: 20%;
    font-weight: bold;
    text-align: center;
}

.yarnListBody{
    height: 60px;
}

h2{
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
    display:inline-block;
}
h5{
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.separate{
    padding-bottom: 35px;
}

.estilo{
  background-color: white;
}

header, footer, nav, aside {
  display: none;
}
</style>
