export default {
  providerInvoices: [],
  details: [],
  providerInvoicesPage: {},
  providerInvoice: {},
  loadingInvoices: null,
  providerInvoiceStatus: {
    status: null,
    alert: null,
    message: null
  },
}
