var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"dense":"","outlined":"","type":_vm.message.type},model:{value:(_vm.message.alert),callback:function ($$v) {_vm.$set(_vm.message, "alert", $$v)},expression:"message.alert"}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm.$t(_vm.message.message)))])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('span',{staticClass:"text-h3"},[_vm._v(_vm._s(_vm.$t('providerInvoice.add.title')))])])],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-label',[_vm._v(_vm._s(_vm.$t('providerInvoice.add.form.date')))]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":"auto","background-color":"white","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDate),callback:function ($$v) {_vm.menuDate=$$v},expression:"menuDate"}},[_c('v-date-picker',{on:{"input":function($event){_vm.changeDate($event, 'invoiceDate'); _vm.date=$event; _vm.menuDate = false}}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-label',[_vm._v(" "+_vm._s(_vm.$t('providerInvoice.add.form.invoiceNumber'))+" ")]),_c('v-text-field',{attrs:{"dense":"","outlined":"","value":_vm.providerInvoice.invoiceNumber,"background-color":"white","hide-details":"auto"},on:{"input":function($event){return _vm.change($event, 'invoiceNumber')}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-label',[_vm._v(" "+_vm._s(_vm.$t('providerInvoice.add.form.provider'))+" ")]),_c('v-select',{attrs:{"items":_vm.providers,"value":_vm.providerInvoice.provider,"item-value":"id","item-text":"name","dense":"","outlined":"","background-color":"white","hide-details":"auto"},on:{"input":function($event){return _vm.change($event, 'provider')}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-label',[_vm._v(" "+_vm._s(_vm.$t('providerInvoice.add.form.fabricPo'))+" ")]),_c('v-select',{attrs:{"items":_vm.fabricPos,"value":_vm.providerInvoice.fabricPo,"item-value":"id","dense":"","outlined":"","background-color":"white","hide-details":"auto"},on:{"input":function($event){return _vm.change($event, 'fabricPo')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("("+_vm._s(item.number)+") "+_vm._s(item.vendor.name)+" - "+_vm._s(item.buyer.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.number)+" ")]),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(item.vendor.name)+" - "+_vm._s(item.buyer.name))])]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-label',[_vm._v(" "+_vm._s(_vm.$t('providerInvoice.add.form.comment'))+" ")]),_c('v-textarea',{attrs:{"outlined":"","rows":"3","value":_vm.providerInvoice.comment,"background-color":"white","hide-details":"auto"},on:{"input":function($event){return _vm.change($event, 'comment')}}})],1)],1),_c('v-row',[_c('ProviderInvoiceDetail')],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.createProviderInvoice()}}},[_vm._v(" "+_vm._s(_vm.$t('providerInvoice.add.form.addNewProviderInvoice'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }