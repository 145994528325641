import {
  MODIFY_FABRIC_PO_DETAIL,
  MODIFY_FABRIC_PO_DETAIL_YARN,
  MODIFY_FABRIC_PO_GENERAL,
  SET_FABRIC_PO_STATUS,
  SET_VENDORS,
  RESET_FABRIC_PO,
  SET_FABRIC_POS,
  SET_PLANTS,
  SET_YARNS_OF_PO_FABRIC,
  SET_FABRIC_PO,
  SET_FABRIC_PO_ID,
  UPDATE_VERSIONS,
  SET_FILES,
  SET_UPLOADING_FILES,
  REMOVE_FILE,
  RESET_FILES
} from '../mutation-types'
import backendService from '../../services/backend.service'
import router from '@/router'
import { camelizeKeys } from 'humps';
import moment from 'moment'
import fabricService from '../../services/modules/fabric.service';

const getVendors = async ({ commit }) => {
  try {
    const response = await backendService.getVendors();
    const data = response.data

    commit(SET_VENDORS, data)
  } catch (error) {

  }
}

const modifyFabricPoGeneral = ({ commit }, payload) => {
  commit(MODIFY_FABRIC_PO_GENERAL, payload)
}

const setFabricPoStatus = ({ commit }, payload) => {
  commit(SET_FABRIC_PO_STATUS, payload)
}

const modifyFabricPoDetail = ({ commit }, payload) => {
  commit(MODIFY_FABRIC_PO_DETAIL, payload)
}

const modifyFabricPoDetailYarns = ({ commit }, payload) => {
  commit(MODIFY_FABRIC_PO_DETAIL_YARN, payload)
}

const createFabricPo = async ({ commit, state}, {id: parentId, additional:isAdditional}) => {
  try {
    const files = state.files
    const { buyer,
      poNumber: po, vendor, processes, deliveryDate, tlc } = state.general

    const fabricPoDetails = state.detail

    const deliveryDateIso = moment(deliveryDate).toISOString()

    const body = {
      buyer, po, vendor, processes, files, tlc,
      fabricPoDetails, parentId, isAdditional, deliveryDate: deliveryDateIso
    }

    await backendService.createFabricPO(body)

    setTimeout(() => {
      router.push({ name: 'FabricPoList'})
    }, 1000)

    const status = {
      status: 'success',
      message: 'fabricPurchaseOrder.createdFabricPO'
    }
    commit(SET_FABRIC_PO_STATUS, status)
  } catch(error) {
    const response = error.response
    const message = response.data.message

    const status = {
      status: 'error',
      message
    }
    commit(SET_FABRIC_PO_STATUS, status)
  }
}

const updateFabricPo = async ({ commit, state}) => {
  try {
    const files = state.files
    const { buyer,
      poNumber: po, vendor, processes, deliveryDate, tlc } = state.general

    const fabricPoDetails = state.detail

    const deliveryDateIso = moment(deliveryDate).toISOString()

    const body = {
      buyer, po, vendor, processes, files, tlc,
      fabricPoDetails, deliveryDate: deliveryDateIso
    }

    await backendService.updateFabricPo(body, state.fabricPo.id)

    setTimeout(() => {
      router.push({ name: 'FabricPoList'})
    }, 1000)

    const status = {
      status: 'success',
      message: 'fabricPurchaseOrder.createdFabricPO'
    }
    commit(SET_FABRIC_PO_STATUS, status)
  } catch(error) {
    const response = error.response
    const message = response.data.message

    const status = {
      status: 'error',
      message
    }
    commit(SET_FABRIC_PO_STATUS, status)
  }
}

const resetFabricPO = ({ commit }) => {
  commit(RESET_FABRIC_PO)
}

const updatePos = ({ commit }, payload) => {
  commit(UPDATE_VERSIONS, payload)
}

const getFabricPos = async ({ commit }, payload) => {
  try {
    const response = await backendService.getFabricPOS(payload)
    const data = response.data
    commit(SET_FABRIC_POS, data)
  } catch (error) {
    const response = error.response
    const message = response.data.message

    const status = {
      status: 'error',
      message
    }
    commit(SET_FABRIC_PO_STATUS, status)
  }
}

const setFabricPos = ({commit}, data) => {
  commit(SET_FABRIC_POS, data)
}

const getFabricPoById = async ({ commit }, id) => {
  try {
    const response = await backendService.getFabricPoById(id)
    const data = response.data
    commit(SET_FABRIC_PO_ID, data)
  } catch (error) {
    const response = error.response
    const message = response.data.message

    const status = {
      status: 'error',
      message
    }
    commit(SET_FABRIC_PO_STATUS, status)
  }
}

const getPlants = async ({ commit }) => {
  try {
    const response = await backendService.getPlants()
    const data = camelizeKeys(response.data)
    commit(SET_PLANTS, data)
  } catch(error) {
    const response = error.response
    const message = response.data.message

    const status = {
      status: 'error',
      message
    }
    commit(SET_FABRIC_PO_STATUS, status)
  }
}

const getYarnsOfPo = async ({ commit }, id) => {
  try {
    const response = await backendService.getYarnsOfPoFabric(id)
    const data = camelizeKeys(response.data)
    commit(SET_YARNS_OF_PO_FABRIC, data)
  } catch (error) {
    const response = error.response
    const message = response.data.message
  }
}

const getFabricPoByIdForEdit = async ({commit, dispatch}, {id, edit}) => {
  try {
    const response = await backendService.getFabricPo(id)
    const data = response.data
    commit(SET_FABRIC_PO, data)

    const { number: poNumber, vendor, buyer, fabricPoProcesses, fabricPoDetails, fabricPoFiles = [], dateOfDelivery, tlc } = data

    const processes = fabricPoProcesses
    .filter(process => process.enabled)
    .map(process => ({
      process: process.status,
      plantSlug: process.plant.slug,
      comment: process.comment,
      id: process.id,
      images: []
    }))

    fabricPoProcesses
    .filter(process => process.enabled)
    .forEach(process => {
      if (process.processFiles) {
        process.processFiles.forEach(image => {
          dispatch('getProcessFile', {processId: process.id, file: image})
        })
      }
    })

    const general = {
      poNumber, buyer: buyer.id, vendor: vendor.id, processes, edit, tlc, deliveryDate: moment(dateOfDelivery).format('YYYY-MM-DD')
    }

    const details = fabricPoDetails.map(detail => {
      const unitType = detail.unitType
      const colors = detail.colors.map(color => ({
        color: color.color,
        dyeingPrice: parseFloat(color.dyeingPrice),
        fabricPrice: parseFloat(color.fabricPrice),
        grade: color.grade,
        number: color.number,
        printingPrice: parseFloat(color.printingPrice),
        quantity: unitType === 'YDS' ? color.yd : parseFloat(color.quantity),
        yd: unitType === 'YDS' ? parseFloat(color.quantity) : color.yd
      }))

      const yarns = detail.yarns.map(yarn => ({
        percentage: parseFloat(yarn.percentage),
        price: parseFloat(yarn.price),
        yarn: yarn.yarn.id
      }))

      return {
        texture: detail.texture,
        type: detail.type,
        unitType: detail.unitType,
        weightGsm: detail.weightGsm,
        weightYds: detail.weightYds,
        widthUse: parseFloat(detail.widthUse),
        knittingLoss: detail.knittingPercentage,
        knittingPrice: detail.knittingPrice,
        printingLoss: detail.printingPercentage,
        dyeingLoss: detail.dyeingPercentage,
        colors,
        yarns
      }
    })

    commit(MODIFY_FABRIC_PO_GENERAL, general)
    commit(MODIFY_FABRIC_PO_DETAIL, details)
    commit(RESET_FILES)
    fabricPoFiles.forEach(file => {
      commit(SET_FILES, file)
    })


  } catch (error) {
    const response = error.response
    const message = response.data.message

    const status = {
      status: 'error',
      message
    }
    commit(SET_FABRIC_PO_STATUS, status)
  }
}

const getProcessFile = async ({commit, state, dispatch}, { processId, file}) => {
  try {
    const response = await backendService.getProcessFile(file.id)
    const base64 = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))

    let newGeneral = state.general
    const processes = newGeneral.processes
    const index = newGeneral.processes.findIndex(process => process.id === processId)
    if (index >= 0) {
      const process = processes[index];
      const images = process.images || []
      images.push({ file: base64, type: file.extension, fileName: file.fileName })
      process['images'] = images
      processes[index] = process
      newGeneral = {...newGeneral, processes}
      dispatch('modifyFabricPoGeneral', newGeneral)
    }

  } catch (error) {
    const response = error.response
    const message = response.data.message

    const status = {
      status: 'error',
      message
    }
    commit(SET_FABRIC_PO_STATUS, status)
  }
}

const deleteFabricPo = async ({commit}, id) => {
  try {
    await fabricService.deleteFabricPo(id)
  } catch (error) {
    const response = error.response
    const message = response.data.message

    const status = {
      status: 'error',
      message
    }
    commit(SET_FABRIC_PO_STATUS, status)
  }
}

const uploadFabricPoFile = async ({ commit }, payload) => {
  commit(SET_UPLOADING_FILES, true)
  try {
    const response = await fabricService.createFabricPOFile(payload)
    const file = response.data
    commit(SET_FILES, file)
    commit(SET_UPLOADING_FILES, false)
  } catch (error) {
    commit(SET_UPLOADING_FILES, false)
    const response = error.response
    const message = response.data.message

    const status = {
      status: 'error',
      message
    }
    console.log(status)
  }
}

const removeFile = ({ commit }, file) => {
  commit(REMOVE_FILE, file)
}

export default {
  getVendors,
  modifyFabricPoGeneral,
  setFabricPoStatus,
  modifyFabricPoDetail,
  modifyFabricPoDetailYarns,
  createFabricPo,
  resetFabricPO,
  getFabricPos,
  getPlants,
  getYarnsOfPo,
  getFabricPoByIdForEdit,
  updateFabricPo,
  getFabricPoById,
  updatePos,
  deleteFabricPo,
  setFabricPos,
  getProcessFile,
  uploadFabricPoFile,
  removeFile
}
