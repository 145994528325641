<template>
  <v-container>
    <v-row>
      <v-col>
        <v-select
          :items="processes"
          @input="change($event, 'process')"
          :value="item.process"
          :label="$t('providerInvoice.add.form.detail.item.process')"
          item-value="key"
          item-text="name"
          dense outlined background-color="white" hide-details="auto"
        ></v-select>
      </v-col>

      <v-col>
        <v-text-field
          dense
          outlined
          :label="$t('providerInvoice.add.form.detail.item.description')"
          :value="item.description"
          @input="change($event, 'description')"
          background-color="white" hide-details="auto">
        </v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          dense
          outlined
          type="number"
          :label="$t('providerInvoice.add.form.detail.item.quantity')"
          :value="item.quantity"
          @input="change(parseFloat($event), 'quantity'); calculateTotal()"
          background-color="white" hide-details="auto">
        </v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          dense
          outlined
          type="number"
          :label="$t('providerInvoice.add.form.detail.item.unitPrice')"
          :value="item.unitPrice"
          @input="change(parseFloat($event), 'unitPrice'); calculateTotal()"
          background-color="white" hide-details="auto">
        </v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          dense
          outlined
          readonly
          type="number"
          :label="$t('providerInvoice.add.form.detail.item.total')"
          :value="item.total"
          background-color="white" hide-details="auto">
        </v-text-field>
      </v-col>

      <v-col style="max-width: 40px;" class=" d-flex justify-center">
        <v-btn icon color="error" @click="deleteElement">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ProviderInvoiceItem',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    processes() {
      return [
        {
          key: 'KNITTING',
          name: 'KNITTING'
        },
        {
          key: 'DYEING',
          name: 'DYEING'
        },
        {
          key: 'PRINTING',
          name: 'PRINTING'
        }
      ]
    }
  },
  methods: {
    change(value, field) {
      const row = this.item;
      row[field] = value;
      this.$emit('input', row);
    },
    deleteElement() {
      this.$emit('delete')
    },
    calculateTotal() {
      if (this.item.unitPrice && this.item.quantity) {
        const total = this.item.unitPrice * this.item.quantity;
        this.item.total = total
        this.change(total, 'total')
        this.$forceUpdate()
      }
    }
  }
}
</script>
