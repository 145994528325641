import {
  SET_PLANTS,
  SET_YARN_INBOUND,
  SET_MESSAGE,
  SET_YARN_INBOUNDS,
  SET_YARN_INBOUND_BY_ID,
  SET_YARN_INVENTARY
} from '../mutation-types'

export default {
  [SET_YARN_INBOUND] (state, payload) {
    state.yarnInbound = payload
  },
  [SET_PLANTS] (state, payload) {
    state.plants = payload
  },
  [SET_MESSAGE] (state, payload) {
    state.message = payload
  },
  [SET_YARN_INBOUNDS] (state, payload) {
    state.yarnInbounds = payload.data
    state.yarnInboundPage = payload.meta
  },
  [SET_YARN_INBOUND_BY_ID] (state, payload) {
    state.yarnInboundById = payload
  },
  [SET_YARN_INVENTARY] (state, payload) {
    state.yarnInventary = payload
  }
}
