<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-center align-center">
        <span class="text-h3 pr-3">{{ $t('providerInvoice.list.title') }}</span>
        <v-btn icon color="secondary" @click="addNewProviderInvoice()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="providerInvoices"
          :items-per-page="filters.size"
          hide-default-footer
          class="elevation-1"
        >
         <template v-slot:[`item.action`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="viewItem(item)"
          >
            mdi-eye
          </v-icon>
         </template>
        </v-data-table>
        <v-pagination
          class="py-4"
          v-model="filters.page"
          @input="changePage"
          :length="providerInvoicesPage.totalPages"
          :total-visible="filters.size"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const providerInvoiceModule = createNamespacedHelpers('providerInvoice')

export default {
  name: 'ProviderInvoiceList',
  created() {
    this.getProviderInvoices(this.filters)
  },
  data: () => ({
    filters: {
      page: 1,
      limit: 10,
      size: 20,
      searchBy: null,
      search: null
    },
  }),
  methods: {
    ...providerInvoiceModule.mapActions(['getProviderInvoices']),
    changePage(event) {
      this.filters.page = event
      this.getProviderInvoices(this.filters)
    },
    addNewProviderInvoice() {
      this.$router.push({name: 'NewProviderInvoice' })
    },
    viewItem(item) {
      this.$router.push({ name: 'ViewProviderInvoice', params: {id: item.id }})
    }
  },
  computed: {
    ...providerInvoiceModule.mapGetters(['providerInvoices', 'providerInvoicesPage']),
    headers() {
      return [
        {
          text: this.$t('providerInvoice.list.header.invoiceNumber'),
          value: 'invoiceNumber',
          align: 'center',
          class: 'text-subtitle-1 font-weight-bold',
          cellClass: 'text-subtitle-2 secondary--text'
        },
        {
          text: this.$t('providerInvoice.list.header.provider'),
          value: 'provider.name',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: this.$t('providerInvoice.list.header.invoiceDate'),
          value: 'formattedInvoiceDate',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: this.$t('providerInvoice.list.header.invoiceQuantity'),
          value: 'invoiceQuantity',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: this.$t('providerInvoice.list.header.invoiceTotalAmount'),
          value: 'invoiceTotalAmount',
          align: 'center',
          class: 'text-subtitle-1',
        },
        {
          text: "Options",
          value: "action",
          align: 'center',
          class: 'text-subtitle-1',
        }
      ]
    }
  }
}
</script>
