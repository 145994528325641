<template>
  <v-col class="white rounded pa-8" style="box-shadow: 0px 0px 15px 0px #00000036;">
    <v-row>
      <v-col class="d-flex justify-space-between">
        <span class="text-subtitle-1 d-flex">
          <div class="pt-1">
            {{ $t('yarnPurchaseOrder.detail.title') }}
          </div>

          <div class="d-flex pl-3">
            <span class="text-body-2 pt-2">{{ $t('yarnPurchaseOrder.general.kgs') }}</span>
            <v-switch
              class="my-0 px-1"
              v-model="lbs"
              @click="change('unitType')"
            ></v-switch>
            <span class="text-body-2 pt-2">{{ $t('yarnPurchaseOrder.general.lbs') }}</span>
          </div>
        </span>
        <v-btn
          small
          color="secondary"
          dark
          @click="addNewDetail"
        >
          {{ $t('yarnPurchaseOrder.detail.addButtonDetail') }}
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="(((yarnPoId && yarnPurchaseDetail) || !yarnPoId) && yarns.length > 0)">
      <v-row v-for="(item, index) in yarnPurchaseDetail" :key="index">
        <YarnSelection
          :item="item"
          @input="changeYarnSelection(index, $event)"
          @delete="deleteYarnSelection(index)" />
      </v-row>
    </template>

    <v-row>
      <Footer :footer="footer" @input="changeYarnFooter($event)"/>
    </v-row>
  </v-col>
</template>

<script>
import YarnSelection from './YarnSelection.vue'
import Footer from './Footer.vue'
import { createNamespacedHelpers } from 'vuex'

const yarnPoModule = createNamespacedHelpers('yarnPo')

export default {
  name: 'YarnOrderDetail',
  components: { YarnSelection, Footer },
  data: () => ({
    lbs: false,
    yarnPoId: null
  }),
  created() {
    this.yarnPoId = this.$route.params && this.$route.params.id || null;
    this.getYarns();
    this.change('unitType')
  },
  computed: {
    ...yarnPoModule.mapGetters(['yarnPurchaseDetail', 'footer', 'general', 'yarns'])
  },
  methods: {
    ...yarnPoModule.mapActions(['modifyYarnPurchaseOrderDetail', 'getYarns', 'modifyYarnPurchaseOrderFooter', 'modifyYarnPurchaseOrderGeneral']),
    change(field) {

      const newGeneral = this.general
      if (this.yarnPoId) {
        this.lbs = newGeneral.unitType === 'LBS'
      } else {
        if (this.lbs) {
          newGeneral[field] = 'LBS';
        } else {
          newGeneral[field] = 'KGS';
        }
      }

      this.modifyYarnPurchaseOrderGeneral(newGeneral)
    },
    addNewDetail() {
      const details = this.yarnPurchaseDetail;
      details.push({})
      this.modifyYarnPurchaseOrderDetail(details)
    },
    changeYarnSelection(index, element) {
      const details = this.yarnPurchaseDetail;
      details[index] = element
      this.modifyYarnPurchaseOrderDetail(details)
    },
    deleteYarnSelection(index) {
      const details = this.yarnPurchaseDetail.filter((item, idx) => idx !== index)
      this.modifyYarnPurchaseOrderDetail(details)
    },
    changeYarnFooter(payload) {
      this.modifyYarnPurchaseOrderFooter(payload)
    }
  }
}
</script>
