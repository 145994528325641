<template>
  <v-container>
    <v-row>
      <v-col>
        <v-alert
          dense
          v-model="message.alert"
          outlined
          :type="message.type"
        >
          <span class="text-subtitle-1">{{ $t(message.message) }}</span>
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-center">
        <span class="text-h3">{{ $t('providerInvoice.add.title') }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-label>{{ $t('providerInvoice.add.form.date') }}</v-label>
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      background-color="white"
                      append-icon="mdi-calendar"
                      readonly
                      v-model="date"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @input="changeDate($event, 'invoiceDate'); date=$event; menuDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="6">
                <v-label>
                  {{ $t('providerInvoice.add.form.invoiceNumber') }}
                </v-label>
                <v-text-field
                  dense
                  outlined
                  :value="providerInvoice.invoiceNumber"
                  @input="change($event, 'invoiceNumber')"
                  background-color="white" hide-details="auto"
                  >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-label>
                  {{ $t('providerInvoice.add.form.provider') }}
                </v-label>

                <v-select
                  :items="providers"
                  @input="change($event, 'provider')"
                  :value="providerInvoice.provider"
                  item-value="id"
                  item-text="name"
                  dense outlined background-color="white" hide-details="auto"
                >
                </v-select>
              </v-col>

              <v-col cols="6">
                <v-label>
                  {{ $t('providerInvoice.add.form.fabricPo') }}
                </v-label>

                <v-select
                  :items="fabricPos"
                  @input="change($event, 'fabricPo')"
                  :value="providerInvoice.fabricPo"
                  item-value="id"
                  dense outlined background-color="white" hide-details="auto"
                >
                  <template v-slot:selection="{ item }">
                    <span>({{ item.number }}) {{ item.vendor.name }} - {{ item.buyer.name }} </span>
                  </template>
                  <template v-slot:item="{item}">
                    <span class="font-weight-bold"> {{ item.number }} </span>
                    <span class="pl-2">{{ item.vendor.name }} - {{ item.buyer.name }}</span>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12">
                <v-label>
                  {{ $t('providerInvoice.add.form.comment') }}
                </v-label>
                <v-textarea
                  outlined
                  rows="3"
                  :value="providerInvoice.comment"
                  background-color="white"
                  @input="change($event, 'comment')"
                  hide-details="auto"
                >
                </v-textarea>
              </v-col>

            </v-row>

            <v-row>
              <ProviderInvoiceDetail/>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-end">
        <v-btn
          color="primary"
          dark
          @click="createProviderInvoice()"
        >
          {{ $t('providerInvoice.add.form.addNewProviderInvoice') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProviderInvoiceDetail from '../../components/provider-invoice/ProviderInvoiceDetail.vue'
import { createNamespacedHelpers } from 'vuex'
const fabricPoModule = createNamespacedHelpers('fabricPo')
const yarnPOModule = createNamespacedHelpers('yarnPo')
const providerInvoiceModule = createNamespacedHelpers('providerInvoice')
import moment from 'moment'

export default {
  name: 'ProviderInvoice',
  components: {ProviderInvoiceDetail},
  data: () => ({
    menuDate: false,
    date: null,
    filters: {
      page: 1,
      limit: 100
    },
  }),
  mounted() {
    this.resetSearch()
    this.getProviders()

    const id = this.$route.params && this.$route.params.id
    if (id) {
      this.getProviderInvoice(id)
    }
  },
  methods: {
    ...providerInvoiceModule.mapActions(['setProviderInvoice', 'createProviderInvoice', 'getProviderInvoice']),
    ...fabricPoModule.mapActions(['getFabricPos']),
    ...yarnPOModule.mapActions(['getProviders']),
    change(value, field) {
      const newProviderInvoice = this.providerInvoice
      newProviderInvoice[field] = value
      this.setProviderInvoice(newProviderInvoice)
    },
    changeDate(date, field) {
      const isoDate = moment(date).toISOString()
      this.change(isoDate, field)
      this.$forceUpdate()
    },
     resetSearch() {
      this.filters.page = 1
      this.getFabricPos(this.filters)
    },
  },
  computed: {
    ...providerInvoiceModule.mapGetters(['providerInvoice', 'providerInvoiceStatus']),
    ...fabricPoModule.mapGetters(['fabricPos']),
    ...yarnPOModule.mapGetters(['providers']),
    message() {
      return this.providerInvoiceStatus
    },
    invoiceDate() {
      return this.providerInvoice.invoiceDate
    }
  },
   watch: {
    invoiceDate() {
      this.date = this.invoiceDate ? moment(this.providerInvoice.invoiceDate).format('YYYY-MM-DD') : null
    }
  }
}
</script>
