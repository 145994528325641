<template>

    <v-container v-if="fabricPoById[0]">
    <v-col id="vCol" class="white rounded pa-8" style="box-shadow: 0px 0px 15px 0px #00000036;">
    <div id="printableArea" class="estilo" style="font-family:malgun; width: 100%;">
        <p  style="text-align:right; margin: 0px;">Created: {{(new Date(fabricPoById[0].createdAt).toLocaleDateString())}} {{ (fabricPoById[0].tlc).substring(0,1) }}</p>
        
            <v-row>
                <v-col cols="3" >
                    <img src="../../assets/logo2.png" alt="logo" width="200px" height="160px" >
                </v-col>
                <v-col cols="5" style="display: flex; align-items: center;">
                     <h5>Address :  {{ $t('generalInfo.addres') }}<br>
                         Phone :     {{ $t('generalInfo.phone') }}<br>
                         E-mail :   {{ $t('generalInfo.mail') }}
                     </h5>
                </v-col>

                <v-col cols="4" class="d-flex align-center">
                    <table class="dataPo">
                        <tr>
                            <td>PO #</td>
                            <template v-if="fabricPoById[0]">
                            <td>{{fabricPoById[0].number}}</td>
                            </template>
                        </tr>
                        <tr>
                            <td>VENDOR</td>
                            <td>{{fabricPoById[0].vendor.name}}</td>
                        </tr>
                        <tr>
                            <td>BUYER</td>
                            <td>{{fabricPoById[0].buyer.name}}</td>
                        </tr>
                        <tr>
                            <td>DATE OF DELIVERY</td>
                            <td>{{new Date(fabricPoById[0].dateOfDelivery).toLocaleDateString()}}</td>
                        </tr>
                    </table>
                </v-col>
            </v-row>
                <h2 style="text-align:center; font-weight: bolder;">FABRIC PURCHASE ORDER</h2>                    
        <div>
            
                <h4 style="text-align:center">KNITTING</h4>
            
        </div>
    <div>
        <v-row>
            <v-col cols="4" class="d-flex align-center">
                <table class="dataPo">
                        <template v-if="processes !== 'undefined'">
                        <tr>
                            <td>KNITTING MILL</td>
                            <td>
                                <template v-for="process in processes">
                                    <template v-if="process.status === 'KNITTING'">
                                        {{process.plant.name}}
                                    </template>
                                </template>
                            </td>
                        </tr>
                        <tr>
                            <td>DYEING MILL</td>
                            <td>
                                <template v-for="process in processes">
                                    <template v-if="process.status === 'DYEING'">
                                        {{process.plant.name}}
                                    </template>
                                </template>
                            </td>
                        </tr>
                        <tr>
                            <td>PRINTING MILL</td>
                            <td>
                                <template v-for="process in processes">
                                    <template v-if="process.status === 'PRINTING'">
                                        {{process.plant.name}}
                                    </template>
                                </template>
                            </td>
                        </tr>
                        </template>
                    </table>
            </v-col>

            <v-col cols="8">
                <table class="dataPoInfo">

                        <td colspan="2"><h3> 원사 소요량 (KGS)</h3></td>

                    <tr>
                        <!-- TYPES "B" -->
                        <td style="border:0px; width: 50%">
                        <table class="dataPoInfo">



                            <template v-for="(n,index) in 6">
                                <template  v-if="types[index]">
                                <template v-if="types[index].type === 'b1'" >
                                    <tr v-for="(n,i) in 3" :style="[n%3==0 ? {'border-bottom': 'solid 4px'} : {'border-bottom': '0px'}]" :key="n.id">
                                        <template v-if="types[index].yarns[i]">
                                            <td style="width:5%; height: 32px; font-weight: bold;">{{(types[index].type).toUpperCase()}}</td>
                                            <td style="width:65%;">{{types[index].yarns[i].yarn.name}}</td>
                                            <td style="width:10%">{{parseFloat(types[index].yarns[i].percentage)}}%</td>
                                            <td style="width:20%">{{formato((types[index].yarns[i].totalKgs))}} KGS</td>
                                        </template>
                                        <template v-else>
                                            <td style="width:5%; height: 32px; font-weight: bold;">{{(types[index].type).toUpperCase()}}</td>
                                            <td style="width:65%"></td>
                                            <td style="width:10%"></td>
                                            <td style="width:20%"></td>
                                        </template>
                                    </tr>
                                </template>
                                <template v-else-if="types[index].type === 'b2'">
                                    <tr v-for="(n,i) in 3" :style="[n%3==0 ? {'border-bottom': 'solid 4px'} : {'border-bottom': '0px'}]" :key="n.name">
                                        <template v-if="types[index].yarns[i]">
                                            <td style="width:5%; height: 32px; font-weight: bold;">{{(types[index].type).toUpperCase()}}</td>
                                            <td style="width:65%">{{types[index].yarns[i].yarn.name}}</td>
                                            <td style="width:10%">{{parseFloat(types[index].yarns[i].percentage)}}%</td>
                                            <td style="width:20%">{{formato(types[index].yarns[i].totalKgs)}} KGS</td>
                                        </template>
                                        <template v-else>
                                            <td style="width:5%; height: 32px; font-weight: bold;">{{(types[index].type).toUpperCase()}}</td>
                                            <td style="width:65%"></td>
                                            <td style="width:10%"></td>
                                            <td style="width:20%"></td>
                                        </template>
                                    </tr>
                                </template>
                                <template v-else-if="types[index].type === 'b3'">
                                    <tr v-for="(n,i) in 3" :style="[n%3==0 ? {'border-bottom': 'solid 4px'} : {'border-bottom': '0px'}]" :key="n.n">
                                        <template v-if="types[index].yarns[i]">
                                            <td style="width:5%; height: 32px; font-weight: bold;">{{(types[index].type).toUpperCase()}}</td>
                                            <td style="width:65%">{{types[index].yarns[i].yarn.name}}</td>
                                            <td style="width:10%">{{parseFloat(types[index].yarns[i].percentage)}}%</td>
                                            <td style="width:20%">{{formato(types[index].yarns[i].totalKgs)}} KGS</td>
                                        </template>
                                        <template v-else>
                                            <td style="width:5%; height: 32px; font-weight: bold;">{{(types[index].type).toUpperCase()}}</td>
                                            <td style="width:65%"></td>
                                            <td style="width:10%"></td>
                                            <td style="width:20%"></td>
                                        </template>
                                    </tr>
                                </template>
                                </template>
                            </template>
                        </table>
                    </td>
                    <!-- TYPES "T" -->
                    <td style="border:0px; width: 50%">
                        <table class="dataPoInfo">
                            <template v-for="(n,index) in 6">
                                <template  v-if="types[index]">
                                <template v-if="types[index].type === 't1'">
                                    <tr v-for="(n,i) in 3" :style="[n%3==0 ? {'border-bottom': 'solid 4px'} : {'border-bottom': '0px'}]" :key="n.a">
                                        <template v-if="types[index].yarns[i]">
                                            <td style="width:5%; height: 32px;  font-weight: bold;">{{(types[index].type).toUpperCase()}}</td>
                                            <td style="width:65%">{{types[index].yarns[i].yarn.name}}</td>
                                            <td style="width:10%">{{parseFloat(types[index].yarns[i].percentage)}}%</td>
                                            <td style="width:20%">{{formato((types[index].yarns[i].totalKgs))}} KGS</td>
                                        </template>
                                        <template v-else>
                                            <td style="width:5%; height: 32px;  font-weight: bold;">{{(types[index].type).toUpperCase()}}</td>
                                            <td style="width:65%"></td>
                                            <td style="width:10%"></td>
                                            <td style="width:20%"></td>
                                        </template>
                                    </tr>
                                </template>
                                <template v-else-if="types[index].type === 't2'">
                                    <tr v-for="(n,i) in 3" :style="[n%3==0 ? {'border-bottom': 'solid 4px'} : {'border-bottom': '0px'}]" :key="n.b">
                                        <template v-if="types[index].yarns[i]">
                                            <td style="width:5%; height: 32px; font-weight: bold;">{{(types[index].type).toUpperCase()}}</td>
                                            <td style="width:65%">{{types[index].yarns[i].yarn.name}}</td>
                                            <td style="width:10%">{{parseFloat(types[index].yarns[i].percentage)}}%</td>
                                            <td style="width:20%">{{formato((types[index].yarns[i].totalKgs))}} KGS</td>
                                        </template>
                                        <template v-else>
                                            <td style="width:5%; height: 32px; font-weight: bold;">{{(types[index].type).toUpperCase()}}</td>
                                            <td style="width:65%"></td>
                                            <td style="width:10%"></td>
                                            <td style="width:20%"></td>
                                        </template>
                                    </tr>
                                </template>
                                <template v-else-if="types[index].type === 't3'">
                                    <tr v-for="(n,i) in 3" :style="[n%3==0 ? {'border-bottom': 'solid 4px'} : {'border-bottom': '0px'}]" :key="n.c">
                                        <template v-if="types[index].yarns[i]">
                                            <td style="width:5%; height: 32px; font-weight: bold;">{{(types[index].type).toUpperCase()}}</td>
                                            <td style="width:65%">{{types[index].yarns[i].yarn.name}}</td>
                                            <td style="width:10%">{{parseFloat(types[index].yarns[i].percentage)}}%</td>
                                            <td style="width:20%">{{formato((types[index].yarns[i].totalKgs))}} KGS</td>
                                        </template>
                                        <template v-else>
                                            <td style="width:5%; height: 32px; font-weight: bold;">{{(types[index].type).toUpperCase()}}</td>
                                            <td style="width:65%"></td>
                                            <td style="width:10%"></td>
                                            <td style="width:20%"></td>
                                        </template>
                                    </tr>
                                </template>
                                </template>
                            </template>
                        </table>
                    </td>
                    </tr>



                </table>
            </v-col>
        </v-row>
    </div>
        <v-row>
            <v-col>
                <table class="dataPoInfo">
                    <thead>
                        <td>DIV</td>
                        <td>YARN DESCRIPTION</td>
                        <td>WIDTH</td>
                        <td colspan="2">WEIGHT</td>
                        <td colspan="3">KNITTING</td>
                        <td colspan="3">DYEING</td>
                        <td colspan="3">PRINTING</td>
                    </thead>
                    <tr v-for="(type) in types" :key="type.id" >
                        <template v-if="type.id">
                            <td style="height: 30px">
                                {{(type.type).toUpperCase()}}
                            </td>
                            <td>
                                <div hidden>{{yarn_complete = ''}}</div>
                                <template v-for="yarn in type.yarns">
                                    <div hidden>
                                        {{yarn_complete+=(yarn.yarn.name + "+")}}
                                    </div>
                                </template>
                                {{(yarn_complete.substr(0, yarn_complete.length - 1)) + " " + type.texture.name}}
                            </td>
                            <td>{{parseFloat(type.widthUse)}}/{{parseFloat(type.widthTotal)}}''</td>
                            <td>{{parseFloat(type.weightGsm)}} g/m²</td>
                            <td>{{parseFloat(type.weightYds)}}  g/yd</td>
                            <td >
                                    {{parseFloat(type.knittingPercentage)}}%
                            </td>
                            <td>
                                    {{formato(sumarMontos(type,"KNITTING"))}}kgs
                            </td>
                            <td>{{formato((sumarMontos(type,"KNITTING")/type.weightYds)*1000)}}yds</td>
                            <td>
                                {{parseFloat(type.dyeingPercentage)}}%
                            </td>
                            <td>{{formato(sumarMontos(type,"DYEING"))}}kgs</td>
                            <td>{{formato((sumarMontos(type,"DYEING")/type.weightYds)*1000)}}yds</td>
                            <td style="width:25px">
                                <template v-for="(n,i) in 3">
                                <template v-if="processes[i] && processes[i].status === 'PRINTING'">
                                    {{parseFloat(type.printingPercentage)}}%
                                </template>
                                </template>
                            </td>
                            <td>
                            <template>
                                    {{formato(sumarMontos(type,"PRINTING"))}}kgs
                            </template>
                            </td>
                            <td>{{formato((sumarMontos(type,"PRINTING")/type.weightYds)*1000)}}yds</td>
                        </template>
                    </tr>
                </table>
            </v-col>
        </v-row>
    <div class="types" v-for="(type) in types" :key="type.id">
        <div hidden>{{tQuantity=0}}{{tYds=0}}{{tAmount=0}}</div>
    <template v-if="type.id">
        <v-row style="margin-bottom:-35px">
            <v-col>
                <h4>{{(type.type).toUpperCase()}} DETAIL </h4>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <table class="dataPo">
                    <tr>
                        <td>YARN</td>
                        <td>
                            <div hidden>{{yarn_complete = ''}}</div>
                                <template v-for="yarn in type.yarns">
                                    <div hidden>
                                        {{yarn_complete+=(yarn.yarn.name + "+")}}
                                    </div>
                                </template>
                                {{(yarn_complete.substr(0, yarn_complete.length - 1)) + " " + type.texture.name}}
                        </td>
                        <td>WIDTH</td>
                        <td>{{parseFloat(type.widthUse)}}/{{parseFloat(type.widthTotal)}}''</td>
                        <td>WEIGHT</td>
                        <td>{{parseFloat(type.weightGsm)}}g/m²</td>
                        <td>{{parseFloat(type.weightYds).toFixed(0)}}g/yd</td>
                    </tr>
                </table>
            </v-col>
        </v-row>
        <v-row style="margin-top:-10px">
            <v-col>
                <table class="dataPoInfo">
                    <thead>
                        <tr>
                        <td>COLOR</td>
                        <td>LAB DIP</td>
                        <td>GRADE</td>
                        <td>LOSS %</td>
                        <td>QTY KGS</td>
                        <td>QTY YDS</td>
                        <td>U/PRICE</td>
                        <td>TOTAL AMOUNT</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="color in type.colors" :key="color.id">
                        <td>{{color.color}}</td>
                        <td>{{color.number}}</td>
                        <td>{{color.grade}}</td>
                        <td>
                           {{parseFloat(type.knittingPercentage)}}%
                        </td>
                        <td style="text-align:right">{{formato(sumarMontosColor(type,color,"KNITTING"))}}kgs</td><div hidden>{{tQuantity+=(parseFloat((sumarMontosColor(type,color,"KNITTING"))))}}</div>
                        <td style="text-align:right">{{formato((sumarMontosColor(type,color,"KNITTING")/type.weightYds)*1000)}}Yds</td><div hidden>{{tYds+=(parseFloat((((sumarMontosColor(type,color,"KNITTING")/type.weightYds)*1000))))}}</div>
                        <td>
                            ${{uPrice=formato(type.knittingPrice)}}
                        </td>
                        <td style="text-align:right">${{formato(total=(sumarMontosColor(type,color,"KNITTING"))*uPrice)}}</td><div hidden>{{tAmount+=(parseFloat(total))}}</div>
                    </tr>
                </tbody>
                    <thead>
                        <td colspan="4">TOTAL</td>
                        <td style="text-align:right">
                            {{formato(tQuantity)}}Kgs
                        </td>
                        <td style="text-align:right">{{formato(tYds)}}Yds</td>
                        <td></td>
                        <td style="text-align:right">${{formato(tAmount)}}</td>
                    </thead>
                </table>
            </v-col>
        </v-row>
    </template>
    </div>
    <h4 style="margin-top:8px; margin-bottom: 8px;">COMMENTS KNITTING</h4>
    <table>
        <tr><td>
            <template v-for="process in processes">
              <template v-if="process.status === 'KNITTING'">
                <h4 :key="process.status" v-html="process.comment">
                </h4>
              </template>
            </template>
        </td></tr>
    </table>
</div>
    <div class="separate"></div>
    <div class="text-center">
    <v-btn
              id="buttonPrint"
              color="primary"
              dark
              @click="PrintReport('printableArea')"
            >
             {{ $t('printButton') }}
            </v-btn>
    </div>

    </v-col>
    </v-container>
    </template>

    <script>
    import { parse } from 'path';
    import { createNamespacedHelpers } from 'vuex'
    const fabricModule = createNamespacedHelpers('fabricPo')
    const authModule = createNamespacedHelpers('auth')
    import jsPDF from 'jspdf'
    import {font} from '../../assets/bk/malgun-normal'
    import printReport from '../../services/helpers/printReport'
    export default {
        props:{
    id:{
      type: String,
      required: true,
    }
    },
        data:()=>({
        }),
    methods:{
        ...fabricModule.mapActions(['getFabricPoById']),
        ...authModule.mapActions(['setDrawer']),
        PrintReport(){
            
        //   document.getElementById("appBar").style.display="none";
        //   document.getElementById("buttonPrint").style.display="none";
        //   document.getElementById("footBar").style.display="none";
        //   document.getElementById("vCol").className="";
        //   document.getElementById("vCol").style=""
        //   this.setDrawer(false)
        //   this.$nextTick(() => {
        //   window.print();
        //   document.getElementById("appBar").style.display="";
        //   document.getElementById("buttonPrint").style.display="";
        //   document.getElementById("footBar").style.display="";
        //   document.getElementById("vCol").className="white rounded pa-8";
        //   document.getElementById("vCol").style="box-shadow: 0px 0px 15px 0px #00000036;"
        //   this.setDrawer(true)
        //   })


            printReport.printReport(this.fabricPoById[0].number,'KNITTING')
          },

        formato(digito){
            digito = parseFloat(digito)
            return digito.toLocaleString('en-US',{minimumFractionDigits: 2,maximumFractionDigits: 2})
        },
        sumarMontos(arr,process){
            let percentage = 0
            let sum = 0
            let totalYarn = arr.sumYarn
            if (process === "KNITTING"){
                    sum=totalYarn*(1+(arr.dyeingPercentage/100))
                    totalYarn = sum
                    sum=totalYarn*(1+(arr.printingPercentage/100))
            }

            if (process === "DYEING"){
                let isPrinting = 0
                this.processes.forEach(e=>{
                        if (e.status === 'PRINTING'){
                            isPrinting = 1
                        }
                })
                    if (isPrinting == 1){
                        sum=totalYarn*(1+(arr.printingPercentage/100))
                        totalYarn = sum
                    }else{
                        sum=totalYarn
                        totalYarn = sum
                    }
            }

            if (process === "PRINTING"){
                this.processes.forEach(e=>{
                        if (e.status === 'PRINTING'){
                            sum = totalYarn
                        }
                })
            }

            return sum
        },

        sumarMontosColor(arr,monto,process){
            let percentage = 0
            let sum = 0
            let totalYarn = monto.quantity
            if (process === "KNITTING"){
                    sum=totalYarn*(1+(arr.dyeingPercentage/100))
                    totalYarn = sum
                    sum=totalYarn*(1+(arr.printingPercentage/100))
            }

            if (process === "DYEING"){
                let isPrinting = 0
                this.processes.forEach(e=>{
                        if (e.status === 'PRINTING'){
                            isPrinting = 1
                        }
                })
                    if (isPrinting == 1){
                        sum=totalYarn*(1+(arr.printingPercentage/100))
                        totalYarn = sum
                    }else{
                        sum=totalYarn
                        totalYarn = sum
                    }
            }

            if (process === "PRINTING"){
                this.processes.forEach(e=>{
                        if (e.status === 'PRINTING'){
                            sum = totalYarn
                        }
                })
            }

            return sum
        }

    },
    beforeMount(){
        fabricModule.mapGetters(['fabricPoById'])
    },
    computed:{
        ...fabricModule.mapGetters(['fabricPoById']),
         processes (){
             return this.fabricPoById[0].fabricPoProcesses.map(process=> {
              return {
                ...process,
                comment:process.comment ? process.comment.replace(/\n/g, '<br>') : process.comment
              }
             })
         },
        types(){
            //const colors =  (this.fabricPoById[0].fabricPoDetails[0].colors)

            const details = this.fabricPoById[0].fabricPoDetails

            //const yarns = this.fabricPoById[0].fabricPoDetails[0].yarns
            details.forEach((type,index) => {
            let sumYarn = 0
            let totalYarn = 0
            let totalKgs = 0
                let colors =  (details[index].colors)
                if (colors){
                colors.forEach(el => {
                   sumYarn = parseFloat(el.quantity) + sumYarn
                   details[index].sumYarn = sumYarn
                });
                }
                totalYarn = (sumYarn * (details[index].knittingPercentage/100))+sumYarn
                sumYarn = totalYarn
                totalYarn = (sumYarn * (details[index].dyeingPercentage/100))+sumYarn
                sumYarn = totalYarn
                totalYarn = (sumYarn * (details[index].printingPercentage/100))+sumYarn
                sumYarn = totalYarn
                if(details[index].yarns.length > 0){
                let yarns = (details[index].yarns)
                yarns.forEach((e,i) => {
                    totalKgs = (totalYarn) * (e.percentage/100)
                    yarns[i].totalKgs = totalKgs
                    details[index].totalYarn = totalYarn
                });
                }
            });

            let newAr = this.fabricPoById[0].fabricPoDetails

            for (let i=1; i<=3; i++){
            if (!newAr.find(e => e.type === ("b"+i))){
                if(newAr.find(e=>e.type === ("t"+i))){
                newAr.push({type:"b"+i,yarns:{}})
                }
            }
            }
            for (let i=1; i<=3; i++){
                if (!newAr.find(e => e.type === ("t"+i))){
                    if(newAr.find(e=>e.type === ("b"+i))){
                    newAr.push({type:"t"+i,yarns:{}})
                    }
                }
            }

            newAr.sort(function(a,b){
                return a.type.localeCompare(b.type)
            })
            return newAr

        },
    },
    mounted(){
        this.getFabricPoById(this.$route.params.id)

    },
    updated(){
        //this.PrintReport()
    }
    }
    </script>

    <style lang="css" scoped>
    table{
        border: 1px;
        width: 100%;
        border-collapse: collapse;

        font-size: 12px;
    }

    td {
        border: 1px solid;
        height: 30px;
    }

    .dataPo{
        font-weight: bold;
        text-align: center;
    }

    .dataPoInfo{
        font-weight: bold;
        text-align: center;
        font-size: 10px;
    }

    .dataPoInfo td{
        height: 10px;
    }

    .dataPoInfo thead{
        font-weight: bolder;
    }



    .titlePo{
        width: 20%;
    }

    .yarnListTitle{
        width: 20%;
        font-weight: bold;
        text-align: center;
    }

    .yarnListBody{
        height: 60px;
    }

    h5,h2,h4,h1,h3{
        font-weight: bold;

    }

    .separate{
        padding-bottom: 35px;
    }

    .estilo{
      background-color: white;
    }

    .types + .types + .types + .types + .types {
        page-break-before: always;
    }

    .types + .types + .types + .types + .types + .types{
        page-break-before: avoid;
    }

    </style>
