export default {
  vendors: [],
  general: {
    processes: [{
      process: 'KNITTING'
    },
    {
      process: 'DYEING'
    }]
  },
  detail: [{
    colors: [{}],
    yarns: [{}]
  }],
  yarns: [{}],
  fabricPoStatus: {
    status: null,
    message: null
  },
  fabricPos: [],
  fabricPoPage: {},
  plants: [],
  currentPoFabricYarns: [],
  fabricPo: {},
  fabricPoById: {},
  fabricPosVersions: [],
  uploadingFiles: false,
  files: []
}
