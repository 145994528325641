import axios from 'axios'
import vueI18n from '@/i18n';
import Vue from 'vue';
import router from '@/router'
import backendService from '../services/backend.service';
import { SET_AUTHENTICATED, SET_TOKEN } from '../store/mutation-types'

export default (vuexStore) => {
  const axiosInstance = axios.create()

  axiosInstance.interceptors.request.use((config) => {
    const accessToken = vuexStore.getters['auth/accessToken'];
    config.headers.Authorization = `Bearer ${accessToken}`
    config.headers['accept-language'] = vueI18n.locale

    return config
  }, (error) => Promise.reject(error))

  axiosInstance.interceptors.response.use((response) => response,
    async (error) => {
      const originalRequest = error.config
      const response = error.response
      const refreshToken = vuexStore.getters.refreshToken

      if (response.status !== 401 ) {
        return Promise.reject(error)
      }

      if (originalRequest._retry) {
        vuexStore.commit('auth/'+SET_AUTHENTICATED, { token: null, email: null, refreshToken: null, roles: [] })
        localStorage.clear()
        router.push('login')
      }

      originalRequest._retry = true

      try {
        const tokenData = await backendService.refreshToken(refreshToken)
        const accessToken = tokenData.data.accessToken
        const newRefreshToken = tokenData.data.refreshToken
        vuexStore.commit('auth/'+SET_TOKEN, { accessToken, refreshToken: newRefreshToken})

        return axiosInstance(originalRequest)
      } catch(e) {
        vuexStore.commit('auth/'+SET_AUTHENTICATED, { token: null, email: null, refreshToken: null, roles: [] })
        localStorage.clear()
        router.push('/login')
      }
    }
  )

  Vue.prototype.$http = axiosInstance
}
