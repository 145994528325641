<template>
  <v-col>
    <v-row>
      <v-col>
        <v-autocomplete
          dense
          outlined
          background-color="white"
          :value="item.yarn"
          @input="change($event.id, 'yarn')"
          :label="$t('fabricPurchaseOrder.detail.yarnSelection.yarn')"
          :items="yarns"
          item-value="id"
          item-text="name"
          return-object
          hide-details="auto">
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-text-field
          dense
          outlined
          :value="item.percentage"
          background-color="white"
          @input="change(parseFloat($event), 'percentage');"
          type="number"
          :label="$t('fabricPurchaseOrder.detail.yarnSelection.percentage')"
          hide-details="auto">
          
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          dense
          outlined
          :value="item.price"
          background-color="white"
          @input="change(parseFloat($event), 'price');"
          type="number"
          :label="$t('fabricPurchaseOrder.detail.yarnSelection.price')"
          hide-details="auto">
        </v-text-field>
      </v-col>
      <v-col style="max-width: 40px;" class=" d-flex justify-center">
        <v-btn icon color="error" @click="deleteElement">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

export default {
  name: "FabricPoYarnSelection",
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    yarns: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {

  },
  methods: {
    change(value, field) {
      if (isNaN(value)) {
        value = 0
      }
      const row = this.item;
      row[field] = value;
      this.$emit('input', row);
    },
    deleteElement() {
      this.$emit('delete')
    },
  }
}
</script>
