import { SET_PROVIDER_INVOICES,
  SET_PROVIDER_INVOICE_STATUS,
  SET_PROVIDER_INVOICE,
  SET_PROVIDER_INVOICE_DETAIL } from '../mutation-types'
import providerInvoiceService from "../../services/modules/provider-invoice.service";
import router from '@/router'

const setProviderInvoice = ({commit}, payload) => {
  commit(SET_PROVIDER_INVOICE, payload)
}

const setProviderInvoiceDetail = ({commit}, payload) => {
  commit(SET_PROVIDER_INVOICE_DETAIL, payload)
}

const getProviderInvoices = async ({commit}, payload) => {
  try {
    const response = await providerInvoiceService.getAllProviderInvoices(payload)
    const data = response.data
    commit(SET_PROVIDER_INVOICES, data)
  } catch (error) {
    const response = error.response
    const message = response.data.message

    const status = {
      status: 'error',
      message
    }
    commit(SET_PROVIDER_INVOICE_STATUS, status)
  }
}

const getProviderInvoice = async ({commit}, id) => {
  try {
    const response = await providerInvoiceService.getProviderInvoice(id)
    const data = response.data
    commit(SET_PROVIDER_INVOICE, data)
    const detail = data.details
    commit(SET_PROVIDER_INVOICE_DETAIL, detail)
  } catch (error) {
    const response = error.response
    const message = response.data.message

    const status = {
      status: 'error',
      message
    }
    commit(SET_PROVIDER_INVOICE_STATUS, status)
  }
}

const createProviderInvoice = async ({commit, state}) => {
  try {
    const {invoiceDate, fabricPo: fabricPoId, invoiceNumber,
    provider: providerId, comment } = state.providerInvoice;

    const details = state.details.map(detail => ({...detail, invoiceTotalAmount: detail.total}))

    const payload = {
      invoiceDate, fabricPoId, invoiceNumber, providerId, comment, details
    }

    await providerInvoiceService.createProviderInvoice(payload)

    setTimeout(() => {
      router.push({ name: 'ProviderInvoiceList'})
    }, 1000)
  } catch (error) {
    const response = error.response
    const message = response.data.message

    const status = {
      status: 'error',
      message
    }
    commit(SET_PROVIDER_INVOICE_STATUS, status)
  }
}

export default {
  getProviderInvoices,
  createProviderInvoice,
  setProviderInvoice,
  setProviderInvoiceDetail,
  getProviderInvoice
}
