
export default {
  providers: [],
  vendors: [],
  yarnPurchaseDetail: [{}],
  footer: {},
  general: {},
  yarns: [],
  origin: [
    {
      key: 'CAFTA',
      text: 'Cafta'
    },
    {
      key: 'ASIA',
      text: 'Asia'
    },
    {
      key: 'DUTY_FREE',
      text: 'Duty free'
    }
  ],
  yarnPoStatus: {
    status: null,
    message: null
  },
  yarnPOs: [],
  yarnPoPage: {},
  yarnPoById:[],
  currentYarnPoYarns: []
}

