<template>
  <v-container fluid class="py-0">
    <v-row>
      <v-col class="px-0 py-1 d-flex align-center">
        <span class="text-body-1 font-weight-bold">
          {{ $t('fabricPurchaseOrder.general.file.fileTitle') }}
        </span>
      </v-col>
      <v-col style="min-width: 100px;" class="text-end py-1 pe-0">
        <v-btn small outlined color="primary" @click="addFile()" :loading="uploadingFile" class="text-button">
          + {{ $t('fabricPurchaseOrder.general.file.addButton') }}
          <v-icon color="primary" size="18px">
            mdi-upload
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <input type="file" multiple
          ref="fileField" counter class="d-none"
          accept="application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          @change="onFileChanged">
      </v-col>
    </v-row>

    <v-row v-if="files.length === 0">
      <v-col class="file-input rounded border
        text-center primary--text text--lighten-2">
        <v-icon color="primary" size="28px">
          mdi-file
        </v-icon>
        <span class="text-body-1">
          {{ $t('fabricPurchaseOrder.general.file.addFilesLabel') }}
        </span>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col>
        <file-list :files="files" @removeFile="removeFile" @downloadFile="downloadFile"></file-list>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0 d-flex flex-column" cols="12">
        <span class="pb-1 text-caption error--text" v-for="error in errors" :key="error">
          {{error}}
        </span>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import FileList from './FileList.vue'

export default {
  name: 'FileField',
  components: { FileList },
  props: {
    files: {
      type: Array,
      default: () => ([])
    },
    uploadingFile: {
      type: Boolean,
      default: () => (null)
    }
  },
  data: () => ({
    errors: []
  }),
  methods: {
    addFile() {
      this.$refs.fileField.click();
    },
    onFileChanged(e) {
      const newFiles = e.target.files
      this.$emit('inputFile', newFiles)
    },
    removeFile(file) {
      this.$emit('removeFile', file)
    },
    downloadFile(file) {
      this.$emit('downloadFile', file)
    }
  },
  computed: {

  }
}
</script>

<style lang="scss" scoped>
.file-input {
  border-style: dashed;
}
</style>
